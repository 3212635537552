<template>
  <modal
    :title="'Annexes de : ' + order?.invoice?.serial"
    v-model="modalOpened"
    @cancel="() => closeModal('close')"
    style="padding-top: 100px"
    effect="fade/zoom"
    large
  >
    <div>
      <div class="row">
        <div class="col-sm-12 form-group">
          <el-table
            style="width: 100%"
            :data="files"
            :default-sort="{ prop: 'createdAt', order: 'ascending' }"
          >
            <el-table-column prop="name" label="Nom du fichier">
              <template slot-scope="scope">
                <a @click="downloadFile(scope.row.id)" href="#">{{
                  scope.row.name
                }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="Date de création">
              <template slot-scope="scope">
                {{ new Date(scope.row.createdAt).toLocaleDateString() }}
              </template>
            </el-table-column>
            <el-table-column label="Actions" align="right">
              <template slot-scope="scope">
                <button
                  v-if="scope.row.isInvoiceFile"
                  class="btn btn-danger btn-sm"
                  @click="deleteAnnexe(scope.row.id)"
                >
                  <span class="fa fa-trash-o"></span>
                </button>
                <span v-else-if="scope.row.id !== order.invoice.pdf"
                  >Ancienne facture</span
                >
                <span v-else>Facture</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="row" v-if="hasPermission($store.state.user, 'ORDERS_WRITE')">
        <div class="col-sm-12 form-group">
          <label>Ajouter</label>
        </div>
        <div class="col-sm-12">
          <dropzone
            :url="apiUploads()"
            :headers="apiAuthorization()"
            v-on:vdropzone-success="fileUploaded"
            id="fileInvoice"
          >
            <div class="dz-default dz-message">
              <span
                ><i class="icon-cloud-upload"></i><br />Glissez le fichier
                ici</span
              >
            </div>
          </dropzone>
          <button
            v-if="formFile"
            type="button"
            class="btn btn-primary float-right mt-1"
            @click="sendForm()"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-primary" @click="$emit('close')">
        Fermer
      </button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Dropzone from "vue2-dropzone";
import Modal from "vue-strap/src/Modal";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Modal,
    Dropzone,
  },

  mixins: [DownloadFile],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      default: undefined,
    },
    invoiceFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      files: [],
      modalOpened: false,
      formFile: undefined,
    };
  },

  mounted() {
    this.modalOpened = this.show;
    this.files = this.invoiceFiles;
  },

  methods: {
    apiUploads() {
      return `${axios.defaults.baseURL}uploads`;
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    async fileUploaded(_, response) {
      this.formFile = response.id;
    },

    async sendForm() {
      try {
        const { data } = await this.$api.post(
          `/orders/${this.order.id}/invoice-file`,
          {
            fileId: this.formFile,
          }
        );

        this.files.push(data);
      } catch (error) {
        console.error(error);
      }

      this.formFile = undefined;
    },

    async deleteAnnexe(fileId) {
      try {
        await this.$api.delete(`/orders/${fileId}/invoice-file`);
      } catch (error) {
        console.error(error);
      }

      this.files = this.files.filter((file) => file.id !== fileId);
    },

    async downloadFile(fileId) {
      this.downloadSecuredFile(`uploads/document/${fileId}`);
    },

    closeModal(action) {
      this.modalOpened = false;

      this.$emit(action);
    },
  },

  watch: {
    show: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
      }
    },
  },
};
</script>
