<template>
  <modal
    title="Ajouter une commande"
    class="custom-modal"
    large
    v-model="modalOpened"
    @ok="handleNewOrder()"
    @cancel="$emit('closeModal')"
    effect="fade/zoom"
    cancelText="Fermer"
    okText="Enregistrer"
    :backdrop="false"
  >
    <div slot="modal-header" class="modal-header row m-0">
      <h4 class="modal-title col-4">Ajouter une commande</h4>

      <div
        class="col-8 d-flex flex-column align-items-end"
        style="padding-top: 17px"
      >
        <div class="pull-right d-flex flex-wrap justify-content-end">
          <div class="form-group d-inline-block mr-1">
            <span class="mr-1"><i class="fa fa-video-camera"></i> Visio</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.hasVisio"
                @change="changeHasVisio"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div class="form-group d-inline-block mr-1">
            <span class="mr-1"><i class="fa fa-bullhorn"></i> Parrainage</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.sponsorship.isSponsorship"
                @change="changeIsSponsorship"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div class="form-group d-inline-block mr-1">
            <span class="mr-1"><i class="fa fa-gift"></i> Carte cadeau</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.giftCard.isGiftCard"
                @change="handleChangeIsGiftCard()"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div class="form-group d-inline-block" v-if="!form.isCredit">
            <span class="mr-1"><i class="icon-envelope"></i> Email</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.sendEmail"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <div
          v-if="form.sponsorship.isSponsorship"
          class="form-group d-flex flex-column align-items-end mb-0"
        >
          <v-select
            v-model="form.sponsorship.sponsorEmail"
            :options="clients"
            :reduce="(option) => option.value"
            :class="
              formErrors.noSponsor || formErrors.autoSponsoring
                ? 'has-error'
                : ''
            "
            :disabled="formErrors.sponsorship"
            @search="searchClients"
            @input="checkSponsor"
          ></v-select>
          <label class="text-muted mb-0">Parrain/Marraine</label>
          <div v-if="formErrors.autoSponsoring" class="text-danger">
            Un client ne peut pas se parrainer lui-même
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4" v-if="form.hasVisio">
        <label>Type de prestations (visio) *</label>
        <v-select
          v-model="form.visio.prestationType"
          :options="visioPrestationsType"
          :class="this.formErrors.visioPrestationType ? 'has-error' : ''"
        ></v-select>
      </div>
      <div class="form-group col-sm-4" v-if="form.hasVisio">
        <label>Durée de la visio (en minutes)</label>
        <v-select
          v-model="form.visio.visioDuration"
          :options="visioDurations"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group has-danger col-sm-4">
        <label>Société *</label>
        <v-select
          v-model="form.merchantId"
          :options="merchantsOptions"
          :class="this.formErrors.merchantId ? 'has-error' : ''"
          @click.native="checkIfEmpty"
          @input="handleMerchantChange"
        ></v-select>
      </div>
      <div class="form-group has-danger col-sm-4">
        <label>Concept *</label>
        <v-select
          v-model="form.concept"
          :options="concepts"
          :class="this.formErrors.concept ? 'has-error' : ''"
          @input="updatePaymentMethods()"
        >
          <span slot="no-options">Sélectionnez une option pour Société.</span>
        </v-select>
      </div>

      <div class="form-group col-sm-4">
        <label>Commercial</label>
        <v-select :options="commercials" v-model="form.commercial"></v-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label>Pack</label>
        <v-select
          v-model="form.session"
          :options="sessions"
          :class="this.formErrors.session ? 'has-error' : ''"
          @input="(e) => createSubPayments(e)"
        ></v-select>
      </div>
      <div class="form-group col-sm-4">
        <label>Séances temporaires</label>
        <v-select v-model="form.allowed" :options="sessions"></v-select>
      </div>
      <div
        class="form-group col-sm-4"
        v-if="!form.giftCard.isGiftCard"
        style="padding-top: 14px"
      >
        Pointage des participants
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="form.requiredSessionUserCount"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>
      <div class="col-sm-4" v-else>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Expéditeur</label>
            <input
              v-model="form.giftCard.sender"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group col-sm-6">
            <label>Destinataire</label>
            <input
              v-model="form.giftCard.recipient"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group col-sm-12">
            <label>Message carte cadeau</label>
            <textarea
              v-model="form.giftCard.message"
              type="text"
              class="form-control"
              maxlength="80"
            />
            <p>
              <strong class="text-muted">
                {{ giftCardMaxChar }} caractères restants.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label>
          Description
          <span v-if="form.giftCard.isGiftCard">carte cadeau</span>
          <span v-else>commande</span>
          (visible facturation)
        </label>

        <v-select
          v-if="client.isB2B"
          style="margin-bottom: 5px"
          :options="client.orderDescriptions"
          v-model="client.billing.label"
          @input="updateBillingDescriptionForB2B"
        ></v-select>

        <textarea
          v-model="client.billing.description"
          type="text"
          class="form-control"
        />
      </div>
      <div class="form-group col-sm-4">
        <label>
          Label de la
          <span v-if="form.giftCard.isGiftCard">carte cadeau</span>
          <span v-else>commande</span>
          (visible coach)
        </label>
        <textarea v-model="form.label" type="text" class="form-control" />
      </div>
      <div class="form-group col-sm-3">
        <label>Commande effectuée le</label>
        <datepicker
          v-model="form.createdAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-7">
        <label>Menu / Statistique *</label>
        <v-select
          v-model="form.menuStat"
          :options="menuStatsOptions"
          label="label"
          :selectable="(option) => !option.disable"
          :class="this.formErrors.menu ? 'has-error' : ''"
          :disabled="form.isCredit"
          @input="changeMenuStat"
        />
      </div>

      <div class="form-group col-sm-2" style="padding-top: 35px">
        <i class="icon-target"></i> Attribution
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="form.attribution"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>

      <div class="form-group col-sm-3">
        <label>Prix *</label>
        <div
          class="input-group"
          :class="this.formErrors.price ? 'has-error' : ''"
        >
          <input
            v-model="form.price"
            type="text"
            class="form-control"
            @input="(e) => formPriceInput(e.target.value)"
          />
          <span class="input-group-addon"><i class="fa fa-euro"></i></span>
        </div>
        <small
          v-if="
            sponsoredStatus &&
            sponsoredStatus.value === 'discovery-done' &&
            (form.sponsorship.isSponsorship ||
              (!form.sponsorship.isSponsorship &&
                !sponsoredStatus.canBeSponsored)) &&
            !formErrors.client
          "
          class="text-success"
        >
          -10% applicable grâce au parrainage
        </small>
        <small class="text-danger" v-if="form.isCredit">
          Avoir de <strong>{{ form.price }}&euro;</strong>, une facture sera
          générée directement.
        </small>
      </div>
    </div>

    <div v-if="!form.isCredit">
      <div class="row">
        <div class="form-group col-sm-2">
          <label>
            Avance imm.
            <i
              class="fa fa-info-circle"
              v-tooltip="
                `Avance immédiate : ${
                  !client.urssafInscription
                    ? 'client non inscrit'
                    : 'ne peut contenir de paiement CB & doit être dans COMP'
                }`
              "
            ></i>
          </label>
          <div class="input-group" style="padding-top: 7px">
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.paymentData.isAI"
                :disabled="
                  !hasPermission($store.state.user, 'ORDERS_WRITE') ||
                  !client.urssafInscription ||
                  !client.urssafInscription.valid ||
                  !isCompany
                "
                @change="toggleAI"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <div class="form-group col-sm-2">
          <label> Nombre d'échéances </label>
          <div class="input-group">
            <input
              v-model="form.paymentData.countPayments"
              type="number"
              min="1"
              max="20"
              class="form-control"
              @input="createSubPayments"
            />
          </div>
        </div>
        <div class="form-group col-sm-2">
          <label> Paiement par CB </label>
          <div class="input-group" style="padding-top: 7px">
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.paymentData.isCB"
                :disabled="
                  !hasPermission($store.state.user, 'ORDERS_WRITE') ||
                  form.paymentData.isAI
                "
                @change="createSubPayments"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <div class="form-group col-sm-4" v-if="form.paymentData.isCB">
          <label style="width: 100%">
            Description Paiement
            <a class="pull-right" href="#" @click="createSubPayments">
              <i class="fa fa-refresh"></i>
            </a>
          </label>
          <input
            v-model="form.paymentData.description"
            type="text"
            class="form-control"
          />
        </div>

        <div class="form-group col-sm-4" v-else>
          <div class="form-group" style="position: relative">
            <label style="width: 100%">
              Choix du mode de paiement (par défaut)
              <a class="pull-right" href="#" @click="createSubPayments">
                <i class="fa fa-refresh"></i>
              </a>
            </label>
            <div class="input-group">
              <span class="input-group-addon">Paiement par</span>
              <select
                v-model="form.paymentData.type"
                @change="createSubPayments"
                :disabled="form.paymentData.isAI"
                class="form-control"
              >
                <option
                  :key="key"
                  v-for="(pm, key) in paymentMethods"
                  :value="pm.name"
                >
                  {{ pm.name }}
                </option>
              </select>
            </div>
            <div
              class="input-group"
              style="margin-top: -1px; position: absolute; width: 100%"
              v-if="form.paymentData.type == 'manual'"
            >
              <input
                v-model="form.paymentData.typeManual"
                type="text"
                class="form-control"
                placeholder="Saisir un nom de paiement"
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-2">
          <label style="width: 100%">
            Première échéance
            <a class="pull-right" href="#" @click="createSubPayments">
              <i class="fa fa-refresh"></i>
            </a>
          </label>
          <datepicker
            v-model="form.paymentData.wishedDay"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div class="row">
            <div class="form-group col-sm-6">
              <label>
                Bloquer les planifications des séances non payés
                <i
                  class="fa fa-info-circle"
                  v-tooltip="
                    `Lorsque cette option est activée, les séances non payées ne pourront pas être planifiées par le coach.`
                  "
                ></i>
              </label>
              <div class="input-group" style="padding-top: 7px">
                <label
                  class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
                >
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="form.sessionCoachBlocked"
                    :disabled="
                      !hasPermission($store.state.user, 'ORDERS_WRITE')
                    "
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <div
                class="alert alert-info"
                v-if="form.paymentData.sessionPrice"
              >
                <strong>Information</strong> Prix d'une séance
                {{ form.paymentData.sessionPrice }} €
              </div>
            </div>
          </div>

          <div
            class="alert alert-danger"
            v-if="sessionsDifference || paymentsDifference"
          >
            Veuillez ajuster les séances de :
            {{ sessionsDifference }} séance{{
              sessionsDifference > 1 ? "s" : ""
            }}
            pour {{ paymentsDifference }} €
          </div>

          <div class="alert alert-danger" v-if="formErrors.missingType">
            Veuillez ajouter une description (si CB) ou un mode (si pas CB) aux
            paiements.
          </div>

          <el-table :data="form.payments" v-if="!form.paymentData.isAI">
            <el-table-column label="Prix" width="100">
              <template slot-scope="scope"> {{ scope.row.price }} € </template>
            </el-table-column>
            <el-table-column label="CB" align="center" width="40">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="checkbox"
                  v-model="scope.row.isCB"
                  @input="clearPaymentType(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="Description" width="200">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.type"
                  :disabled="!scope.row.isCB"
                />
              </template>
            </el-table-column>
            <el-table-column label="Date de prélèvement" width="170">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </template>
            </el-table-column>
            <el-table-column label="Mode" v-if="!form.paymentData.isCB">
              <template slot-scope="scope">
                <select
                  v-if="!scope.row.isCB"
                  v-model="scope.row.type"
                  class="form-control"
                >
                  <option
                    :key="key"
                    v-for="(pm, key) in paymentMethods"
                    :value="pm.name"
                  >
                    {{ pm.name }}
                  </option>
                </select>
              </template>
            </el-table-column>

            <el-table-column label="Séances" width="125">
              <template slot-scope="scope">
                <input
                  v-model="scope.row.nbSessions"
                  type="number"
                  class="form-control"
                  @input="
                    (e) => formPaymentAiSessionInput(e.target.value, scope.row)
                  "
                />
              </template>
            </el-table-column>
          </el-table>

          <el-table :data="form.payments" v-else>
            <el-table-column label="Prix" width="100">
              <template slot-scope="scope"> {{ scope.row.price }} € </template>
            </el-table-column>
            <el-table-column label="Date de prélèvement" width="170">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </template>
            </el-table-column>
            <el-table-column label="Séances" width="125">
              <template slot-scope="scope">
                <input
                  v-model="scope.row.nbSessions"
                  type="number"
                  class="form-control"
                  @input="
                    (e) => formPaymentAiSessionInput(e.target.value, scope.row)
                  "
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-4" v-if="!form.giftCard.isGiftCard">
          <div class="form-group">
            Valeurs par défaut des sessions
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.editSessions"
                @input="fetchClientCoachs"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div v-if="form.editSessions" class="row">
            <div class="col-sm-8 form-group">
              <label>Coach</label>
              <v-select
                v-model="form.defaultCoach"
                :options="coachs"
                @search="searchCoachs"
                :class="this.formErrors.coach ? 'has-error' : ''"
              ></v-select>
            </div>
            <div class="col-sm-4 form-group">
              <label>Credit par séance</label>
              <input
                type="text"
                class="form-control"
                v-model="form.defaultCredit"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="companyHasSignatureSheetsFeature" class="row pt-1">
        <div class="form-group col-sm-4 d-flex">
          <div>
            Émargement
            <i
              class="fa fa-info-circle"
              v-tooltip="
                'Des feuilles d\'émargement seront générées pour toutes les séances à partir d\'un modèle sélectionné.'
              "
            ></i>
          </div>
          <label
            class="switch switch-sm switch-text switch-info mb-0 mr-0 ml-1"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="form.signatureSheets"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
        </div>

        <template v-if="form.signatureSheets">
          <div class="form-group col-sm-4">
            <label>Lot</label>
            <v-select
              v-model="form.sessionsBatchId"
              :options="sessionsBatchesOptions"
              :reduce="({ value }) => value"
              :class="this.formErrors.sessionsBatchId ? 'has-error' : ''"
              @input="form.signatureSheetTemplateId = null"
            >
              <template #no-options>
                Créez un lot pour cette entreprise depuis le menu B2B >
                Émargement > Lots
              </template></v-select
            >
          </div>
          <div class="form-group col-sm-4">
            <label>Modèle</label>
            <v-select
              v-model="form.signatureSheetTemplateId"
              :options="signatureSheetTemplatesOptions"
              :reduce="({ value }) => value"
              :class="
                this.formErrors.signatureSheetTemplateId ? 'has-error' : ''
              "
            >
              <template #no-options>
                {{
                  form.sessionsBatchId
                    ? "Créez un modèle de feuille d'émargement pour cet lot depuis le menu B2B > Émargement > Modèles"
                    : "Veuillez sélectionner un lot"
                }}
              </template></v-select
            >
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";
import Modal from "vue-strap/src/Modal";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import debounce from "lodash.debounce";

import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
    vSelect,
    Datepicker,
  },

  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    clientData: {
      type: Object,
      required: true,
    },
    requiredSessionUserCount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      modalOpened: false,
      merchantsOptions: [],
      concepts: [],
      clients: [],
      coachs: [],
      sessions: [],
      visioPrestationsType: [],
      visioDurations: [
        { label: "30", value: 30 },
        { label: "45", value: 45 },
        { label: "60", value: 60 },
        { label: "75", value: 75 },
        { label: "90", value: 90 },
        { label: "105", value: 105 },
        { label: "120", value: 120 },
      ],
      commercials: [],
      paymentMethods: [],
      company: null,
      formSending: false,
      form: {
        sendEmail: true,
        giftCard: {
          isGiftCard: false,
          sender: "",
          recipient: "",
          message: "",
        },
        sponsorship: {
          isSponsorship: false,
          sponsorEmail: null,
        },
        hasVisio: false,
        visio: {
          prestationType: null,
          visioDuration: null,
        },
        menuStat: null,
        merchantId: null,
        concept: null,
        client: null,
        session: null,
        allowed: null,
        clients: null,
        price: null,
        description: null,
        label: null,
        attribution: null,
        commercial: null,
        paymentData: {
          isCB: true,
          isAI: false,
          type: null,
          wishedDay: moment().add("15", "days").format(),
          countPayments: 1,
          description: null,
          sessionPrice: null,
        },
        payments: [],
        defaultCoach: null,
        defaultCredit: "30",
        requiredSessionUserCount: null,
        sessionCoachBlocked: true,
        signatureSheets: false,
        sessionsBatchId: null,
        signatureSheetTemplateId: null,
      },
      formErrors: {
        session: false,
        concept: false,
        price: false,
        merchantId: false,
        client: false,
        menu: false,
        missingType: false,
        paymentsDifference: false,
        sponsorship: false,
        noSponsor: false,
        autoSponsoring: false,
        visioPrestationType: false,
        sessionsBatchId: null,
        signatureSheetTemplateId: null,
      },
      unpaidQuantity: null,
      sponsoredStatus: null,
      client: null,
    };
  },

  async created() {
    this.modalOpened = this.modalShow;
    this.client = this.clientData;

    if (this.client.companyId) {
      await this.getCompany(this.client.companyId);
    }

    await this.fetchCommercials();
    await this.fetchMerchants();
    await this.getClientCommercials(this.client.email);
    await this.checkClient();

    if (this.requiredSessionUserCount) {
      this.form.requiredSessionUserCount = this.requiredSessionUserCount;
    }

    if (this.client.billing?.description) {
      this.form.description = this.client.billing.description;
    }

    if (!this.form.commercial) {
      const currentCommercial = this.commercials.find((c) => {
        if (this.$store.state.user.email === c.value) {
          return c;
        }
      });

      if (currentCommercial) {
        this.form.commercial = currentCommercial;
      }
    }

    for (let i = 1; i <= 200; i++) {
      this.sessions.push({
        label: this.formatPack(i.toString()),
        value: i.toString(),
      });

      if (!this.form.session) {
        this.form.session = this.sessions[0];
      }
    }
  },

  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
      }
    },

    clientData: {
      handler(val) {
        this.client = val;
      },
      deep: true,
    },
  },

  computed: {
    giftCardMaxChar() {
      return 80 - this.form.giftCard.message.length;
    },

    paymentsDifference() {
      let diff = 0;

      if (this.form.payments) {
        let totalsPayments = 0;

        for (const p of this.form.payments) {
          totalsPayments += +p.price;
        }

        diff = this.form.price - totalsPayments;
      }

      return diff.toFixed(2) === "0.00" || diff.toFixed(2) === "-0.00"
        ? 0
        : diff.toFixed(2);
    },

    sessionsDifference() {
      let diff = 0;

      if (
        this.form.payments &&
        this.form.payments.length > 0 &&
        this.form.session?.value
      ) {
        let totalsSession = 0;

        for (const p of this.form.payments) {
          totalsSession += +p.nbSessions;
        }

        diff = this.form.session?.value - totalsSession;
      }

      return diff;
    },

    cleanedCredit() {
      return (
        parseFloat(this.form.defaultCredit.replace(",", ".")).toFixed(2) * 100
      );
    },

    ...mapGetters({
      orderStatus: "getOrderStatusGroups",
      orderStatusFollowed: "getOrderStatusFollowedGroups",
      sponsorshipStatus: "getSponsorshipStatus",
      menuStatsOptions: "getMenuStatsOptions",
    }),

    isCompany() {
      return (
        this.form.merchantId?.value === "502c9c9e-0205-4380-9fb3-b5f63176da84"
      );
    },

    conceptIsAffectedByOrderBlocking() {
      const conceptsNames = [
        "justcoaching.fr",
        "Body Trainers",
        "luxurycoaching.fr",
      ];

      if (this.form.concept) {
        return conceptsNames.includes(this.form.concept);
      }

      return false;
    },

    addOrderBlocked() {
      return (
        this.unpaidQuantity >= 1 &&
        this.conceptIsAffectedByOrderBlocking &&
        !this.hasPermission(
          this.$store.state.user,
          "BLOCKED_IMPAYED_ORDERS_READ"
        )
      );
    },

    companyHasSignatureSheetsFeature() {
      if (!this.company || !this.company.features) {
        return false;
      }

      return this.company.features.some(
        (feature) => feature.featureName === "signatureSheets"
      );
    },

    sessionsBatchesOptions() {
      if (!this.company || !this.company.sessionsBatches) {
        return [];
      }

      return this.company.sessionsBatches.map((batch) => ({
        label: batch.label,
        value: batch.id,
      }));
    },

    signatureSheetTemplatesOptions() {
      if (
        !this.company ||
        !this.company.sessionsBatches ||
        !this.form.sessionsBatchId
      ) {
        return [];
      }

      const selectedSessionsBatch = this.company.sessionsBatches.find(
        (batch) => batch.id === this.form.sessionsBatchId
      );

      if (
        !selectedSessionsBatch ||
        !selectedSessionsBatch.signatureSheetTemplates
      ) {
        return [];
      }

      return selectedSessionsBatch.signatureSheetTemplates.map((template) => ({
        label: template.label,
        value: template.id,
      }));
    },
  },

  methods: {
    updateBillingDescriptionForB2B(value) {
      this.client.billing = {
        ...this.client.billing,
        description: value ? value.description : "",
        label: value ? value.label : "",
      };
    },

    checkFields() {
      this.formErrors.client = !this.form.client;

      if (this.addOrderBlocked) {
        this.formErrors.client = true;
      }

      this.formErrors.sponsorship =
        this.form.sponsorship.isSponsorship &&
        !this.sponsoredStatus?.canBeSponsored;
      this.formErrors.noSponsor =
        this.form.sponsorship.isSponsorship &&
        !this.form.sponsorship.sponsorEmail;
      this.formErrors.session = !this.form.isCredit && !this.form.session;
      this.formErrors.concept = !this.form.concept;
      this.formErrors.merchantId = !this.form.merchantId;
      this.formErrors.price = !this.form.price;
      this.formErrors.menu = !this.form.isCredit && !this.form.menuStat;

      if (
        !this.form.isCredit &&
        this.form.payments &&
        this.form.payments.length > 0
      ) {
        if (this.form.payments.find((p) => !p.type)) {
          this.formErrors.missingType = true;
        } else {
          this.formErrors.missingType = false;
        }

        this.formErrors.paymentsDifference = !!this.paymentsDifference;
      } else {
        this.formErrors.missingType = false;
        this.formErrors.paymentsDifference = false;
      }

      if (this.form.hasVisio && !this.form.visio.prestationType) {
        this.formErrors.visioPrestationType = true;
      } else {
        this.formErrors.visioPrestationType = false;
      }

      this.formErrors.sessionsBatchId =
        this.form.signatureSheets && !this.form.sessionsBatchId;
      this.formErrors.signatureSheetTemplateId =
        this.form.signatureSheets && !this.form.signatureSheetTemplateId;

      return (
        Object.values(this.formErrors).filter((error) => error).length === 0
      );
    },

    async checkClient() {
      if (!this.form.client || !this.form.client.value) {
        this.clearSponsorshipOffer();
        this.sponsoredStatus = null;
        this.formErrors.client = false;
        return;
      }

      try {
        const { data } = await this.$api.get(
          "/clients/orders/unpaid-quantity",
          {
            params: {
              client: this.form.client.value,
            },
          }
        );

        this.unpaidQuantity = data.unpaidQuantity;
      } catch (e) {
        console.log(e);
      }

      this.checkIfClientCanBeSponsored();
    },

    async updatePaymentMethods() {
      const { data } = await this.$api.get("/payment-methods/by-concept", {
        params: { concept: this.form.concept },
      });

      this.paymentMethods = data;
    },

    async handleNewOrder() {
      this.form.client = this.client.email;
      this.form.description = this.client.billing.description;

      if (!this.checkFields() || this.formSending) {
        this.$errorToast("Les champs entourés en rouge sont obligatoires");
        return;
      }

      const isConversion =
        this.form.menuStat &&
        this.form.menuStat.value &&
        (this.form.menuStat.value === "decouverte" ||
          this.form.menuStat.value === "one-shot");

      const isAcquisition =
        this.form.menuStat &&
        this.form.menuStat.value &&
        (this.form.menuStat.value === "decouverte" ||
          this.form.menuStat.value === "pack-conversion" ||
          this.form.menuStat.value === "one-shot" ||
          this.form.menuStat.value === "autres");

      const isFidelisation =
        this.form.menuStat &&
        this.form.menuStat.value &&
        (this.form.menuStat.value === "pack-conversion" ||
          this.form.menuStat.value === "pack-fidelisation" ||
          this.form.menuStat.value === "one-shot");

      let defaultCoach;
      let defaultCredit;

      if (this.form.editSessions) {
        defaultCoach = this.form.defaultCoach && this.form.defaultCoach.value;
        defaultCredit = this.cleanedCredit;
      }

      try {
        this.$emit("orderLoading", true);
        this.formSending = true;

        const { data } = await this.$api.post("/orders", {
          merchantId: this.form.merchantId.value,
          client: this.form.client,
          session: this.form.session.value,
          allowed: this.form.allowed ? this.form.allowed.value : 0,
          concept: this.form.concept,
          commercial: this.form.commercial ? this.form.commercial.value : 0,
          discovery: isConversion && isAcquisition && !isFidelisation,
          isCredit: this.form.isCredit,
          price: this.form.price.replace(",", "."),
          labelDescription: this.client.billing.label,
          description: this.form.description,
          label: this.form.label,
          isConversion,
          isFidelisation,
          isAcquisition,
          defaultCoach,
          defaultCredit,
          giftCard: this.form.giftCard,
          sponsored: this.form.sponsorship.isSponsorship,
          sponsoredBy:
            this.form.sponsorship.isSponsorship &&
            this.form.sponsorship.sponsorEmail,
          isOneShotEvent: this.form.menuStat?.value === "one-shot",
          requiredSessionUserCount: this.form.requiredSessionUserCount,
          isAI: this.form.paymentData.isAI,
          sessionCoachBlocked: this.form.sessionCoachBlocked,
          attribution: this.form.attribution,
          payments: this.form.payments,
          hasVisio: this.form.hasVisio,
          visioPrestationType:
            this.form.visio.prestationType &&
            this.form.visio.prestationType.value,
          visioDuration:
            this.form.visio.visioDuration &&
            this.form.visio.visioDuration.value,
          sessionsBatchId: this.form.sessionsBatchId,
          signatureSheetTemplateId: this.form.signatureSheetTemplateId,
        });

        this.newOrder = false;

        if (!this.form.isCredit && this.form.sendEmail) {
          this.$confirm({
            message: "Envoyez un email pour informer le client ?",
            onConfirm: async () => {
              await this.sendEmailAfterCreation(data);
            },
          });
        }

        this.$emit("close");
      } catch (err) {
        this.formSending = false;
      } finally {
        this.$emit("orderLoading", false);
      }
    },

    handleChangeIsGiftCard() {
      if (this.form.giftCard && this.form.giftCard.isGiftCard) {
        this.form.menuStat = {
          label:
            "Pack OneShot/Event (Menu Conversion - Fidélisation / Stat Acquisition)",
          value: "one-shot",
          disable: false,
        };

        this.form.description = "Carte cadeau";
      }
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.name} (${c.usedEmail})`,
        firstName: c.firstName,
        lastName: c.lastName,
        company: c.company,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async searchCoachs(search, loading) {
      if (search !== "") {
        await this.fetchCoachs(search, loading, this);
      }
    },

    fetchCoachs: debounce(async (search, loading, vm) => {
      if (!search || search.lentgh < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/users/coachs/search", {
        query: search,
        filterBy: ["firstName", "lastName", "email"],
      });

      vm.coachs = data.map((c) => ({
        label: `${c.coach ? c.coach.name : ""} (${c.email})`,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async fetchClientCoachs() {
      if (this.form.client) {
        try {
          const { data } = await this.$api.get(
            `/clients/coachs/${this.form.client.value}`
          );

          this.coachs = data;
        } catch (e) {
          console.log(e);
        }
      }
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));

      this.commercials.push({
        label: "Aucun",
        value: "none",
      });
    },

    async fetchVisioPrestationsType() {
      const { data } = await this.$api.get("/visio/prestations-type");

      this.visioPrestationsType = data.map((prestation) => {
        return { label: prestation.label, value: prestation.uuid };
      });
    },

    async getClientCommercials(email) {
      const { data } = await this.$api.get(`/clients/by-email/${email}`);

      this.clients.push(data);

      if (data.commercials && data.commercials.length > 0) {
        const commercialToSet = data.commercials[0].user;

        if (commercialToSet) {
          this.form.commercial = {
            label: commercialToSet,
            value: commercialToSet,
          };
        }
      }
    },

    async fetchMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    async handleMerchantChange(merchant) {
      const DISABLED_FROM_CREATION = ["Just Coaching Live"];

      if (merchant) {
        const params = { merchantId: merchant.value };
        const { data } = await this.$api.get("/concepts", { params });

        this.concepts = [];
        this.concepts = data
          .filter((concept) => {
            if (!DISABLED_FROM_CREATION.includes(concept.name)) {
              return concept.name;
            }
          })
          .map(({ name }) => name);
      }
    },

    toggleAI() {
      if (this.form.paymentData.isAI) {
        this.form.paymentData.isCB = false;
      }

      this.createSubPayments();
    },

    createSubPayments(e) {
      if (e && typeof e.preventDefault === "function") e.preventDefault();
      if (!this.form.isCredit && this.form.price) {
        const payments = [];

        const nbOfSessions = this.form.session.value;
        const globalPrice = this.form.price;
        const nbPaiement = this.form.paymentData.countPayments;

        const sessionPrice = globalPrice / nbOfSessions;
        const quotient = Math.floor(nbOfSessions / nbPaiement);
        const remainder = nbOfSessions % nbPaiement;

        let type = this.form.paymentData.type;
        let deadline = null;

        if (this.form.paymentData.isCB) {
          type = this.form.paymentData.description;
        } else if (this.form.paymentData.isAI) {
          type = "Avance immédiate";
        }

        for (let index = 0; index < nbPaiement; index++) {
          let total = sessionPrice * quotient;
          let countSession = quotient;

          if (index < remainder) {
            total = (quotient + 1) * sessionPrice;
            countSession = quotient + 1;
          }

          payments.push({
            price: total.toFixed(2),
            captured: false,
            type,
            alert: false,
            alertMessage: "",
            deadline,
            isCB: this.form.paymentData.isCB,
            isAI: this.form.paymentData.isAI,
            nbSessions: countSession,
          });

          if (null === deadline) {
            deadline = moment(this.form.paymentData.wishedDay).format();
          } else {
            deadline = moment(deadline).add(1, "M").format();
          }
          this.form.paymentData.sessionPrice = sessionPrice;

          this.form.payments = payments;
        }
      }
    },

    async sendEmailAfterCreation(order) {
      await this.$api.post(`/orders/send-creation-email/${order.id}`);
    },

    changeHasVisio() {
      if (!this.form.hasVisio) {
        this.form.visio.prestationType = null;
      } else if (this.form.hasVisio && this.visioPrestationsType.length === 0) {
        this.fetchVisioPrestationsType();
      }
    },

    changeIsSponsorship() {
      if (this.form.sponsorship.isSponsorship) {
        if (this.form.client) {
          this.checkIfClientCanBeSponsored();
        }
      } else {
        this.clearSponsorshipOffer();
        this.form.sponsorship.sponsorEmail = null;
      }
    },

    checkIfEmpty() {
      if (!this.form.merchantId) {
        this.concepts = [];
        this.form.concept = null;
      }
    },

    checkStatusFilter() {
      if (this.isProblematicStatusFilter) {
        this.form.status = this.form.status.filter(
          (status) => status.isProblematic
        );

        this.orderStatus[0].status.forEach((status) => {
          status.$isDisabled = true;
        });
      } else {
        this.orderStatus[0].status.forEach((status) => {
          status.$isDisabled = false;
        });
      }
    },

    checkSponsor() {
      if (!this.form.sponsorship.sponsorEmail) {
        this.formErrors.autoSponsoring = false;
      } else {
        this.formErrors.noSponsor = false;

        if (
          this.form.client?.value &&
          this.form.sponsorship.sponsorEmail === this.form.client.value
        ) {
          this.formErrors.autoSponsoring = true;
        }
      }
    },

    async checkIfClientCanBeSponsored() {
      try {
        if (
          this.form.sponsorship.sponsorEmail &&
          this.form.sponsorship.sponsorEmail === this.form.client.value
        ) {
          this.formErrors.autoSponsoring = true;

          return;
        }

        const { data } = await this.$api.post("/clients/can-be-sponsored", {
          email: this.form.client.value,
        });

        this.sponsoredStatus = this.sponsorshipStatus.find(
          ({ value }) => value === data.status
        );

        if (this.form.sponsorship.isSponsorship) {
          if (this.sponsoredStatus.canBeSponsored) {
            if (this.form.menuStat?.value === "one-shot") {
              this.setOneShotEventSponsorshipStatus();
            } else {
              this.setFreeDiscovery();
            }
          } else {
            this.formErrors.sponsorship = true;
          }
        }
      } catch (e) {
        this.formErrors.client = true;
      }
    },

    clearSponsorshipOffer() {
      this.formErrors.sponsorship = false;
      this.formErrors.noSponsor = false;
      this.formErrors.autoSponsoring = false;
      this.form.description = null;
      this.form.menuStat = null;
      this.form.price = null;
    },

    setFreeDiscovery() {
      this.form.session = this.sessions.find(({ value }) => value === "1");
      this.form.clients = "1";
      this.form.description = "Parrainage - Séance découverte offerte";
      this.form.menuStat = this.menuStatsOptions.find(
        ({ value }) => value === "decouverte"
      );
      this.form.price = "0";
    },

    changeMenuStat(menuStat) {
      if (
        this.form.sponsorship.isSponsorship &&
        this.sponsoredStatus?.canBeSponsored
      ) {
        if (menuStat?.value === "one-shot") {
          this.setOneShotEventSponsorshipStatus();
        } else if (this.sponsoredStatus?.value !== "eligible") {
          this.setEligibleSponsorshipStatus();
        }
      }
    },

    setOneShotEventSponsorshipStatus() {
      const discoveryDone = this.sponsorshipStatus.find(
        (status) => status.value === "discovery-done"
      );

      const oneShotEventSponsorship = {
        ...discoveryDone,
        canBeSponsored: true,
      };

      this.sponsoredStatus = oneShotEventSponsorship;
    },

    setEligibleSponsorshipStatus() {
      this.sponsoredStatus = this.sponsorshipStatus.find(
        (status) => status.value === "eligible"
      );
    },

    clearPaymentType(payment) {
      payment.type = null;
    },

    formPriceInput(value) {
      this.form.price = this.formatFormPrice(value);

      this.createSubPayments();
    },

    formPaymentAiSessionInput(value, payment) {
      payment.price = (value * this.form.paymentData.sessionPrice).toFixed(2);
    },

    async getCompany(companyId) {
      if (!companyId) {
        return;
      }

      try {
        const { data } = await this.$api.get(`/companies/${companyId}`);

        this.company = data;
      } catch (e) {
        this.company = null;
      }
    },
  },
};
</script>

<style>
.has-error {
  border: 1px solid #ff5454;
  border-radius: 4px;
}

.custom-modal .modal-lg {
  max-width: 1100px;
}
</style>
