<template>
  <div>
    <div class="card" v-if="payments && order.status !== 'refunded'">
      <div class="card-header">
        <i class="icon-credit-card"></i> Paiements
        <button
          v-if="
            order.status !== 'pending' &&
            hasPermission($store.state.user, 'ORDER_PAYMENT_WRITE')
          "
          type="button"
          class="btn btn-sm btn-primary float-right"
          @click="addCredit = true"
        >
          <i class="fa fa-minus"></i>&nbsp; Créer un Remboursement
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary float-right"
          v-if="
            needPayments &&
            hasPermission($store.state.user, 'ORDER_PAYMENT_WRITE')
          "
          @click="addPayment = true"
        >
          <i class="fa fa-plus"></i>&nbsp; Ajouter manuellement
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary float-right"
          v-if="
            needPayments &&
            hasPermission($store.state.user, 'ORDER_PAYMENT_WRITE')
          "
          @click="addStripePayment = true"
        >
          <i class="fa fa-credit-card"></i>&nbsp; Créer un paiement par Carte
          Bleue
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary float-right"
          v-if="hasPayments && hasPermission($store.state.user, 'ORDERS_WRITE')"
          @click="sendInformationEmail"
        >
          <i class="fa fa-envelope"></i>&nbsp; Email d'information
        </button>
      </div>
      <div class="card-block payments-custom-wrapper">
        <payments
          :paymentsData.sync="payments"
          :client="client"
          @pending="reloadOrder"
          @captured="reloadOrder"
        />
      </div>
    </div>

    <modal
      title="Ajouter un remboursement"
      v-model="addCredit"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleSaveCredit()"
      effect="fade/zoom"
      large
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter un remboursement</h4>
      </div>
      <div class="row">
        <div class="col-md-4 form-group">
          <label>Description</label>
          <div class="input-group">
            <input v-model="formCredit.type" type="text" class="form-control" />
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Montant du remboursement</label>
          <div class="input-group">
            <input
              v-model="formCredit.credit"
              type="text"
              class="form-control"
              @input="(e) => creditInput(e.target.value)"
            />
            <span class="input-group-addon"><i class="fa fa-euro"></i></span>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Date du remboursement</label>
          <div class="input-group">
            <el-date-picker
              v-model="formCredit.capturedAt"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              type="date"
            />
          </div>
        </div>
      </div>
    </modal>

    <modal
      title="Ajouter un paiement"
      v-model="addPayment"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleSavePayment()"
      effect="fade/zoom"
      large
    >
      <div
        slot="modal-header"
        class="modal-header d-flex justify-content-between"
      >
        <h4 class="modal-title">Ajouter un paiement</h4>
        <div v-if="this.order.sessionCoachBlocked">
          <label>Supprimer les restrictions</label>
          <el-popover trigger="hover" placement="top" :width="400">
            <div class="p-1 text-justify">
              <p>
                Permet de retirer les restrictions de montant d'un paiement.
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
          <div class="input-group">
            <el-checkbox v-model="form.force" @input="createSubPayments" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" v-if="form.force">
          <div class="alert alert-danger">
            <strong>Attention</strong> En désactivant les restrictions vous vous
            exposez à des erreurs, veuillez bien vérifier les montants saisis.
            Les séances seront par ailleurs toutes débloquées, cette case ne
            doit JAMAIS être cochée dans le cadre d'un paiement en avance
            immédiate.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Description</label>
        <div class="input-group">
          <span class="input-group-addon">Paiement par</span>
          <select
            v-model="form.type"
            class="form-control"
            @change="createSubPayments"
          >
            <option
              :key="key"
              v-for="(pm, key) in paymentMethods"
              :value="pm.name"
            >
              {{ pm.name }}
            </option>
          </select>
        </div>
        <div
          class="input-group"
          style="margin-top: -1px"
          v-if="form.type == 'manual'"
        >
          <input
            v-model="form.typeManual"
            type="text"
            class="form-control"
            placeholder="Saisir un nom de paiement"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Prix</label>
          <div class="input-group">
            <input
              v-model="form.price"
              type="text"
              class="form-control"
              v-on:blur="addMaximumPrice"
              @input="createSubPayments"
            />
            <span class="input-group-addon"><i class="fa fa-euro"></i></span>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Nombre d'échéances</label>
          <div class="input-group">
            <input
              v-model="form.countPayments"
              type="number"
              min="1"
              class="form-control"
              @input="createSubPayments"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12" v-if="formDifference">
          <div class="alert alert-danger">
            Veuillez ajuster le paiement de : {{ formDifference }} €
          </div>
        </div>
        <div class="col-sm-12" v-if="form.sessionPrice && !form.force">
          <div class="alert alert-info" v-if="form.sessionPrice">
            <strong>Information</strong> Prix d'une séance
            {{ form.sessionPrice }} €
          </div>
        </div>
        <div class="col-sm-12">
          <el-table
            v-if="!order.sessionCoachBlocked"
            style="width: 100%"
            :data="form.payments"
          >
            <el-table-column label="Prix">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.price"
                  @input="
                    (e) => formPaymentPriceInput(e.target.value, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="Description">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.type"
                />
              </template>
            </el-table-column>
            <el-table-column label="Payé ?">
              <template slot-scope="scope">
                <select v-model="scope.row.captured" class="form-control">
                  <option value="0">Non</option>
                  <option value="1">Oui</option>
                </select>
              </template>
            </el-table-column>
            <el-table-column label="Date d'échéance" width="150">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </template>
            </el-table-column>
            <el-table-column label="Alerte">
              <template slot-scope="scope">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="scope.row.alert"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
              </template>
            </el-table-column>
            <el-table-column label="Message d'Alerte" width="180">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.alertMessage"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-table v-else style="width: 100%" :data="form.payments">
            <el-table-column label="Prix">
              <template slot-scope="scope">
                <p>{{ scope.row.price }} €</p>
              </template>
            </el-table-column>
            <el-table-column label="Séances" width="125">
              <template slot-scope="scope">
                <input
                  v-if="!form.force"
                  v-model="scope.row.nbSessions"
                  type="number"
                  class="form-control"
                  @input="
                    (e) => formPaymentSessionInput(e.target.value, scope.row)
                  "
                />
                <p v-else>{{ scope.row.nbSessions }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Description">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.type"
                />
              </template>
            </el-table-column>
            <el-table-column label="Payé ?">
              <template slot-scope="scope">
                <select v-model="scope.row.captured" class="form-control">
                  <option value="0">Non</option>
                  <option value="1">Oui</option>
                </select>
              </template>
            </el-table-column>
            <el-table-column label="Date d'échéance" width="150">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </template>
            </el-table-column>
            <el-table-column label="Alerte">
              <template slot-scope="scope">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="scope.row.alert"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
              </template>
            </el-table-column>
            <el-table-column label="Message d'Alerte" width="180">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.alertMessage"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </modal>

    <modal
      title="Créer un paiement par Carte Bleue"
      v-model="addStripePayment"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleAddStripePayment()"
      effect="fade/zoom"
      large
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Créer un paiement par Carte Bleue</h4>
      </div>
      <div class="form-group">
        <label style="width: 100%">
          Description pour le client
          <a class="pull-right" href="#" @click="createSubStripePayments">
            <i class="fa fa-refresh"></i>
          </a>
        </label>
        <input type="text" v-model="formStripe.type" class="form-control" />
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Prix</label>
          <div class="input-group">
            <input
              v-model="formStripe.price"
              type="text"
              class="form-control"
              v-on:blur="addMaximumStripePrice"
              @input="createSubStripePayments"
            />
            <span class="input-group-addon"><i class="fa fa-euro"></i></span>
          </div>
        </div>
        <div class="form-group col-sm-3">
          <label>Nombre d'échéances</label>
          <div class="input-group">
            <input
              v-model="formStripe.countPayments"
              type="number"
              min="1"
              class="form-control"
              @input="createSubStripePayments"
            />
          </div>
        </div>
        <div class="form-group col-sm-3">
          <label style="width: 100%">
            Première échéance
            <a class="pull-right" href="#" @click="createSubStripePayments">
              <i class="fa fa-refresh"></i>
            </a>
          </label>
          <datepicker
            v-model="formStripe.wishedDay"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12" v-if="formStripe.sessionPrice">
          <div class="alert alert-info" v-if="formStripe.sessionPrice">
            <strong>Information</strong> Prix d'une séance
            {{ formStripe.sessionPrice }} €
          </div>
        </div>
        <div class="col-sm-12" v-if="stripeFormDifference">
          <div class="alert alert-danger">
            Veuillez ajuster le paiement de : {{ stripeFormDifference }} €
          </div>
        </div>
        <div class="col-sm-12" v-if="missingType">
          <div class="alert alert-danger">
            Veuillez ajouter une description aux paiements.
          </div>
        </div>
        <div class="col-sm-12">
          <el-table
            v-if="!order.sessionCoachBlocked"
            style="width: 100%"
            :data="this.formStripe.payments"
          >
            <el-table-column label="Prix">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.price"
                  @input="
                    (e) => formPaymentPriceInput(e.target.value, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="Date d'échéance" width="150">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  type="date"
                  style="width: 100%"
                />
              </template>
            </el-table-column>
            <el-table-column label="Alerte">
              <template slot-scope="scope">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="scope.row.alert"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
              </template>
            </el-table-column>
            <el-table-column label="Message d'Alerte">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.alertMessage"
                />
              </template>
            </el-table-column>
            <el-table-column label="Description">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.type"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-table v-else style="width: 100%" :data="this.formStripe.payments">
            <el-table-column label="Prix">
              <template slot-scope="scope">
                <p>{{ scope.row.price }} €</p>
              </template>
            </el-table-column>
            <el-table-column label="Séances" width="125">
              <template slot-scope="scope">
                <input
                  v-model="scope.row.nbSessions"
                  type="number"
                  class="form-control"
                  @input="
                    (e) => formCBPaymentSessionInput(e.target.value, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="Date d'échéance" width="150">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  type="date"
                  style="width: 100%"
                />
              </template>
            </el-table-column>
            <el-table-column label="Alerte">
              <template slot-scope="scope">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="scope.row.alert"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
              </template>
            </el-table-column>
            <el-table-column label="Message d'Alerte">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.alertMessage"
                />
              </template>
            </el-table-column>
            <el-table-column label="Description">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.type"
                />
              </template>
            </el-table-column>
          </el-table>
          <span class="info-paiement">
            Le payment automatique sera déclanché après le premier, dans le cas
            ou le prélèvement automatique à été coché.
          </span>
        </div>
      </div>
      <small v-if="!client || !client.stripe" class="text-danger">
        <i class="fa fa-exclamation-triangle" />
        Le client n'a pas encore de Carte bleue Stripe, prélèvement automatique
        impossible.
      </small>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import Payments from "../components/Payments";

export default {
  components: {
    Payments,
    Modal,
    Datepicker,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    paymentProps: {
      type: Array,
      required: true,
    },
    needPayments: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      missingType: null,
      payments: [],
      addCredit: false,
      addPayment: false,
      addStripePayment: false,
      formStripe: {
        price: null,
        type: null,
        countPayments: 1,
        wishedDay: moment().add("15", "days").format(),
        payments: [],
        sessionPrice: null,
      },
      form: {
        force: false,
        type: "Chèque",
        typeManual: null,
        price: null,
        countPayments: 1,
        payments: [],
        sessionPrice: null,
      },
      formCredit: {
        type: "",
        capturedAt: moment().format(),
        price: null,
      },
      stripeKey: null,
      paymentMethods: [],
    };
  },

  async created() {
    this.payments = this.paymentProps;

    try {
      this.$api
        .get("/payment-methods/by-concept", {
          params: { concept: this.order.concept },
        })
        .then((res) => {
          this.paymentMethods = res.data;
        });

      const { data } = await this.$api(
        `/clients/stripe-key/${this.client.id}`,
        {
          params: {
            merchantId: this.order.merchantId,
            concept: this.order.concept,
          },
        }
      );

      this.stripeKey = data;
    } catch (e) {
      console.log(e);
    }
  },

  computed: {
    formDifference() {
      if (this.form.force === true) {
        return 0;
      }

      let diff = 0;

      if (this.form.payments) {
        let totalsPayments = 0;

        for (const p of this.form.payments) {
          totalsPayments += +p.price;
        }

        diff = this.form.price - totalsPayments;
      }

      return diff.toFixed(2) === "0.00" || diff.toFixed(2) === "-0.00"
        ? 0
        : diff.toFixed(2);
    },

    stripeFormDifference() {
      let diff = 0;

      if (this.formStripe.payments) {
        let totalsPayments = 0;

        for (const p of this.formStripe.payments) {
          totalsPayments += +p.price;
        }

        diff = this.formStripe.price - totalsPayments;
      }

      return diff.toFixed(2) === "0.00" || diff.toFixed(2) === "-0.00"
        ? 0
        : diff.toFixed(2);
    },

    hasPayments() {
      return this.payments.length > 0;
    },
  },

  methods: {
    createSubPayments() {
      if (!this.form.price) return;
      if (!this.order.sessionCoachBlocked) {
        this.form.price = this.formatFormPrice(this.form.price);

        const payments = [];
        const price = (this.form.price / this.form.countPayments).toFixed(2);

        let deadline = moment().add(15, "day").format();

        for (let index = 0; index < this.form.countPayments; index++) {
          payments.push({
            price,
            captured: false,
            type: this.form.type,
            alert: false,
            alertMessage: "",
            deadline,
          });

          deadline = moment(deadline).add(1, "M").format();
        }

        this.form.payments = payments;
      } else {
        const payments = [];

        const capturedPayments = this.order.payments.filter((p) => p.captured);
        const nbOfSessionsAlreadyCapture = capturedPayments.reduce(
          (acc, p) => acc + p.nbSessions,
          0
        );
        const nbOfSessions = this.order.session - nbOfSessionsAlreadyCapture;
        const globalPrice = this.form.price;
        const nbPaiement = this.form.countPayments;
        const sessionPrice = globalPrice / nbOfSessions;
        const quotient = Math.floor(nbOfSessions / nbPaiement);
        const remainder = nbOfSessions % nbPaiement;

        let type = this.form.type;
        let deadline = moment().add(15, "day").format();

        for (let index = 0; index < nbPaiement; index++) {
          let total = sessionPrice * quotient;
          let countSession = quotient;

          if (index < remainder) {
            total = (quotient + 1) * sessionPrice;
            countSession = quotient + 1;
          }

          if (isNaN(total) && this.form.force) {
            total = this.form.price / this.form.countPayments;
            countSession = 0;
          }

          payments.push({
            price: total.toFixed(2),
            captured: false,
            type,
            alert: false,
            alertMessage: "",
            deadline,
            isCB: false,
            isAI: false,
            nbSessions: countSession,
          });

          deadline = moment(deadline).add(1, "M").format();

          this.form.sessionPrice = sessionPrice;
          this.form.payments = payments;
        }
      }
    },

    createSubStripePayments() {
      if (!this.formStripe.price) return;
      if (!this.order.sessionCoachBlocked) {
        this.formStripe.price = this.formatFormPrice(this.formStripe.price);

        const payments = [];
        const price = (
          this.formStripe.price / this.formStripe.countPayments
        ).toFixed(2);

        let deadline = moment(this.formStripe.wishedDay).format();

        for (let index = 0; index < this.formStripe.countPayments; index++) {
          payments.push({
            price,
            type: this.formStripe.type,
            alert: false,
            alertMessage: "",
            deadline,
            autoCaptureAt: deadline,
            stripe: {
              status: "waiting",
            },
          });

          deadline = moment(deadline).add(1, "M").format();
        }

        this.formStripe.payments = payments;
      } else {
        const payments = [];
        const capturedPayments = this.order.payments.filter((p) => p.captured);
        const nbOfSessionsAlreadyCapture = capturedPayments.reduce(
          (acc, p) => acc + p.nbSessions,
          0
        );
        const nbOfSessions = this.order.session - nbOfSessionsAlreadyCapture;
        const globalPrice = this.formStripe.price;
        const nbPaiement = this.formStripe.countPayments;

        const sessionPrice = globalPrice / nbOfSessions;
        const quotient = Math.floor(nbOfSessions / nbPaiement);
        const remainder = nbOfSessions % nbPaiement;
        let deadline = null;

        for (let index = 0; index < nbPaiement; index++) {
          let total = sessionPrice * quotient;
          let countSession = quotient;

          if (index < remainder) {
            total = (quotient + 1) * sessionPrice;
            countSession = quotient + 1;
          }

          payments.push({
            price: total.toFixed(2),
            captured: false,
            type: this.formStripe.type,
            alert: false,
            alertMessage: "",
            deadline,
            isCB: true,
            isAI: false,
            nbSessions: countSession,
            autoCaptureAt: deadline,
            stripe: {
              status: "waiting",
            },
          });

          if (null === deadline) {
            deadline = moment(this.formStripe.wishedDay).format();
          } else {
            deadline = moment(deadline).add(1, "M").format();
          }

          this.formStripe.sessionPrice = sessionPrice;
          this.formStripe.payments = payments;
        }
      }
    },

    formPaymentSessionInput(value, payment) {
      payment.price = (value * this.form.sessionPrice).toFixed(2);
    },

    formCBPaymentSessionInput(value, payment) {
      payment.price = (value * this.formStripe.sessionPrice).toFixed(2);
    },

    amountDiff(raw) {
      let payed = 0;

      for (let payment of this.order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }
      }

      return raw
        ? this.order.price - payed
        : this.formatPrice(this.order.price - payed);
    },

    addMaximumPrice() {
      if (parseFloat(this.form.price) > parseFloat(this.needPayments)) {
        this.form.price = this.needPayments;

        this.createSubPayments();
      }
    },

    addMaximumStripePrice() {
      if (parseFloat(this.formStripe.price) > parseFloat(this.needPayments)) {
        this.formStripe.price = this.needPayments;

        this.createSubStripePayments();
      }
    },

    async handleSaveCredit() {
      if (this.formCredit.credit && this.formCredit.type) {
        const { credit, type, capturedAt } = this.formCredit;

        const payment = {
          price: -credit.replace(",", "."),
          type,
          captured: true,
          capturedAt,
        };

        try {
          await this.$api.post(`/payments/order/${this.order.id}`, payment);
        } catch (e) {
          console.log(e);
        }

        this.reloadOrder();
      }
    },

    async handleSavePayment() {
      if (this.form.price && this.form.payments && !this.formDifference) {
        this.missingType = this.form.payments.find((p) => !p.type);

        if (!this.missingType) {
          for (const payment of this.form.payments) {
            if (payment.captured === "1") {
              payment.captured = true;
            } else {
              payment.captured = false;
            }

            try {
              await this.$api.post(`/payments/order/${this.order.id}`, payment);
            } catch (e) {
              console.log(e);
            }
          }

          this.reloadOrder();
        }
      }
    },

    async handleAddStripePayment() {
      if (
        this.formStripe.price &&
        this.formStripe.payments &&
        !this.stripeFormDifference
      ) {
        this.missingType = this.formStripe.payments.find((p) => !p.type);

        if (!this.missingType) {
          for (const payment of this.formStripe.payments) {
            try {
              await this.$api.post(`/payments/order/${this.order.id}`, payment);
            } catch (e) {
              console.log(e);
            }
          }

          this.reloadOrder();
        }
      }
    },

    reloadOrder() {
      window.location.reload(true);
    },

    sendInformationEmail() {
      this.$confirm({
        message:
          "Envoyer un email au client avec les informations de paiements ?",
        onConfirm: async () => {
          try {
            await this.$api.post(
              `/orders/send-creation-email/${this.order.id}`
            );

            this.$successToast("Email envoyé");
          } catch (e) {
            console.log(e);
          }
        },
      });
    },

    creditInput(value) {
      this.formCredit.credit = this.formatFormPrice(value);
    },

    formPaymentPriceInput(value, payment) {
      payment.price = this.formatFormPrice(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-paiement {
  font-style: italic;
  margin-top: 15px;
  display: inline-block;
}

/*[TODO] May we improved */
.payments-custom-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .payments-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .payments-custom-wrapper {
  max-width: calc(100vw - 70px);
}
</style>
