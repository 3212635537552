<template>
  <modal v-model="visible" @cancel="closeModal" effect="fade/zoom" large>
    <Loader :is-visible="loading" />

    <div slot="modal-header" class="modal-header">
      <div>
        <h4>
          <i class="fa fa-commenting" style="margin-right: 0.5rem"></i> Notebook
          ({{ notebookData.length }})
          <span v-if="client"> - {{ client.fullName }}</span>
        </h4>
        <h5 v-if="order" class="text-muted">
          <span v-if="order.label">{{ order.label }} - </span>
          <span v-if="order.discovery">Séance découverte</span>
          <span v-else>{{
            order.session > 1 ? `Pack ${order.session} séances` : "1 séance"
          }}</span>
          <span> - {{ formatDate(order.createdAt) }}</span>
        </h5>
      </div>
      <button type="button" class="btn btn-sm btn-primary" @click="closeModal">
        Fermer
      </button>
    </div>

    <div class="row">
      <div class="col-6 text-center">
        Commerciaux ({{ commercialNotes.length }})
      </div>
      <div class="col-6 text-center">Coachs ({{ coachNotes.length }})</div>
    </div>

    <hr />

    <div class="row">
      <div class="col-6 notebook-list border-right">
        <div v-if="canAddNote" class="border-bottom pb-1">
          <button
            v-if="!newNote"
            type="button"
            class="btn btn-sm btn-primary"
            @click.stop="startNewNote"
          >
            <i class="fa fa-plus"></i> Ajouter
          </button>

          <NotebookItem
            v-if="newNote"
            :item="newNote"
            @add="addNote"
            @cancel="newNote = undefined"
          />
        </div>

        <NotebookItem
          v-for="note in commercialNotes"
          :key="note.id"
          :item="note"
          :isCurrentOrderNote="order && note.orderId === order.id"
          @update="updateNote"
          @delete="deleteNote"
        />
      </div>
      <div class="col-6 notebook-list">
        <NotebookItem
          v-for="note in coachNotes"
          :key="note.id"
          :item="note"
          :isCurrentOrderNote="order && note.orderId === order.id"
          @update="updateNote"
          @delete="deleteNote"
        />
      </div>
    </div>

    <div slot="modal-footer"></div>
  </modal>
</template>

<script>
import { mapState } from "vuex";
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";
import NotebookItem from "./NotebookItem";

export default {
  components: {
    Modal,
    Loader,
    NotebookItem,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    notebook: {
      type: Array,
      default: () => [],
    },

    client: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      notebookData: [],
      loading: false,
      visible: false,
      activeTab: 0,
      newNote: undefined,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),

    canAddNote() {
      return (
        this.isAdmin(this.user) ||
        this.hasPermission(this.user, "CLIENTS_WRITE")
      );
    },

    commercialNotes() {
      return this.notebookData
        .filter((note) => !!note.commercial || !!note.admin)
        .map((note) => ({ ...note, order: note.order }));
    },

    coachNotes() {
      return this.notebookData
        .filter((note) => !!note.coach && !note.admin && !note.commercial)
        .map((note) => ({ ...note, order: note.order }));
    },
  },

  watch: {
    show(value) {
      this.visible = value;
    },
    notebook(value) {
      this.notebookData = value;
    },
  },

  created() {
    this.visible = this.show;
    this.notebookData = this.notebook;
  },

  methods: {
    closeModal() {
      this.$emit("close");
      this.newNote = undefined;
    },

    startNewNote() {
      const { email } = this.user;

      const newNote = {
        createdAt: new Date().toISOString(),
        note: "",
        userEmail: email,
        order: this.order,
      };

      if (this.isAdmin(this.user)) {
        newNote.admin = { name: this.user.firstName };
      } else if (this.user.commercial) {
        const { pseudo, color } = this.user.commercial;

        newNote.commercial = { pseudo, color };
      }

      this.newNote = newNote;
    },

    async addNote(note) {
      try {
        this.loading = true;

        const { data } = await this.$api.post("/notebook", {
          ...note,
          clientEmail: this.client.email,
          orderId: this.order?.id,
        });

        this.notebookData = [data, ...this.notebookData];

        this.$emit("added", data);
      } catch (e) {
        this.$errorToast("Impossible d'ajouter la note");
      } finally {
        this.newNote = undefined;
        this.loading = false;
      }
    },

    async updateNote(note) {
      try {
        this.loading = true;

        const { data } = await this.$api.put(`/notebook/${note.id}`, note);

        this.$emit("updated", data);
      } catch (e) {
        this.$errorToast("Impossible de mettre à jour la note");
      } finally {
        this.loading = false;
      }
    },

    async deleteNote(noteId) {
      try {
        this.loading = true;

        await this.$api.delete(`/notebook/${noteId}`);

        this.notebookData = this.notebookData.filter(
          (note) => note.id !== noteId
        );

        this.$emit("deleted", noteId);
      } catch (e) {
        this.$errorToast("Impossible de supprimer la note");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.notebook-list {
  max-height: 500px;
  overflow-y: auto;
}

.border-right {
  border-right: solid 1px #e1e6ef;
}

.border-bottom {
  border-bottom: solid 1px #e1e6ef;
}
</style>
