<template>
  <modal
    title="Confirmer"
    :value="visible"
    okText="Envoyer"
    cancelText="Annuler"
    @ok="sendEvent"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <p>
            Voulez-vous envoyer le contenu
            {{ this.selectedEvent?.title }} avec une notification ?
          </p>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label
            @click.stop=""
            class="switch switch-sm switch-text switch-info"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="withNotification"
            />
            <span class="switch-label" data-on="Oui" data-off="Non"></span>
            <span class="switch-handle"></span>
          </label>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal.vue";
import Loader from "../components/Loader.vue";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    selectedEvent: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      withNotification: false,
      isVisible: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },

    async sendEvent() {
      try {
        this.loading = true;

        await this.$api.post(`/events/${this.selectedEvent.id}/send`, {
          withNotification: this.withNotification,
        });

        this.$emit("added");
        this.withNotification = false;
        this.closeModal();
        this.$successToast("Le message à bien été envoyé");
      } catch (e) {
        this.$errorToast("Impossible d'envoyer ce contenu");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
