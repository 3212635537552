<template>
  <div>
    <StatsFilters
      :coachs="filterCoachs"
      :concepts="concepts"
      :commercials="commercials"
      :coachManagers="coachManagers"
      :show-date="true"
      :is-conversion-stats="true"
      @apply-filters="applyFilters"
    />

    <div class="animated fadeIn">
      <div class="card" v-if="!loading">
        <div class="card-block">
          <div class="row">
            <template v-if="archived">
              <div class="col-md-3">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="rankPending"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
                En Attente
              </div>
              <div class="col-md-3">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="rankConversion"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
                Converti
              </div>
              <div class="col-md-3">
                <label class="switch switch-sm switch-text switch-info mb-0">
                  <input
                    type="checkbox"
                    class="switch-input"
                    v-model="rankLost"
                  />
                  <span class="switch-label" data-on="On" data-off="Off"></span>
                  <span class="switch-handle"></span>
                </label>
                Perdu
              </div>
            </template>
            <template v-else>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4">
                    <label
                      class="switch switch-sm switch-text switch-info mb-0"
                    >
                      <input
                        type="checkbox"
                        class="switch-input"
                        v-model="completed"
                      />
                      <span
                        class="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                    Séance terminée
                  </div>
                </div>
              </div>
            </template>
            <div class="col-md-3 text-right">
              <button
                class="btn btn-sm btn-primary mr-1"
                @click="pushAllCoachModal()"
              >
                <i class="icon-paper-plane"></i> Push aux coachs
              </button>
              <button
                v-if="!archived"
                class="btn btn-sm btn-secondary"
                @click="archived = true"
              >
                Voir les archives
              </button>
              <button
                v-else
                class="btn btn-sm btn-primary"
                @click="archived = false"
              >
                Voir les séances à traiter
              </button>
            </div>
          </div>
          <div class="row">
            <div v-if="!archived && !completed" class="col-md-3">
              <label class="switch switch-sm switch-text switch-info mb-0">
                <input
                  type="checkbox"
                  class="switch-input"
                  v-model="unAssigned"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
              </label>
              Non attribuées
            </div>
            <div v-if="!archived && !completed" class="col-md-3">
              <label class="switch switch-sm switch-text switch-info mb-0">
                <input
                  type="checkbox"
                  class="switch-input"
                  v-model="unScheduled"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
              </label>
              Non planifiées
            </div>
            <div v-if="!archived && !completed" class="col-md-3">
              <label class="switch switch-sm switch-text switch-info mb-0">
                <input
                  type="checkbox"
                  class="switch-input"
                  v-model="hasSchedule"
                />
                <span class="switch-label" data-on="On" data-off="Off"></span>
                <span class="switch-handle"></span>
              </label>
              Planifiées
            </div>
          </div>
        </div>
      </div>

      <div class="card card-inverse card-info text-center" v-if="loading">
        <div class="card-block">
          <blockquote class="card-blockquote">
            Chargement en cours ...
          </blockquote>
        </div>
      </div>

      <div v-else class="card card-responsive">
        <div class="card-block">
          <el-table
            :data="orders"
            :default-sort="{ prop: 'scheduledDate', order: 'descending' }"
          >
            <el-table-column width="25">
              <template slot-scope="scope">
                <i
                  v-if="scope.row.rank === 'conversion'"
                  class="text-success fa fa-check-circle"
                />
                <i
                  v-else-if="
                    scope.row.rank === 'lost' || scope.row.rank === 'lost_other'
                  "
                  class="text-danger fa fa-exclamation-circle"
                />
                <i v-else class="text-warning fa fa-circle" />
              </template>
            </el-table-column>

            <el-table-column :label="label" prop="client" width="210">
              <template slot-scope="scope">
                <div class="d-flex">
                  <a href="#" @click="(e) => goToClient(e, scope.row.clientId)">
                    {{ scope.row.client }}
                  </a>
                </div>
                <div>
                  <a href="#" @click="(e) => goToClient(e, scope.row.clientId)">
                    {{ scope.row.company }}
                  </a>
                </div>
                <div class="d-flex">
                  <router-link :to="'/orders/' + scope.row.id">
                    <span v-if="scope.row.free">Offert</span>
                    <span v-else>{{ scope.row.price }}</span>
                  </router-link>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Coachs" prop="coachs" sortable>
              <template slot-scope="scope">
                <span v-for="(name, i) in scope.row.coachsArray" :key="i">
                  <a @click="(e) => goToCoach(e, scope.row, name)" href="#">
                    {{ name }}
                    <i class="icon-user"></i>
                  </a>
                  <span v-if="i < scope.row.coachsArray.length - 1">, </span>
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Séances" prop="completed">
              <template slot-scope="scope">
                <button
                  class="btn btn-sm btn-secondary"
                  @click="show(scope.row)"
                >
                  <i class="fa fa-eye" /> {{ scope.row.state }}
                </button>
                <template
                  v-if="scope.row.sessions && scope.row.sessions.length"
                >
                  <br />
                  <el-tag v-if="scope.row.completed" size="mini" type="success">
                    Terminée
                  </el-tag>
                  <el-tag
                    v-else-if="isScheduled(scope.row)"
                    size="mini"
                    type="primary"
                  >
                    Planifiée
                  </el-tag>
                  <el-tag
                    v-else-if="isUnScheduled(scope.row)"
                    size="mini"
                    type="warning"
                  >
                    Non planifiée
                  </el-tag>
                  <el-tag v-else size="mini" type="danger">
                    Non attribuée
                  </el-tag>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Notes" prop="notes" width="220">
              <template slot-scope="scope">
                <NoteModal
                  :notes="scope.row.conversion.notes"
                  :key="scope.row.id"
                  @showComment="showComment(scope.row)"
                  permission="CONVERSION_WRITE"
                  v-if="hasPermission($store.state.user, 'CONVERSION_WRITE')"
                />
                <span v-else>{{ scope.row.conversion.notes }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Planifié le" prop="scheduledDate" sortable>
              <template slot-scope="scope">
                <span
                  v-bind:class="{
                    'font-weight-bold': now > scope.row.scheduledDate,
                  }"
                >
                  {{ scope.row.scheduledAt }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="archived ? 'Archivé le' : 'Date commande'"
              prop="dateSort"
              width="140"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.date }}
              </template>
            </el-table-column>

            <el-table-column label="Notebook" prop="notebook">
              <template slot-scope="scope">
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="showNotebook(scope.row)"
                  v-tooltip="concatedNotes(scope.row)"
                >
                  <i class="fa fa-commenting"></i>
                  ({{ scope.row.notebook ? scope.row.notebook.length : 0 }})
                  Consulter
                </button>
              </template>
            </el-table-column>

            <el-table-column label="Commercial">
              <template slot-scope="scope">
                <template v-for="(rel, index) of scope.row.commercials">
                  <CommercialRel :key="`com-${index}`" :relation="rel" />
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Coach manager" width="140">
              <template slot-scope="scope">
                <template v-for="(rel, index) of scope.row.coachManagers">
                  <CoachManagerRel
                    :key="`coach-manager-${index}`"
                    :relation="rel"
                  />
                </template>
              </template>
            </el-table-column>

            <el-table-column v-if="!archived" label="Paiement">
              <template slot-scope="scope">
                <span class="badge badge-default" v-if="scope.row.free"
                  >Offerte</span
                >
                <span
                  class="badge badge-success"
                  v-else-if="scope.row.status === 'payed'"
                  >Payée</span
                >
                <span
                  class="badge badge-info"
                  v-else-if="scope.row.status === 'refunded'"
                  >Avoir</span
                >

                <span
                  class="badge badge-success"
                  v-else-if="scope.row.status === 'unpaid'"
                  >Impayé (traité)</span
                >

                <span
                  class="badge badge-danger"
                  v-else-if="scope.row.status === 'pending'"
                  >En attente du paiement</span
                >
                <span
                  class="badge badge-warning"
                  v-else-if="scope.row.status === 'partial'"
                  >Paiement partiel</span
                ><br />
              </template>
            </el-table-column>

            <el-table-column width="150" align="right">
              <template
                slot-scope="scope"
                v-if="hasPermission($store.state.user, 'CONVERSION_WRITE')"
              >
                <button
                  class="btn btn-sm btn-primary mr-1"
                  v-if="!scope.row.scheduledAt && scope.row.coachs"
                  @click="pushToCoach(scope.row)"
                >
                  <i class="icon-paper-plane"></i>
                </button>
                <button
                  v-if="archived"
                  class="btn btn-sm btn-primary"
                  @click="unarchive(scope.row)"
                >
                  <i class="fa fa-repeat" />
                </button>
                <button
                  v-else
                  class="btn btn-sm btn-primary"
                  @click="archive(scope.row)"
                >
                  <i class="fa fa-archive" />
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <modal
      v-model="showModal"
      :title="title"
      effect="fade/zoom"
      cancelText="Annuler"
      okText="Enregistrer"
      large
      @ok="onSave"
      @cancel="cancelOrderModal"
    >
      <conversion-conversion
        v-if="order"
        :id="order.id"
        :notes.sync="order.conversion.notes"
        :sessions.sync="order.conversion.sessions"
        :price.sync="order.conversion.price"
        :rank.sync="order.rank"
        :client-is-lost.sync="clientLost.isLost"
        :client-lost-reason.sync="clientLost.lostReason"
        :client-detail-lost-reason.sync="clientLost.detailLostReason"
        :coachs="order.coachsArray"
        :is-prospect="isProspect"
        conversion
      />
      <Sessions v-if="order" :sessions-data="order.sessions" />
    </modal>

    <modal
      v-model="pushAll.display"
      title="Push aux coachs"
      effect="fade/zoom"
      cancelText="Annuler"
      okText="Envoyer"
      large
      @ok="pushNotifAllCoach()"
    >
      <p>
        Voulez-vous vraiment envoyer une notification à tous les coachs n'ayant
        pas planifié leurs séances ?
      </p>
      <div v-if="pushAll.result.display">
        <div v-for="result of pushAll.result.values" :key="result.id">
          <h4>{{ result.name }}</h4>
          <p>
            Email
            <i
              class="fa"
              :class="
                result.email ? 'fa-check text-success' : 'fa-times text-danger'
              "
            ></i>
          </p>
          <p>
            Notif
            <i
              class="fa"
              :class="
                result.notif ? 'fa-check text-success' : 'fa-times text-danger'
              "
            ></i>
          </p>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="pushAll.display = false"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="pushNotifAllCoach()"
        >
          Envoyer
        </button>
        <button
          v-if="pushAll.result.display"
          type="button"
          class="btn btn-success"
          @click="pushAll.display = false"
        >
          Valider
        </button>
      </div>
    </modal>

    <modal
      v-model="pushCoach.display"
      title="Envoyer un rappel"
      effect="fade/zoom"
      cancelText="Annuler"
      okText="Envoyer"
      large
      @ok="pushNotifCoach()"
    >
      <div v-if="pushCoach.order">
        <p>
          Voulez-vous vraiment envoyer une notification à
          <strong>{{ pushCoach.order.sessions[0].coachs[0].coach.name }}</strong
          >, concernant la commande de
          <strong> {{ this.pushCoach.order.client }} </strong>
        </p>
        <div v-if="pushCoach.result.display">
          <p>
            Email
            <i
              class="fa"
              :class="
                pushCoach.result.email
                  ? 'fa-check text-success'
                  : 'fa-times text-danger'
              "
            ></i>
          </p>
          <p>
            Notif
            <i
              class="fa"
              :class="
                pushCoach.result.notif
                  ? 'fa-check text-success'
                  : 'fa-times text-danger'
              "
            ></i>
          </p>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="pushCoach.display = false"
        >
          Annuler
        </button>
        <button type="button" class="btn btn-primary" @click="pushNotifCoach()">
          Envoyer
        </button>
        <button
          v-if="pushCoach.result.display"
          type="button"
          class="btn btn-success"
          @click="pushCoach.display = false"
        >
          Valider
        </button>
      </div>
    </modal>

    <ModalNotebook
      :show="modalNotebook.show"
      :notebook="modalNotebook.notebook"
      :client="modalNotebook.client"
      :order="modalNotebook.order"
      @close="closeNotebook"
    />

    <modal
      title="Commentaire"
      v-model="modalUpdateNoteConversion.showComment"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateNoteConversion.form.notes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Modal from "vue-strap/src/Modal";
import Sessions from "../components/Sessions";
import StatsFilters from "../components/StatsFilters";
import ConversionConversion from "../components/ConversionFidelisation";
import CommercialRel from "../components/CommercialRel";
import CoachManagerRel from "../components/CoachManagerRel";
import ModalNotebook from "../components/ModalNotebook";
import NoteModal from "../components/form/NoteModal";

export default {
  components: {
    Modal,
    ConversionConversion,
    Sessions,
    StatsFilters,
    CommercialRel,
    CoachManagerRel,
    ModalNotebook,
    NoteModal,
  },

  data() {
    return {
      loading: true,
      archived: false,
      filter: {},
      pushAll: {
        display: false,
        result: {
          display: false,
          values: [],
        },
      },
      pushCoach: {
        display: false,
        coach: null,
        result: {
          display: false,
          email: false,
          notif: false,
        },
      },
      completed: false,
      rankPending: true,
      rankConversion: true,
      rankLost: true,
      rankOther: true,
      unScheduled: false,
      unAssigned: false,
      hasSchedule: false,
      order: null,
      clientLost: {
        isLost: false,
        lostReason: null,
        detailLostReason: null,
      },
      showModal: false,
      now: moment().unix(),
      clients: {},
      commercials: [],
      coachManagers: [],
      concepts: [],
      modalNotebook: {
        show: false,
        notebook: [],
        client: {},
        order: {},
      },
      modalUpdateNoteConversion: {
        showComment: false,
        form: {
          orderId: null,
          notes: "",
        },
      },
    };
  },

  computed: {
    label() {
      return "Clients (" + this.orders.length + ")";
    },

    title() {
      if (this.order) {
        return this.order.client + " - " + this.formatPack(this.order.session);
      }
      return "";
    },

    filterCoachs() {
      let coachs = {};
      for (let email in this.clients) {
        if (Object.prototype.hasOwnProperty.call(this.clients, email)) {
          this.clients[email].forEach((order) => {
            order.sessions.forEach((session) => {
              session.coachs.forEach((coach) => {
                coachs[coach.coach.name] = true;
              });
            });
          });
        }
      }
      return Object.keys(coachs);
    },

    orders() {
      let orders = [];
      for (let email in this.clients) {
        if (
          Object.prototype.hasOwnProperty.call(this.clients, email) &&
          (!this.filter.client || email === this.filter.client.value)
        ) {
          if (
            this.filter.concept &&
            !this.clients[email].find((o) => o.concept === this.filter.concept)
          ) {
            continue;
          }
          if (
            this.filter.status &&
            !this.clients[email].find(
              (o) => o.status === this.filter.status.value
            )
          ) {
            continue;
          }

          this.clients[email].forEach((order) => {
            let coachs = {};
            let completed = 0;

            if (this.filter.concept && order.concept !== this.filter.concept) {
              return;
            }

            if (
              this.filter.status &&
              order.status !== this.filter.status.value
            ) {
              return;
            }

            if (this.archived) {
              if (!this.rankPending && order.rank === "pending") {
                return;
              }
              if (!this.rankConversion && order.rank === "conversion") {
                return;
              }
              if (!this.rankLost && order.rank === "lost") {
                return;
              }
              if (!this.rankLostOther && order.rank === "lost_other") {
                return;
              }
              if (
                this.filter.begin &&
                moment(this.filter.begin) > moment(order.archivedAt)
              ) {
                return;
              }
              if (
                this.filter.end &&
                moment(this.filter.end) < moment(order.archivedAt)
              ) {
                return;
              }
            } else if (
              this.unScheduled ||
              this.hasSchedule ||
              this.unAssigned ||
              this.completed
            ) {
              let filtered = true;

              if (this.unScheduled) {
                for (const session of order.sessions) {
                  if (session.completed) {
                    continue;
                  }

                  if (!session.scheduledAt && session.assignedAt) {
                    filtered = false;
                  }

                  break;
                }
              }

              if (this.unAssigned) {
                for (const session of order.sessions) {
                  if (session.completed) {
                    continue;
                  }

                  if (!session.scheduledAt && !session.assignedAt) {
                    filtered = false;
                  }

                  break;
                }
              }
              if (this.hasSchedule) {
                for (const session of order.sessions) {
                  if (session.completed) {
                    continue;
                  }

                  if (session.scheduledAt && session.assignedAt) {
                    filtered = false;
                  }

                  break;
                }
              }

              if (this.completed && order.completed) {
                filtered = false;
              }

              if (filtered) {
                return;
              }
              if (this.filterByDate(order)) {
                return;
              }
            } else {
              if (this.filterByDate(order)) {
                return;
              }
            }

            if (this.filter.inactive) {
              if (!order.completed || order.packs > 0) {
                return;
              }

              let diff = moment().diff(
                moment(order.completedAt || order.createdAt),
                "month"
              );
              if (
                !(this.filter.inactive.value === "4m-more" && diff >= 4) ||
                (this.filter.inactive.value === "3m" && diff === 3) ||
                (this.filter.inactive.value === "2m" && diff === 2) ||
                (this.filter.inactive.value === "1m" && diff === 1)
              ) {
                return;
              }
            }

            order.sessions.forEach((session) => {
              session.coachs.forEach((coach) => {
                coachs[coach.coach.name] = true;
              });
              if (session.completed) {
                completed++;
              }
            });

            if (this.filter.coach && !coachs[this.filter.coach]) {
              return;
            }

            orders.push({
              ...order,
              coachs: Object.keys(coachs).join(", "),
              coachsArray: Object.keys(coachs),
              pack: this.formatPack(order.session),
              price: this.formatPrice(order.price),
              orders: this.clients[email].length,
              state: completed + "/" + order.sessions.length,
              date: this.archived
                ? this.formatDate(order.archivedAt)
                : this.formatDate(order.createdAt),
              dateSort: this.archived
                ? moment(order.archivedAt).unix()
                : moment(order.createdAt).unix(),
              scheduledAt:
                order.sessions && order.sessions.length
                  ? this.formatDateTime(order.sessions[0].scheduledAt)
                  : null,
              scheduledDate:
                order.sessions &&
                order.sessions.length &&
                order.sessions[0].scheduledAt
                  ? moment(order.sessions[0].scheduledAt).unix()
                  : 0,
              notes:
                order.conversion && order.conversion.notes
                  ? order.conversion.notes.length > 60
                    ? order.conversion.notes.substr(0, 60) + "..."
                    : order.conversion.notes
                  : "",
            });
          });
        }
      }
      return orders;
    },

    isProspect() {
      return this.order?.refClient?.prospect?.isProspect;
    },

    isLost() {
      return (
        this.clientLost.isLost ||
        this.order?.rank === "lost" ||
        this.order?.rank === "lost_other"
      );
    },
  },

  async created() {
    await this.fetchCommercials();
    await this.fetchCoachManagers();
    await this.fetchConcepts();

    this.filter.commercial = this.commercials.find((c) => {
      if (this.$store.state.user.email === c.value) {
        return c;
      }
    });

    this.filter.coachManager = this.coachManagers.find((c) => {
      if (this.$store.state.user.email === c.value) {
        return c;
      }
    });

    await this.loadOrders();
  },

  methods: {
    async loadOrders() {
      let params = {};

      if (this.archived) {
        params.archived = this.archived;
      }

      if (this.filter.commercial) {
        params.commercial = this.filter.commercial.value;
      }

      if (this.filter.coachManager) {
        params.coachManager = this.filter.coachManager.value;
      }

      this.loading = true;

      const { data } = await this.$api.get("/orders/conversion", { params });

      this.clients = data;
      this.loading = false;
    },

    onSave() {
      if (this.isLost && this.order.refClient) {
        const data = {
          email: this.order.refClient.email,
        };

        if (this.isProspect) {
          data.prospect = {
            type: "perdu",
            lostReason: this.clientLost.lostReason,
            detailLostReason: this.clientLost.detailLostReason,
            lostAt: moment().toDate(),
          };
        } else {
          data.status = "lost";
          data.lostReason = this.clientLost.lostReason;
          data.detailLostReason = this.clientLost.detailLostReason;
          data.lostAt = moment().toDate();
        }

        this.$confirm({
          message:
            "Le client/prospect va changer de statut. Souhaitez-vous continuer ?",
          onConfirm: async () => {
            try {
              await this.$api.patch("/clients/status", data);

              this.save();
            } catch (e) {
              this.order = null;
              this.clientLost.isLost = false;
              this.clientLost.lostReason = null;
              this.clientLost.detailLostReason = null;
              this.showModal = false;
              this.$errorToast("Un problème est survenu");

              return;
            }
          },
        });
      } else {
        this.save();
      }
    },

    async save() {
      await this.$api.put("/orders/conversion/" + this.order.id, {
        rank: this.order.rank,
        conversion: this.order.conversion,
      });

      const updatedOrder = this.orders.find(
        (order) => order.id === this.order.id
      );

      if (this.isProspect) {
        updatedOrder.refClient.prospect.type = "perdu";
        updatedOrder.refClient.prospect.lostReason = this.clientLost.lostReason;
      } else {
        updatedOrder.refClient.status = "lost";
        updatedOrder.refClient.lostReason = this.clientLost.lostReason;
      }

      this.order = null;
      this.clientLost.isLost = false;
      this.clientLost.lostReason = null;
      this.clientLost.detailLostReason = null;
      this.showModal = false;
    },

    cancelOrderModal() {
      this.order = null;
      this.clientLost.isLost = false;
      this.clientLost.lostReason = null;
      this.clientLost.detailLostReason = null;
    },

    filterByDate(order) {
      if (
        this.filter.begin &&
        moment(this.filter.begin).startOf("day") > moment(order.createdAt)
      ) {
        return true;
      }

      return (
        this.filter.end &&
        moment(this.filter.end).endOf("day") < moment(order.createdAt)
      );
    },
    pushToCoach(order) {
      this.pushCoach.order = order;
      this.pushCoach.display = true;
      this.pushCoach.result.display = false;
    },

    async pushNotifCoach() {
      const result = await this.requestPushConversion(this.pushCoach.order.id);

      this.pushCoach.result.display = true;
      this.pushCoach.result.email = result.email;
      this.pushCoach.result.notif = result.notif;
    },

    pushAllCoachModal() {
      this.pushAll.display = true;
      this.pushAll.result.display = false;
    },

    async pushNotifAllCoach() {
      for (let order of this.orders) {
        if (!order.scheduledAt && order.coachs) {
          const result = await this.requestPushConversion(order.id);

          this.pushAll.result.display = true;
          this.pushAll.result.values.push({
            id: order.id,
            name: order.coachsArray[0],
            email: result.email,
            notif: result.notif,
          });
        }
      }
    },

    async requestPushConversion(orderId) {
      try {
        const { data } = await this.$api.patch(
          `/orders/${orderId}/conversion/push`
        );

        return data;
      } catch (e) {
        console.log(e);
      }
    },

    show(order) {
      this.order = order;

      if (
        this.order.conversion?.price &&
        typeof this.order.conversion.price === "number"
      ) {
        this.order.conversion.price = this.order.conversion.price.toFixed(2);
      }

      if (order.refClient) {
        if (order.refClient?.prospect?.isProspect) {
          this.clientLost.lostReason = order.refClient.prospect.lostReason;
          this.clientLost.detailLostReason =
            order.refClient.prospect.detailLostReason;
        } else {
          this.clientLost.lostReason = order.refClient.lostReason;
          this.clientLost.detailLostReason = order.refClient.detailLostReason;
        }
      }

      this.$nextTick(() => {
        this.showModal = true;
      });
    },

    getDelay(value) {
      if (!value) {
        return "inconnu";
      }

      const now = moment();
      const duration = moment.duration(now.diff(value));
      const hours = duration.asHours();
      const minutes = duration.asMinutes();

      return hours > 1
        ? `${Math.round(hours)} h`
        : `${Math.round(minutes)} min`;
    },

    archive(order) {
      const message =
        order.rank === "pending"
          ? 'La séance est toujours "En attente"... archiver quand même ?'
          : "Archiver la séance ?";

      this.$confirm({
        message,
        onConfirm: async () => {
          await this.$api.put(`/orders/archive/${order.id}`, {
            archivedConversion: true,
          });

          this.sliceOrder(order.id);
        },
      });
    },

    unarchive(order) {
      this.$api
        .put("/orders/archive/" + order.id, { archivedConversion: false })
        .then(() => window.location.reload(true));
    },

    sliceOrder(orderId) {
      for (let email in this.clients) {
        if (Object.prototype.hasOwnProperty.call(this.clients, email)) {
          let index = this.clients[email].findIndex(
            (order) => order.id === orderId
          );
          if (index === -1) {
            continue;
          }
          if (index === 0 && this.clients[email].length > 1) {
            this.clients[email][1].refClient = this.clients[email][0].refClient;
          }
          this.clients[email].splice(index, 1);
        }
      }
    },

    isScheduled(order) {
      for (const session of order.sessions) {
        if (session.completed) {
          continue;
        }

        return session.scheduledAt && session.assignedAt;
      }
    },

    isUnScheduled(order) {
      for (const session of order.sessions) {
        if (session.completed) {
          continue;
        }

        return !session.scheduledAt && session.assignedAt;
      }
    },

    goToOrder(e, order) {
      e.preventDefault();

      this.$router.push({
        name: "order-details",
        params: {
          id: order.id,
        },
      });
    },

    goToCoach(e, order, name) {
      e.preventDefault();

      let myCoach;

      order.sessions.forEach((session) => {
        session.coachs.forEach((coach) => {
          if (coach.coach.name === name) {
            myCoach = coach;
          }
        });
      });

      const routeData = this.$router.resolve({
        name: "user-details",
        params: {
          id: myCoach.id,
        },
      });

      window.open(routeData.href, "_blank");
    },

    goToClient(e, id) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async fetchCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((c) => ({
        value: c.email,
        label: c.coachManager.pseudo,
      }));
    },

    async fetchConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data.map((c) => c.name);
    },

    applyFilters(form) {
      this.filter = { ...form };

      this.loadOrders();
    },

    showNotebook(order) {
      this.modalNotebook.show = true;
      this.modalNotebook.notebook = order.notebook;
      this.modalNotebook.client = order.refClient;
      this.modalNotebook.order = order;
    },

    closeNotebook() {
      this.modalNotebook.show = false;
      this.modalNotebook.notebook = [];
      this.modalNotebook.client = {};
      this.modalNotebook.order = {};
    },

    concatedNotes(order) {
      return (
        order.notebook
          .filter((n) => n.orderId === order.id)
          .map((note) => `${note.title}: ${note.note}`)
          .join(" | ") || "Aucune note pour cette commande"
      );
    },

    showComment(order) {
      this.modalUpdateNoteConversion.showComment = true;
      this.modalUpdateNoteConversion.form.orderId = order.id;
      this.modalUpdateNoteConversion.form.notes = order.conversion.notes;
    },

    async updateComment() {
      await this.$api.put(
        `/orders/conversion-notes/${this.modalUpdateNoteConversion.form.orderId}`,
        {
          notes: this.modalUpdateNoteConversion.form.notes,
        }
      );

      this.modalUpdateNoteConversion = {
        showComment: false,
        form: {
          orderId: null,
          notes: "",
        },
      };

      this.loadOrders();
    },
  },

  watch: {
    archived() {
      this.$nextTick(() => this.loadOrders());
    },
  },
};
</script>

<style scoped>
.table-responsive {
  text-overflow: none;
}
.card-responsive {
  display: block;
  overflow-x: scroll;
}
</style>
