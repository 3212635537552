<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col" v-if="checkPermission">
          <button
            v-if="isProspect"
            type="button"
            class="btn btn-sm mt-0"
            :class="btnProspectColor"
            @click="showSwitchTypeModal = true"
          >
            Prospect
          </button>
          <button
            v-else
            type="button"
            class="btn btn-sm btn-info mt-0"
            @click="showSwitchTypeModal = true"
            :disabled="!checkOrderForConvertClient"
          >
            Client
          </button>
        </div>
        <div class="col" v-else>
          <span
            v-if="isProspect"
            class="btn btn-sm mt-0"
            :class="btnProspectColor"
          >
            Prospect
          </span>
          <span
            v-else
            class="btn btn-sm btn-info mt-0"
            :disabled="!checkOrderForConvertClient"
          >
            Client
          </span>
        </div>
      </div>
    </div>

    <div class="card-block">
      <dl class="row mb-0">
        <dt class="col-4">Statut</dt>
        <dd class="col-8">
          <el-select
            v-if="isProspect"
            :value="client.prospect.type"
            :disabled="checkOrderForConvertProspect"
            @change="handleStatus($event)"
            size="mini"
            :class="btnProspectColor"
            class="w-100"
            style="border-radius: 5px"
          >
            <el-option
              v-for="status in prospectStatus"
              :key="status.label"
              :value="status.value"
              :label="status.label"
              :class="colorClassByProspectStatus(status.value)"
            />
          </el-select>

          <el-select
            v-else
            :value="client.status"
            :class="colorClassByStatus(client.status)"
            size="mini"
            @change="handleStatus($event)"
            :disabled="!checkPermission"
            class="w-100"
            style="border-radius: 5px"
          >
            <el-option
              v-for="status in clientStatus"
              :key="status.label"
              :value="status.value"
              :label="status.label"
              :class="colorClassByStatus(status.value)"
            />
          </el-select>
        </dd>

        <dt v-if="isProspectLost || isClientLost" class="col-4">
          Raison de la perte
        </dt>
        <dd v-if="isProspectLost" class="col-8">
          <el-select
            :value="client.prospect?.lostReason"
            v-model="client.prospect.lostReason"
            @change="editProspectLostReason()"
            :disabled="!checkPermission"
            size="mini"
            class="w-100"
          >
            <el-option
              :value="reason.value"
              v-for="reason in globalLostReasons"
              :key="reason.value"
              :label="reason.label"
            />
          </el-select>
        </dd>

        <dd v-if="isClientLost" class="col-8">
          <el-select
            :value="client.lostReason"
            v-model="client.lostReason"
            @change="editClientLostReason()"
            :disabled="!checkPermission"
            size="mini"
            class="w-100"
          >
            <el-option
              :value="reason.value"
              v-for="reason in globalLostReasons"
              :key="reason.value"
              :label="reason.label"
            />
          </el-select>
        </dd>

        <dt
          v-if="
            withDetailsLostReason && (this.isProspectLost || this.isClientLost)
          "
          class="col-4"
        >
          Détail de la raison
        </dt>
        <dd
          v-if="
            (client.detailLostReason || detailsLostReason.length > 0) &&
            isClientLost
          "
          class="col-8"
        >
          <el-select
            :value="client.detailLostReason"
            v-model="client.detailLostReason"
            @change="editClientDetailLostReason()"
            :disabled="!checkPermission"
            size="mini"
            class="w-100"
          >
            <el-option
              v-for="reason in detailsLostReason"
              :value="reason.value"
              :key="reason.value"
              :label="reason.label"
            />
          </el-select>
        </dd>

        <dd
          v-if="
            (client.prospect?.detailLostReason ||
              detailsLostReason.length > 0) &&
            isProspectLost
          "
          class="col-8"
        >
          <el-select
            :value="client.prospect?.detailLostReason"
            v-model="client.prospect.detailLostReason"
            @change="editProspectDetailLostReason()"
            :disabled="!checkPermission"
            size="mini"
            class="w-100"
          >
            <el-option
              v-for="reason in detailsLostReason"
              :value="reason.value"
              :key="reason.value"
              :label="reason.label"
            />
          </el-select>
        </dd>

        <dt v-if="isProspectLost || isClientLost" class="col-4">Perdu.e le</dt>
        <dd v-if="isProspectLost" class="col-8">
          <datepicker
            v-model="client.prospect.lostAt"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="editProspectLostAt()"
            :disabled="disabledDates"
            v-if="checkPermission"
          />
          <span v-else>{{ formatDate(client.prospect.lostAt) }}</span>
        </dd>
        <dd v-if="isClientLost" class="col-8">
          <datepicker
            v-model="client.lostAt"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="editClientLostAt()"
            :disabled="disabledDates"
            v-if="checkPermission"
          />
          <span v-else>{{ formatDate(client.lostAt) }}</span>
        </dd>

        <dt class="col-4">Archivé</dt>
        <dd class="col-8">
          <el-switch
            v-model="client.archived"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('archived', $event)"
            :disabled="!checkPermission"
          />
        </dd>
      </dl>

      <div
        v-if="conversions.length"
        style="cursor: pointer"
        class="conversion-details-link col-12 text-right p-0 text-primary"
        @click="showConversionDetails = true"
      >
        Détails Conversion/Fidélisation
      </div>
    </div>

    <modal
      title="Prospect/Client"
      v-model="showSwitchTypeModal"
      cancelText="Fermer"
      okText="Convertir"
      @ok="editClientType()"
      effect="fade/zoom"
    >
      <h5 v-if="isProspect">
        Voulez-vous vraiment convertir un prospect en client ?
      </h5>
      <h5 v-else-if="client">
        Voulez-vous vraiment convertir un client en prospect (Converti) ?
      </h5>
    </modal>

    <modal
      title="Détails Conversion/Fidélisation"
      v-model="showConversionDetails"
      large
      okText="OK"
      cancelText="Fermer"
      @ok="showConversionDetails = false"
      effect="fade/zoom"
    >
      <div class="card" v-if="conversions.length">
        <div class="card-header">
          <i class="icon-trophy"></i> Conversions / Fidélisation
        </div>
        <div class="card-block">
          <table class="table table-condensed">
            <tr v-for="(conversion, index) in conversions" :key="index">
              <td v-if="conversion.discovery">
                <button
                  v-if="conversion.status === 'lost'"
                  class="btn btn-sm btn-danger"
                >
                  Perdu Coach
                </button>
                <button
                  v-if="conversion.status === 'lost_other'"
                  class="btn btn-sm btn-danger"
                >
                  Perdu
                </button>
                <button
                  v-else-if="conversion.status === 'conversion'"
                  class="btn btn-sm btn-success"
                >
                  Converti
                </button>
              </td>
              <td v-else>
                <button
                  v-if="conversion.status === 'lost'"
                  class="btn btn-sm btn-danger"
                >
                  Perdu Coach
                </button>
                <button
                  v-if="conversion.status === 'lost_other'"
                  class="btn btn-sm btn-danger"
                >
                  Perdu
                </button>
                <button
                  v-else-if="conversion.status === 'fidelisation'"
                  class="btn btn-sm btn-success"
                >
                  Fidélisé
                </button>
              </td>
              <td>{{ conversion.date }}</td>
              <td>{{ conversion.order }}</td>
              <td>{{ conversion.coachs }}</td>
              <td style="font-style: italic">{{ conversion.notes }}</td>
            </tr>
          </table>
        </div>
      </div>
    </modal>

    <ChangeStatusProspectOrClientModal
      :isProspect="isProspect"
      :show="showUpdateStatusModal"
      :client="client"
      :globalLostReasons="globalLostReasons"
      :disabledDates="disabledDates"
      :status="status"
      @close="closeUpdateStatusModal"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";

import ChangeStatusProspectOrClientModal from "./modal/ChangeStatusProspectOrClientModal.vue";

export default {
  components: {
    Modal,
    Datepicker,
    ChangeStatusProspectOrClientModal,
  },

  props: {
    clientData: {
      type: Object,
      required: true,
    },

    orders: {
      type: Array,
      default: () => [],
    },

    disabledDates: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      client: undefined,

      showSwitchTypeModal: false,
      showConversionDetails: false,

      showUpdateStatusModal: false,
      status: "",

      detailsLostReason: [],
    };
  },

  created() {
    this.client = this.clientData;

    if (this.client.detailLostReason) {
      this.detailsLostReason = this.getClientDetailsLostReason;
    }

    if (this.client.prospect?.detailLostReason) {
      this.detailsLostReason = this.getProspectDetailsLostReason;
    }
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
      clientStatus: "getClientStatus",
      prospectStatus: "getProspectStatus",
      companyLost: "getCompanyLost",
    }),

    globalLostReasons() {
      let globalLostReasons = this.lostReasons;

      return globalLostReasons;
    },

    getClientDetailsLostReason() {
      const detailsLostClient = this.globalLostReasons.find(
        (reason) => reason.value === this.client?.lostReason
      )?.details;

      return Array.isArray(detailsLostClient) ? detailsLostClient : [];
    },

    getProspectDetailsLostReason() {
      const detailsLostProspect = this.globalLostReasons.find(
        (reason) => reason.value === this.client.prospect.lostReason
      )?.details;

      return Array.isArray(detailsLostProspect) ? detailsLostProspect : [];
    },

    isProspect() {
      return !!this.client?.prospect?.isProspect;
    },

    isProspectLost() {
      return this.isProspect && this.client.prospect.type === "perdu";
    },

    withDetailsLostReason() {
      return (
        !!this.client.detailLostReason ||
        !!this.client.prospect?.detailLostReason ||
        this.detailsLostReason.length > 0
      );
    },

    isClientLost() {
      return this.client && !this.isProspect && this.client.status === "lost";
    },

    btnProspectColor() {
      if (this.client && this.client.prospect && this.client.prospect.type) {
        switch (this.client.prospect.type) {
          case "converti":
            return "active-color";
          case "treated":
            return "treated-color";
          case "en-attente":
            return "inactive-color";
          case "perdu":
            return "suspended-color";
        }
      }

      return "btn-info";
    },

    checkOrderForConvertClient() {
      if (this.client) {
        if (!this.client.payments || this.client.payments.length === 0) {
          return true;
        }

        const payments = this.client.payments.filter(
          (p) => !p.order?.discovery
        );

        return payments.length === 0;
      }

      return false;
    },

    checkOrderForConvertProspect() {
      if (!this.hasPermission(this.$store.state.user, "CLIENTS_WRITE")) {
        return true;
      }

      if (this.client.payments.length === 0) {
        return false;
      }

      const payments = this.client.payments.filter((p) => {
        return !p.order?.discovery;
      });

      return payments.length === 0;
    },

    conversions() {
      const conversions = [];

      this.orders.forEach((order) => {
        if (order.rank !== "pending") {
          conversions.push({
            order: this.formatPack(order.session),
            discovery: order.discovery,
            status: order.rank,
            notes: order.fidelisation?.notes,
            date: this.formatDate(order.createdAt),
            coachs: order.rankCoachs.map((c) => c.coach.name).join(", "),
          });
        }
      });

      return conversions;
    },

    checkPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.client.deletedAt
      );
    },
  },

  methods: {
    editClient(name, value) {
      this.$emit("edit", { name, value });
    },

    colorClassByStatus(status) {
      return status + "-color";
    },

    colorClassByProspectStatus(status) {
      switch (status) {
        case "converti":
          return "active-color";
        case "treated":
          return "treated-color";
        case "en-attente":
          return "inactive-color";
        case "perdu":
          return "suspended-color";
      }
    },

    async editClientType() {
      let data = {
        email: this.client.email,
        prospect: { isProspect: false },
        convertToClientAt: moment(),
      };

      if (this.client.prospect && !this.client.prospect.isProspect) {
        data.prospect = {
          isProspect: true,
          type: "converti",
        };
      }

      await this.$api.put("/clients", data);

      this.showSwitchTypeModal = false;
      this.$emit("reload");
    },

    handleStatus(event) {
      this.status = event;

      this.showUpdateStatusModal = true;
    },

    closeUpdateStatusModal(clientUpdate) {
      this.showUpdateStatusModal = false;

      this.client = Object.assign(this.client, clientUpdate);

      if (this.client.lostReason) {
        this.detailsLostReason = this.getClientDetailsLostReason;
      }
    },

    async editProspectLostReason() {
      const { data } = await this.$api.patch("/clients/status", {
        email: this.client.email,
        prospect: {
          type: this.client.prospect.type,
          lostReason: this.client.prospect.lostReason,
          detailLostReason: null,
        },
      });

      this.detailsLostReason = this.getProspectDetailsLostReason;

      this.client.prospect.lostReason = data.prospect.lostReason;
      this.client.prospect.detailLostReason = data.prospect.detailLostReason;
    },

    async editProspectDetailLostReason() {
      const { data } = await this.$api.patch("/clients/status", {
        email: this.client.email,
        prospect: {
          type: this.client.prospect.type,
          lostReason: this.client.prospect.lostReason,
          detailLostReason: this.client.prospect.detailLostReason,
        },
      });

      this.detailsLostReason = this.getProspectDetailsLostReason;

      this.client.prospect.detailLostReason = data.prospect.detailLostReason;
    },

    async editClientLostReason() {
      const { data } = await this.$api.patch("/clients/status", {
        email: this.client.email,
        status: this.client.status,
        lostReason: this.client.lostReason,
        detailLostReason: null,
      });

      this.detailsLostReason = this.getClientDetailsLostReason;

      this.client.detailLostReason = data.detailLostReason;
      this.client.lostReason = data.lostReason;
    },

    async editClientDetailLostReason() {
      const { data } = await this.$api.patch("/clients/status", {
        email: this.client.email,
        status: this.client.status,
        lostReason: this.client.lostReason,
        detailLostReason: this.client.detailLostReason,
      });

      this.detailsLostReason = this.getClientDetailsLostReason;

      this.client.detailLostReason = data.detailLostReason;
    },

    async editProspectLostAt() {
      const data = {
        email: this.client.email,
        prospect: {
          lostAt: this.client.prospect.lostAt,
        },
      };

      await this.$api.put("/clients", data);
    },

    async editClientLostAt() {
      const data = {
        email: this.client.email,
        lostAt: this.client.lostAt,
      };

      await this.$api.put("/clients", data);
    },
  },
};
</script>

<style lang="scss">
.active-color {
  color: #fff;
  background-color: #70ad47;
  input {
    color: #fff;
    background-color: #70ad47;
  }
  &.el-select-dropdown__item {
    color: #fff;
    background-color: #70ad47;
  }
}

.inactive-color {
  color: #fff;
  background-color: #ec7c31;
  input {
    color: #fff;
    background-color: #ec7c31;
  }
  &.el-select-dropdown__item {
    color: #fff;
    background-color: #ec7c31;
  }
}

.lost-color {
  color: #fff;
  background-color: #525252;
  input {
    color: #fff;
    background-color: #525252;
  }
  &.el-select-dropdown__item {
    color: #fff;
    background-color: #525252;
  }
}

.treated-color {
  color: #fff;
  background-color: #67c2ef;
  input {
    color: #fff;
    background-color: #67c2ef;
  }
  &.el-select-dropdown__item {
    color: #fff;
    background-color: #67c2ef;
  }
}

.suspended-color {
  color: #fff;
  background-color: #fe0100;
  input {
    color: #fff;
    background-color: #fe0100;
  }
  &.el-select-dropdown__item {
    color: #fff;
    background-color: #fe0100;
  }
}

.conversion-details-link {
  &:hover {
    text-decoration: underline;
  }
}
</style>
