<template>
  <div>
    <DisbursementGroupAction
      v-if="disbursements.length > 0 && selectedList.length > 1"
      :payed="payed"
      :processed="processed"
      :blocked="blocked"
      :selected="selectedList"
      @manual-pay="(d) => manualPay(d, false)"
      @process="(d) => processDisbursement(d, false)"
      @unprocess="(d) => unprocessDisbursement(d, false)"
      @unpay="(d) => unpayDisbursement(d, false)"
      @archive="(d) => archive(d, false)"
      @iban="(user) => sendIbanEmail(user, false)"
    />

    <el-table
      class="disbursement-table"
      :default-sort="{ prop: 'createdAt', order: 'descending' }"
      @selection-change="handleSelectionChange"
      :data="disbursements"
      :row-class-name="isBlockedRefundCoach"
    >
      <el-table-column type="index" fixed>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}/{{ disbursements.length }}
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        :selectable="isSelectable"
        fixed
        width="55"
      />
      <el-table-column sortable label="Date" width="120" prop="createdAt">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        sortable
        fixed
        label="Nom"
        prop="coach.coach.name"
      >
        <template slot-scope="scope">
          <DisplayCoachName :coach="scope.row.coach" />
        </template>
      </el-table-column>
      <el-table-column label="Société" width="90" prop="merchant.name">
        <template slot-scope="scope">
          {{ scope.row.merchant.name.replace("Just Coaching", "") }}
        </template>
      </el-table-column>
      <el-table-column label="Description" width="240" prop="description">
        <template slot-scope="scope">
          <button
            class="btn btn-link btn-sm"
            v-if="!scope.row.sessions && !scope.row.promoCodes"
            @click="getSessions(scope.row.id)"
          >
            Voir les détails
          </button>
          <div
            v-for="(sessions, clientId) in getClientSessions(scope.row)"
            :key="clientId"
            v-else-if="scope.row.sessions"
          >
            <template v-if="sessions[0].client && sessions[0].client.company"
              ><u>Société</u> : {{ sessions[0].client.company }}</template
            >
            <template v-else-if="sessions[0].client"
              ><u>Client</u> : {{ sessions[0].client.name }}</template
            >
            <template v-else
              ><u>Client</u> :
              <span class="text-danger">n'existe plus</span></template
            >
            <br />
            <ul>
              <li v-for="(session, index) in sessions" :key="index">
                Séance : {{ session.counter }}/{{ session.order.session }}<br />
                <template v-if="session.packs"
                  >Pack:
                  <i
                    class="fa fa-cart-plus"
                    v-bind:class="{
                      'text-success': session.packsCaptured > 0,
                    }" />
                  {{ session.packsCaptured }}/{{ session.packs }}<br
                /></template>
                Planifiée le : {{ formatDate(session.scheduledAt) }}<br />
                <span v-if="session.credit">
                  Tarif : {{ formatPrice(session.credit) }} </span
                ><br />
                <span
                  v-if="hasAmountExceedSessionsAlert(scope.row, session.id)"
                >
                  <i class="fa fa-warning text-warning"></i>
                  Alert : Montant dépassé </span
                ><br />
                <span v-if="hasMultipleSessionsAlert(scope.row, session.id)">
                  <i class="fa fa-warning text-danger"></i>
                  Alert : Plusieurs sessions le meme jour avec le meme client </span
                ><br />
                <span v-if="hasSameTimeSessionsAlert(scope.row, session.id)">
                  <i class="fa fa-warning text-warning"></i>
                  Alert : Session en même temps
                </span>
              </li>
            </ul>
          </div>
          <div v-else-if="scope.row.promoCodes">
            <b>Code(s) promo : </b>
            <ul>
              <span
                v-for="(code, index) in disbursement.promoCodes"
                :key="index"
              >
                <li v-if="code.commission">
                  {{ code.code }}
                </li>
              </span>
            </ul>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="200" label="Anomalies">
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.alert &&
              scope.row.alert.multipleSessions &&
              scope.row.alert.multipleSessions.length > 0
            "
          >
            <i class="fa fa-warning text-danger"></i> Horaires identiques
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content: displayInfoAlert(scope.row.alert.multipleSessions),
                placement: 'right',
              }"
            ></span>
          </div>
          <div
            v-if="
              scope.row.alert &&
              scope.row.alert.sameTimeSessions &&
              scope.row.alert.sameTimeSessions.length > 0
            "
          >
            <i class="fa fa-warning text-warning"></i> Clients identiques
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content: displayInfoAlert(scope.row.alert.sameTimeSessions),
                placement: 'right',
              }"
            ></span>
          </div>
          <div
            v-if="
              scope.row.alert &&
              scope.row.alert.amountExceedSessions &&
              scope.row.alert.amountExceedSessions.length > 0
            "
          >
            <i class="fa fa-warning text-warning"></i> Montant dépassé
            <span
              class="fa fa-info-circle ml-05"
              v-tooltip="{
                content: displayInfoAlert(scope.row.alert.amountExceedSessions),
                placement: 'right',
              }"
            ></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable width="140" label="Montant HT" prop="credit">
        <template slot-scope="scope">
          {{ formatPrice(scope.row.credit) }}
        </template>
      </el-table-column>
      <el-table-column sortable width="140" label="Montant TTC" prop="credit">
        <template slot-scope="scope">
          {{ hasTva(scope.row) ? formatPrice(scope.row.credit * 1.2) : "-" }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="processed"
        sortable
        width="160"
        label="Traité le"
        prop="processedAt"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.processedAt) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="payed"
        width="160"
        sortable
        label="Payé le"
        prop="completedAt"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.completedAt) }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="blocked"
        width="160"
        sortable
        label="Bloqué le"
        prop="blockedAt"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.blockedAt) }}
        </template>
      </el-table-column>

      <el-table-column label="IBAN">
        <template slot-scope="scope">
          <span v-if="hasIban(scope.row.coach)"
            ><i class="fa fa-check text-success"></i
          ></span>

          <span v-else>
            <button
              v-tooltip="{
                content: 'Envoyer un email de rappel à ce sujet ?',
                placement: 'bottom',
              }"
              class="btn btn-primary btn-sm"
              @click="sendIbanEmail(scope.row.coach)"
            >
              <i class="fa fa-envelope"></i>
            </button>
          </span>
        </template>
      </el-table-column>

      <el-table-column :min-width="250" label="Notes">
        <template slot-scope="scope">
          <NoteModal
            :notes="scope.row.note"
            :key="scope.row.id"
            permission="DISBURSEMENTS_WRITE"
            @showComment="showComment(scope.row)"
            v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
          />
          <span v-else>{{ scope.row.note }}</span>
        </template>
      </el-table-column>

      <el-table-column :min-width="250" label="Actions">
        <template slot-scope="scope">
          <div v-if="scope.row.archived" class="text-muted">
            {{
              scope.row.archivedAt
                ? "Archivée le " + formatDateTime(scope.row.archivedAt)
                : "Archivée"
            }}
          </div>
          <div v-else-if="processed">
            <button
              class="btn btn-success btn-sm"
              @click="manualPay(scope.row.id)"
              v-if="
                !coachHasRefundBlocked(scope.row) &&
                hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')
              "
            >
              <i class="fa fa-check-circle"></i> Marquer comme payée
            </button>
            <button
              class="btn btn-warning btn-sm"
              @click="unprocessDisbursement(scope.row.id)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-check-circle"></i> Passer "En attente"
            </button>
          </div>
          <div v-else-if="payed">
            <button
              class="btn btn-warning btn-sm"
              @click="unprocessDisbursement(scope.row.id)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-check-circle"></i> Passer "En attente"
            </button>
            <button
              class="btn btn-outline-danger btn-sm"
              @click="unpayDisbursement(scope.row.id)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-exclamation-triangle"></i> Annuler les paiements
            </button>
            <button
              class="btn btn-info btn-sm"
              @click="archive(scope.row.id)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-check-circle"></i> Archiver la facture
            </button>
          </div>
          <div v-else-if="blocked">
            <button
              class="btn btn-warning btn-sm"
              @click="unprocessDisbursement(scope.row.id)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-check-circle"></i> Passer "En attente"</button
            ><br />
            <button
              class="btn btn-info btn-sm"
              @click="archive(scope.row.id)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-check-circle"></i> Archiver la facture
            </button>
          </div>
          <div v-else-if="scope.row.coach.coach.blockedRefund">
            <span class="btn btn-danger btn-sm"> Blocage facture </span>
          </div>
          <div v-else>
            <button
              class="btn btn-success btn-sm"
              @click="processDisbursement(scope.row.id)"
              v-if="
                !coachHasRefundBlocked(scope.row) &&
                hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')
              "
            >
              <i class="fa fa-check-circle"></i> Facture traitée
            </button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Coach manager"
        width="170"
        sortable
        prop="coach.refCoachManager.coachManager.pseudo"
      >
        <template slot-scope="scope">
          <CoachManagerRel
            v-if="scope.row.coach.refCoachManager"
            :relation="scope.row.coach.refCoachManager.coachManager"
          />
        </template>
      </el-table-column>

      <el-table-column width="170" label="Télécharger">
        <template slot-scope="scope">
          <button
            @click="
              downloadSecuredFile(`uploads/invoice/${scope.row.invoice}.pdf`)
            "
            class="btn btn-sm btn-info"
            v-if="scope.row.invoice"
          >
            <i class="fa fa-download"></i>
          </button>
        </template>
      </el-table-column>
      <el-table-column :min-width="170" v-if="blocked === false">
        <template slot="header">
          Bloquer
          <el-popover trigger="hover" placement="top" :width="400">
            Bloquer la facture, permet de suspendre le traitements de la
            facture, le prestataire est prévenu, il ne peut pas en renvoyer une
            nouvelle. La facture est retrouvable dans la section "Bloquées".
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <div v-if="!scope.row.archived">
            <button
              class="btn btn-outline-danger btn-sm"
              @click="block(scope.row)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-exclamation-triangle"></i> Bloquer la facture
            </button>
          </div>
        </template>
      </el-table-column>
      <el-table-column :min-width="170">
        <template slot="header">
          Refuser
          <el-popover trigger="hover" placement="top" :width="400">
            Refuser la facture, permet de stopper le traitements de la facture,
            le prestataire est prévenu, il peut en renvoyer une nouvelle. La
            facture est archivé et n'est plus récupérable.
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <div v-if="!scope.row.archived">
            <button
              class="btn btn-outline-danger btn-sm"
              @click="reject(scope.row)"
              v-if="hasPermission($store.state.user, 'DISBURSEMENTS_WRITE')"
            >
              <i class="fa fa-exclamation-triangle"></i> Refuser la facture
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <modal
      title="Bloquer la facture"
      v-model="blockModal"
      cancelText="Annuler"
      okText="Bloquer"
      @ok="handleBlock(blockId)"
      effect="fade/zoom"
      large
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Raison du bloquage de la facture</h4>
      </div>
      <textarea class="form-control" v-model="blockMessage"></textarea>
      <h5 class="mt-2">Note interne</h5>
      <textarea class="form-control" v-model="blockNote"></textarea>
    </modal>
    <modal
      title="Rejeter la facture"
      v-model="rejectModal"
      cancelText="Annuler"
      okText="Rejeter"
      @ok="handleReject(rejectId)"
      effect="fade/zoom"
      large
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Raison du rejet de la facture</h4>
      </div>
      <textarea class="form-control" v-model="rejectMessage"></textarea>
      <h5 class="mt-2">Note interne</h5>
      <textarea class="form-control" v-model="rejectNote"></textarea>
    </modal>
    <modal
      v-if="modalUpdateComment.disbursment"
      title="Commentaire"
      v-model="modalUpdateComment.show"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.disbursment.note"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Modal from "vue-strap/src/Modal";
import NoteModal from "./form/NoteModal";

import DisplayCoachName from "./coach/displayCoachName";
import DisbursementGroupAction from "./DisbursementGroupAction";
import DownloadFile from "../mixin/DownloadFile";
import CoachManagerRel from "./CoachManagerRel";

export default {
  components: {
    Modal,
    DisplayCoachName,
    DisbursementGroupAction,
    NoteModal,
    CoachManagerRel,
  },
  mixins: [DownloadFile],
  props: {
    processed: {
      type: Boolean,
      default: false,
    },
    payed: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      disbursements: this.data,
      selectedList: [],
      blockModal: false,
      blockId: "",
      blockMessage: "",
      blockNote: "",
      rejectId: "",
      rejectModal: false,
      rejectMessage: "",
      rejectNote: "",
      modalUpdateComment: {
        show: false,
        disbursment: null,
      },
    };
  },
  methods: {
    handleSelectionChange(selected) {
      this.selectedList = selected;
    },

    goToCoach(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },

    getClientSessions(disbursement) {
      let clients = {};

      disbursement.sessions.forEach((session) => {
        clients[session.clientId] = clients[session.clientId] || [];
        clients[session.clientId].push(session);
      });

      for (let clientId in clients) {
        clients[clientId] = clients[clientId].sort(
          (a, b) => a.counter - b.counter
        );
      }

      return clients;
    },

    displayInfoAlert(arr) {
      return `${arr
        .map((s) => this.formatDateTime(s.scheduledAt))
        .join(" || ")}`;
    },

    async getSessions(disbursementId) {
      const { data } = await this.$api.get("/disbursements/" + disbursementId);

      const disbursement = this.disbursements.find(
        (d) => d.id === disbursementId
      );

      let sessions = data.sessions;

      const getPacks = async (orderId) => {
        return this.$api.get("/orders/packs/" + orderId);
      };

      const getAllPacks = async () => {
        return Promise.all(
          sessions.map((session) => getPacks(session.orderId))
        );
      };

      getAllPacks().then((data) => {
        data.forEach((res) => {
          let sessionIndex = sessions.findIndex(
            (session) => session.orderId === res.data.orderId
          );

          sessions[sessionIndex].packs = res.data.packs;
          sessions[sessionIndex].packsCaptured = res.data.packsCaptured;
        });
      });

      this.$set(disbursement, "sessions", sessions);
    },

    coachHasRefundBlocked(disbursement) {
      return (
        disbursement?.coach?.coach?.blockedRefund ||
        !disbursement?.coach?.coach?.iban
      );
    },

    hasTva(user) {
      return !!user.isTva;
    },

    hasIban(user) {
      return !!user?.coach?.iban;
    },

    async sendIbanEmail(user, ask = true) {
      const { email } = user;

      if (user && !this.hasIban(user)) {
        if (ask) {
          this.$confirm({
            message: "Envoyer un email de demande d'informations bancaires ?",
            onConfirm: async () => {
              try {
                this.$api.post(`/users/send-iban-email`, {
                  email,
                });
              } catch (e) {
                console.warn(e);
              }
            },
          });
        } else {
          try {
            this.$api.post(`/users/send-iban-email`, {
              email,
            });
          } catch (e) {
            console.warn(e);
          }
        }
      }
    },

    async pay(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message:
            "Déclencher un paiement via stripe et marquer la facture comme payée ?",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              stripe: true,
              completed: true,
              completedAt: moment().format(),
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          completed: true,
          completedAt: moment().format(),
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    async manualPay(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: "Marquer la facture comme payée ?",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              completed: true,
              completedAt: moment().format(),
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          completed: true,
          completedAt: moment().format(),
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    async processDisbursement(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: "Marquer la facture comme traitée ?",
          onConfirm: async () => {
            await this.$api.patch(
              `/disbursements/${disbursementId}/processed`,
              {
                processed: 1,
              }
            );

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.patch(`/disbursements/${disbursementId}/processed`, {
          processed: 1,
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    async unprocessDisbursement(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: `Rebasculer la facture dans la section "En attente" ?`,
          onConfirm: async () => {
            await this.$api.patch(
              `/disbursements/${disbursementId}/processed`,
              {
                processed: 0,
              }
            );

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.patch(`/disbursements/${disbursementId}/processed`, {
          processed: 0,
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    async unpayDisbursement(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message: "Annuler le paiement ?",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              completed: false,
              completedAt: null,
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          completed: false,
          completedAt: null,
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    block(disbursement) {
      this.blockId = disbursement.id;
      this.blockModal = true;
      this.blockMessage = null;
      this.blockNote = disbursement.note;
    },

    handleBlock() {
      this.$confirm({
        message:
          "Bloquer la facture ? Le prestataire devra être contacté pour régulariser la situation.",
        onConfirm: async () => {
          await this.$api.delete(`/disbursements/${this.blockId}/blocked`, {
            data: {
              message: this.blockMessage || "",
              note: this.blockNote || "",
            },
          });

          this.updateData([this.blockId]);

          this.blockId = null;
          this.blockModal = false;
          this.blockMessage = null;
          this.blockNote = null;
        },
      });
    },

    reject(disbursement) {
      this.rejectId = disbursement.id;
      this.rejectModal = true;
      this.rejectMessage = null;
      this.rejectNote = disbursement.note;
    },

    handleReject() {
      this.$confirm({
        message:
          "Rejeter la facture ? Le prestataire devra renvoyer une facture.",
        onConfirm: async () => {
          await this.$api.delete(`/disbursements/${this.rejectId}/reject`, {
            data: {
              message: this.rejectMessage || "",
              note: this.rejectNote || "",
            },
          });

          this.updateData([this.rejectId]);

          this.rejectId = null;
          this.rejectModal = false;
          this.rejectMessage = null;
          this.rejectNote = null;
        },
      });
    },

    async archive(disbursementId, ask = true, reload = true) {
      if (ask) {
        this.$confirm({
          message:
            "Marquer la facture comme archivé ? Cette action est irréversible.",
          onConfirm: async () => {
            await this.$api.put(`/disbursements/${disbursementId}`, {
              archived: true,
              archivedAt: moment().format(),
            });

            if (reload) {
              this.updateData([disbursementId]);
            }
          },
        });
      } else {
        await this.$api.put(`/disbursements/${disbursementId}`, {
          archived: true,
          archivedAt: moment().format(),
        });

        if (reload) {
          this.updateData([disbursementId]);
        }
      }
    },

    updateData(selected) {
      this.disbursements = this.disbursements.filter(
        (d) => !selected.includes(d.id)
      );

      this.selectedList = [];
    },

    showComment(disbursment) {
      this.modalUpdateComment.show = true;
      this.modalUpdateComment.disbursment = disbursment;
    },

    async updateComment() {
      if (!this.modalUpdateComment.disbursment) {
        return;
      }

      await this.$api.put(
        `/disbursements/${this.modalUpdateComment.disbursment.id}`,
        {
          note: this.modalUpdateComment.disbursment.note,
        }
      );

      this.modalUpdateComment.show = false;
    },

    hasAmountExceedSessionsAlert(disbursement, sessionId) {
      return (
        disbursement.alert &&
        disbursement.alert.amountExceedSessions &&
        disbursement.alert.amountExceedSessions.find((s) => s.id === sessionId)
      );
    },

    hasMultipleSessionsAlert(disbursement, sessionId) {
      return (
        disbursement.alert &&
        disbursement.alert.multipleSessions &&
        disbursement.alert.multipleSessions.find((s) => s.id === sessionId)
      );
    },

    hasSameTimeSessionsAlert(disbursement, sessionId) {
      return (
        disbursement.alert &&
        disbursement.alert.sameTimeSessions &&
        disbursement.alert.sameTimeSessions.find((s) => s.id === sessionId)
      );
    },

    isBlockedRefundCoach({ row }) {
      if (row.coach.coach.blockedRefund) {
        return "blockedRefundCoach";
      }
      return "";
    },

    isSelectable(row) {
      return !row.coach.coach.blockedRefund;
    },
  },
};
</script>

<style>
.el-table .disbursement-table td {
  vertical-align: top !important;
}

.el-table .blockedRefundCoach {
  background-color: rgba(255, 0, 0, 0.147);
}
</style>
