<template>
  <div class="card">
    <div class="card-header d-flex align-items-center">
      <i class="icon-list" style="margin-right: 0.5rem"></i> Contenus dynamiques
      <span v-if="total" class="ml-05">({{ total }})</span>
    </div>

    <div class="card-block">
      <div v-if="data.length > 0">
        <DynamicContentTable
          :data="data"
          :types="types"
          :current-type="currentType"
          @updated="updatedContent"
          @deleted="deletedContent"
        />

        <paginate
          v-if="count"
          v-model="currentPage"
          ref="paginate"
          :page-count="count"
          :click-handler="paginationHandler"
          prev-text="Précédent"
          next-text="Suivant"
          container-class="pagination"
          class="mt-1"
        ></paginate>
      </div>

      <div v-else>
        <p v-if="currentType" class="text-center">
          Aucun contenu de type {{ currentType }}
        </p>
        <p v-else class="text-center">
          Veuillez sélectionner un type de contenu.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicContentTable from "../components/DynamicContentTable";
import Paginate from "vuejs-paginate";

export default {
  components: {
    DynamicContentTable,
    Paginate,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    types: {
      type: Array,
      default: () => [],
    },

    currentType: {
      type: String,
      default: undefined,
    },

    total: {
      type: Number,
      default: 0,
    },

    page: {
      type: Number,
      default: 1,
    },

    rows: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      currentPage: 1,
      showAddContentModal: false,
    };
  },

  computed: {
    count() {
      return Math.ceil(this.total / this.rows);
    },
  },

  watch: {
    page() {
      if (this.page - 1 !== this.$refs.paginate.selected) {
        this.currentPage = this.page;
        this.$refs.paginate.selected = this.page - 1;
      }
    },
  },

  mounted() {
    this.currentPage = this.page;
  },

  methods: {
    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    updatedContent(content) {
      this.$emit("updated", content);
    },

    deletedContent(contentId) {
      this.$emit("deleted", contentId);
    },
  },
};
</script>
