<template>
  <div class="wrapper">
    <Loader :is-visible="loading" />

    <div class="animated fadeIn">
      <EventsList
        :target="target"
        :data="events"
        :total="total"
        :page="page"
        :rows="limit"
        @added="eventAdded"
        @updated="eventUpdated"
        @deleted="eventDeleted"
        @changePage="changePage"
        @sorted="sortChange"
        @refresh="refreshData"
      />
    </div>
  </div>
</template>

<script>
import EventsList from "../components/EventsList";
import Loader from "../components/Loader";

export default {
  components: {
    Loader,
    EventsList,
  },

  data() {
    return {
      loading: false,
      events: [],
      count: true,
      total: 0,
      offset: 0,
      limit: 15,
      page: 1,
      order: "",
      prop: "",
      target: "CLIENT",
    };
  },

  mounted() {
    this.fetchEvents();
  },

  methods: {
    async fetchEvents() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/events", {
          params: {
            offset: this.offset,
            limit: this.limit,
            count: this.count,
            currentTotal: this.total,
            order: this.order,
            prop: this.prop,
            target: this.target,
          },
        });

        this.events = data.data;
        this.count = false;
        this.total = data.total;
      } catch (e) {
        this.events = [];
      } finally {
        this.loading = false;
      }
    },

    eventAdded() {
      this.count = true;

      this.fetchEvents();
    },

    eventUpdated(eventObj) {
      const events = [...this.events];
      const index = events.findIndex(({ id }) => id === eventObj.id);

      if (index !== -1) {
        events[index] = { ...this.events[index], ...eventObj };

        this.events = events;
      }
    },

    eventDeleted(eventId) {
      this.events = this.events.filter(({ id }) => id !== eventId);
      this.total--;
      this.count = true;
    },

    changePage(page) {
      this.page = page;
      this.offset = page * this.limit - this.limit;

      this.fetchEvents();
    },

    sortChange({ order, prop }) {
      this.order = order;
      this.prop = prop;

      this.fetchEvents();
    },

    refreshData() {
      this.fetchEvents();
    },
  },
};
</script>
