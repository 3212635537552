<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>

    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-3" v-if="period">
          <label>Début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group col-sm-3" v-if="period">
          <label>Fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group col-sm-3">
          <label>Prestataire</label>
          <multiselect
            v-model="form.coachs"
            :options="coachs"
            label="label"
            track-by="label"
            :multiple="true"
            :searchable="true"
            :loading="coachIsLoading"
            :options-limit="10"
            :max-height="600"
            :hide-selected="true"
            @search-change="searchCoach"
          >
            <template v-slot:noOptions>Rechercher un prestataire</template>
            <template v-slot:noResult>Aucun prestataire trouvé</template>
          </multiselect>
        </div>

        <div class="form-group col-sm-3">
          <label>Coach manager</label>
          <multiselect
            placeholder="Choisir un ou des coach managers"
            v-model="form.coachManagers"
            :options="coachManagers"
            label="label"
            track-by="label"
            class="custom-multiselect"
            :multiple="true"
          >
          </multiselect>
        </div>
        <div class="form-group col-sm-3">
          <label>Société</label>
          <v-select
            :options="merchantOptions"
            v-model="form.merchant"
          ></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Filtrer par anomalies</label>
          <v-select
            :options="anomaliesOptions"
            v-model="form.anomalie"
          ></v-select>
        </div>
        <div v-if="archives" class="form-group col-sm-3 d-flex flex-column">
          <label class="mb-1">Inclure les archives</label>
          <el-switch
            v-model="form.includeArchives"
            active-text="Oui"
            inactive-text="Non"
          />
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button type="button" class="btn btn-sm btn-secondary" @click="load()">
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
      <button
        v-if="exportHistory"
        type="button"
        class="btn btn-sm btn-primary ml-05"
        @click="showExportHistory = true"
      >
        <i class="fa fa-download"></i>&nbsp; Exporter l'historique
      </button>
    </div>

    <DisbursementsExportModal
      v-if="exportHistory"
      :show="showExportHistory"
      @close="showExportHistory = false"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import DisbursementsExportModal from "./DisbursementsExportModal";

export default {
  components: {
    vSelect,
    Datepicker,
    Multiselect,
    DisbursementsExportModal,
  },

  props: {
    period: {
      type: Boolean,
      default: false,
    },

    default: {
      type: Object,
      default: null,
    },

    archives: {
      type: Boolean,
      default: false,
    },

    exportHistory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        coachs: null,
        merchant: null,
        begin: null,
        end: null,
        coachManagers: [],
        anomalie: null,
        includeArchives: false,
      },
      merchantOptions: [],
      coachIsLoading: false,
      coachs: [],
      coachManagers: [],
      anomaliesOptions: [
        { label: "Tout", value: "ALL" },
        { label: "Horaires identiques", value: "TIME_TROUBLES" },
        { label: "Clients identiques", value: "CLIENT_TROUBLES" },
        { label: "Montant dépassé", value: "AMOUNT_TROUBLES" },
      ],
      showExportHistory: false,
    };
  },

  async created() {
    this.getMerchants();

    await this.fetchCoachManagers();

    if (this.default) {
      this.form = this.default;
    }

    if (
      this.$store.state.user.coachManager &&
      !this.form.coachManagers.some(
        ({ value }) => value === this.$store.state.user.email
      )
    ) {
      const currentCoachManager = this.coachManagers.find(
        ({ value }) => value === this.$store.state.user.email
      );

      if (currentCoachManager) {
        this.form.coachManagers.push(currentCoachManager);
      }
    }
  },

  methods: {
    load() {
      this.$emit("load", this.form);
    },

    async getMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    async searchCoach(query) {
      if (!query || query.length < 3) {
        return;
      }

      this.coachIsLoading = true;

      const { data } = await this.$api.get(
        `/users/filters?q=${query.split(" ")}`
      );

      this.coachs = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
        usedEmail: c.usedEmail,
      }));

      this.coachIsLoading = false;
    },

    clearAll() {
      this.selectedCountries = [];
    },

    async fetchCoachManagers() {
      const { data } = await this.$api.get("/users/coach-managers");

      this.coachManagers = data.map((c) => ({
        value: c.email,
        label: c.coachManager.pseudo,
      }));
    },
  },
};
</script>
