<template>
  <modal
    :title="modalTitle"
    :value="show"
    cancelText="Annuler"
    :okText="`${editing ? 'Mettre à jour' : 'Ajouter'}`"
    @ok="editing ? editEvent() : addEvent()"
    @cancel="closeModal"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Type *</label>
          <input
            type="text"
            class="form-control"
            v-model="type"
            placeholder="EVENT_TYPE"
          />
          <small v-if="errors && errors.type" class="form-text text-danger">
            {{ errors.type }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Titre *</label>
          <input type="text" class="form-control" v-model="title" />
          <small v-if="errors && errors.title" class="form-text text-danger">
            {{ errors.title }}</small
          >
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label>Message *</label>
          <textarea class="form-control" v-model="notes" />
          <small v-if="errors && errors.notes" class="form-text text-danger">
            {{ errors.notes }}</small
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    target: {
      type: String,
      required: true,
      validator: (value) => ["COACH", "CLIENT"].includes(value),
    },

    editedEvent: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      type: this.target === "COACH" ? "COACH_EVENTS" : "",
      title: "",
      notes: "",
      sendDate: new Date(),
      errors: {},
    };
  },

  computed: {
    editing() {
      return !!this.editedEvent?.id;
    },

    modalTitle() {
      let title = this.editing ? "Modifier" : "Ajouter";

      switch (this.target) {
        case "COACH":
          title += " une notification coach";
          break;
        case "CLIENT":
          title += " une notification client";
          break;
      }

      return title;
    },
  },

  watch: {
    editedEvent() {
      if (this.editing) {
        this.type = this.editedEvent.type;
        this.title = this.editedEvent.title;
        this.notes = this.editedEvent.notes;
      } else {
        this.resetData();
      }
    },
  },

  methods: {
    resetData() {
      this.type = this.target === "COACH" ? "COACH_EVENTS" : "";
      this.title = "";
      this.notes = "";
      this.errors = {};
    },

    closeModal() {
      this.$emit("close");

      this.resetData();
    },

    checkFields() {
      this.errors = {};

      if (!this.type) {
        this.errors.type = "Veuillez renseigner un type";
      } else if (this.type.match(/\s/)) {
        this.errors.type = "Le type ne doit pas contenir d'espaces";
      }

      if (!this.title) {
        this.errors.title = "Veuillez saisir un nom";
      }

      if (!this.notes) {
        this.errors.notes = "Veuillez saisir un contenu";
      }
    },

    async addEvent() {
      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        await this.$api.post("/events", {
          target: this.target,
          type: this.type.toUpperCase(),
          title: this.title,
          notes: this.notes,
        });

        this.$emit("added");
        this.closeModal();
        this.$successToast("Événement créé");
      } catch (e) {
        this.$errorToast("Impossible d'ajouter l'événement");
      } finally {
        this.loading = false;
      }
    },

    async editEvent() {
      if (!this.editedEvent?.id) {
        return;
      }

      this.checkFields();

      if (Object.values(this.errors).filter((error) => error).length > 0) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$api.put(`/events/${this.editedEvent.id}`, {
          target: this.target,
          type: this.type.toUpperCase(),
          title: this.title,
          notes: this.notes,
        });

        this.closeModal();
        this.$emit("updated", data);
        this.$successToast("Événement mis à jour");
      } catch (e) {
        this.$errorToast("Impossible de modifier l'événement");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
