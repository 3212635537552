<template>
  <div>
    <ClientsProspectsFilters
      type="prospect"
      :commercials="commercials"
      :concepts="concepts"
      :form="searchForm"
      @applyFilters="applyFilters"
      @addNew="showNewClientModal = true"
    />

    <loader :isVisible="isLoading" />

    <div class="card">
      <div class="card-block">
        <tabs v-model="activeTab" @input="changeTab">
          <tab :header="headerAll">
            <Prospect
              :prospects="prospects.all"
              :prospectsTotal="totals.all"
              :page="page"
              :rows="limit"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerWaiting">
            <Prospect
              :prospects="prospects.enAttente"
              :prospectsTotal="totals.enAttente"
              :page="page"
              :rows="limit"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerLost">
            <Prospect
              :prospects="prospects.perdu"
              :prospectsTotal="totals.perdu"
              :page="page"
              :rows="limit"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerTreated">
            <Prospect
              :prospects="prospects.treated"
              :prospectsTotal="totals.treated"
              :page="page"
              :rows="limit"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerConverted">
            <Prospect
              :prospects="prospects.converti"
              :prospectsTotal="totals.converti"
              :page="page"
              :rows="limit"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerAlreadyKnown">
            <Prospect
              :prospects="prospects.alreadyKnown"
              :prospectsTotal="totals.alreadyKnown"
              :page="page"
              :rows="limit"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
        </tabs>
      </div>
    </div>

    <modal
      title="Archiver un prospect"
      v-model="archiveProspectModal.display"
      cancelText="Fermer"
      okText="Archiver"
      @ok="archiveProspect()"
      effect="fade/zoom"
    >
      <div v-if="archiveProspectModal.prospect">
        <p>
          Voulez-vous vraiment archiver le prospect
          <strong
            >{{ archiveProspectModal.prospect.firstName }}
            {{ archiveProspectModal.prospect.lastName }}
          </strong>
          ?
        </p>
      </div>
    </modal>

    <NewClientModal
      :show="showNewClientModal"
      :default="form"
      :commercials="commercials"
      :concepts="concepts"
      @close="showNewClientModal = false"
    />

    <ClientNoteModal
      :show="modalUpdateNote.show"
      :client="modalUpdateNote.prospect"
      @close="modalUpdateNote.show = false"
    />

    <ClientCommercialEditModal
      :show="modalEditClientCommercial.show"
      :client="modalEditClientCommercial.prospect"
      :commercials="commercials"
      @close="modalEditClientCommercial.show = false"
    />

    <ChangeStatusProspectOrClientModal
      :isProspect="true"
      :client="modalUpdateProspectType.prospectInfos"
      :show="modalUpdateProspectType.show"
      :globalLostReasons="lostReasons"
      :disabledDates="modalUpdateProspectType.disabledDates"
      :status="modalUpdateProspectType.type"
      @close="closeUpdateStatusModal"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Modal from "vue-strap/src/Modal";

import Prospect from "../components/Prospect";
import ClientsProspectsFilters from "../components/ClientsProspectsFilters";
import Loader from "../components/Loader";
import NewClientModal from "../components/NewClientModal";
import ClientNoteModal from "../components/ClientNoteModal";
import ClientCommercialEditModal from "../components/ClientCommercialEditModal";
import ChangeStatusProspectOrClientModal from "../components/modal/ChangeStatusProspectOrClientModal.vue";

export default {
  components: {
    Tabs,
    Tab,
    Modal,
    Prospect,
    ClientsProspectsFilters,
    Loader,
    NewClientModal,
    ClientNoteModal,
    ClientCommercialEditModal,
    ChangeStatusProspectOrClientModal,
  },

  data() {
    return {
      isLoading: false,
      activeTab: 0,
      searchForm: {
        begin: undefined,
        end: undefined,
        archives: "hidden",
        name: undefined,
        concepts: [],
        commercials: [],
        sortBy: "createdAt",
        sortOrder: "descending",
        aiStatus: "all",
        isB2B: false,
      },
      prospects: {
        all: [],
        enAttente: [],
        perdu: [],
        treated: [],
        converti: [],
        alreadyKnown: [],
      },
      totals: {
        all: 0,
        enAttente: 0,
        perdu: 0,
        treated: 0,
        converti: 0,
        alreadyKnown: 0,
      },
      page: 1,
      offset: 0,
      limit: 15,
      countProspects: true,
      getTotal: true,
      form: {
        email: undefined,
        password: undefined,
        commercial: undefined,
        prospect: {
          isProspect: true,
          type: "en-attente",
        },
      },
      commercials: [],
      concepts: [],
      modalUpdateProspectType: {
        show: false,
        prospectInfos: {},
        type: "",
        disabledDates: {
          to: moment().toDate(),
          from: moment().toDate(),
        },
      },
      archiveProspectModal: {
        display: false,
        prospect: null,
      },
      showNewClientModal: false,
      modalUpdateNote: {
        show: false,
        prospect: null,
      },
      modalEditClientCommercial: {
        show: false,
        prospect: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),

    prospectLostReasons() {
      return this.lostReasons
        .filter((reason) => !reason.clientsOnly)
        .map(({ label, value }) => ({ label, value }));
    },

    prospectStatus() {
      switch (this.activeTab) {
        case 0:
          return "all";
        case 1:
          return "en-attente";
        case 2:
          return "perdu";
        case 3:
          return "treated";
        case 4:
          return "converti";
        case 5:
          return "already-known";
        default:
          return null;
      }
    },

    headerAll() {
      return `Tous ${this.totals.all !== 0 ? "(" + this.totals.all + ")" : ""}`;
    },

    headerWaiting() {
      return `En Attente ${
        this.totals.enAttente !== 0 ? "(" + this.totals.enAttente + ")" : ""
      }`;
    },

    headerLost() {
      return `Perdu ${
        this.totals.perdu !== 0 ? "(" + this.totals.perdu + ")" : ""
      }`;
    },

    headerTreated() {
      return `Traité (en attente de paiement) ${
        this.totals.treated !== 0 ? "(" + this.totals.treated + ")" : ""
      }`;
    },

    headerConverted() {
      return `Converti ${
        this.totals.converti !== 0 ? "(" + this.totals.converti + ")" : ""
      }`;
    },

    headerAlreadyKnown() {
      return `Déjà connus ${
        this.totals.alreadyKnown !== 0
          ? "(" + this.totals.alreadyKnown + ")"
          : ""
      }`;
    },
  },

  async created() {
    await this.getCommercials();
    await this.getConcepts();

    let activeTab =
      this.$route.query.tab ?? sessionStorage.getItem("prospectsTab");

    if (activeTab && this.activeTab !== parseInt(activeTab)) {
      this.activeTab = parseInt(activeTab);
    }

    if (this.$route.query.page !== undefined) {
      this.page = parseInt(this.$route.query.page);
      this.offset = this.limit * this.page - this.limit;

      this.totals = Object.fromEntries(
        this.$route.query.totals.map(([key, value]) => [key, parseInt(value)])
      );
      this.countProspects = !!this.$route.query.count;
      this.getTotal = !!this.$route.query.getTotal;

      let commercials = [];
      let concepts = [];

      if (this.$route.query.commercials) {
        commercials = Array.isArray(this.$route.query.commercials)
          ? this.$route.query.commercials
          : [this.$route.query.commercials];
      }

      if (this.$route.query.concepts) {
        concepts = Array.isArray(this.$route.query.concepts)
          ? this.$route.query.concepts
          : [this.$route.query.concepts];
      }

      this.searchForm = {
        archives: this.$route.query.archives || "hidden",
        concepts: concepts
          .map((c) => this.concepts.find((concept) => concept.name === c))
          .filter((c) => c),
        commercials: commercials
          .map((c) =>
            this.commercials.find((commercial) => commercial.value === c)
          )
          .filter((c) => c),
        sortBy: this.$route.query.sortBy || "createdAt",
        sortOrder: this.$route.query.sortOrder || "descending",
        name: this.$route.query.name || "",
        aiStatus: this.$route.query.aiStatus || "all",
        isB2B: this.$route.query.isB2B || false,
        prospectStatus: this.$route.query.prospectStatus || "all",
        begin: this.$route.query.begin
          ? moment(this.$route.query.begin).toDate()
          : undefined,
        end: this.$route.query.end
          ? moment(this.$route.query.end).toDate()
          : undefined,
      };

      this.getProspect(this.offset);
    }
  },

  methods: {
    async changeTab(index) {
      this.getTotal = true;

      sessionStorage.setItem("prospectsTab", index);
    },

    async getProspect(offset) {
      this.isLoading = true;

      const { data } = await this.$api.get("/prospects", {
        params: {
          filter: {
            ...this.searchForm,
            commercials: this.searchForm.commercials.map((c) => c.value),
            concepts: this.searchForm.concepts.map((c) => c.name),
            prospectType: this.prospectStatus,
          },
          offset,
          limit: this.limit,
          count: this.countProspects,
          currentTotals: this.totals,
          getTotal: this.getTotal,
        },
      });

      this.totals = data.totals;

      switch (this.prospectStatus) {
        case "all":
          this.prospects.all = data.data;
          break;
        case "en-attente":
          this.prospects.enAttente = data.data;
          break;
        case "perdu":
          this.prospects.perdu = data.data;
          break;
        case "treated":
          this.prospects.treated = data.data;
          break;
        case "converti":
          this.prospects.converti = data.data;
          break;
        case "already-known":
          this.prospects.alreadyKnown = data.data;
          break;
      }

      this.checkCurrentSorting();

      this.countProspects = false;
      this.getTotal = false;

      this.isLoading = false;
    },

    async getCommercials() {
      try {
        this.isLoading = true;

        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
          color: c.commercial.color,
          data: c,
        }));
      } catch (e) {
        this.commercials = [];
      } finally {
        this.isLoading = false;
      }
    },

    async getConcepts() {
      try {
        this.isLoading = true;

        const { data } = await this.$api.get(`/concepts`);

        this.concepts = data.map(({ name }) => ({ name }));
      } catch (e) {
        this.concepts = [];
      } finally {
        this.isLoading = false;
      }
    },

    showCommentMethod(prospect) {
      this.modalUpdateNote = {
        ...this.modalUpdateNote,
        prospect,
        show: true,
      };
    },

    editCommercials(prospect) {
      this.modalEditClientCommercial = {
        ...this.modalUpdateNote,
        prospect,
        show: true,
      };
    },

    changeProspectType(prospectInfos) {
      this.modalUpdateProspectType.show = true;
      this.modalUpdateProspectType.prospectInfos = prospectInfos.prospect;
      this.modalUpdateProspectType.type = prospectInfos.type;

      if (prospectInfos.type === "perdu") {
        if (this.isAdmin(this.$store.state.user)) {
          this.modalUpdateProspectType.disabledDates = {
            to: moment(prospectInfos.prospect.createdAt)
              .endOf("day")
              .subtract(1, "days")
              .toDate(),
            from: moment().endOf("day").toDate(),
          };
        }
      }
    },

    filterProspectsAfterChangingStatus(updatedProspect, newStatus) {
      switch (this.prospectStatus) {
        case "all":
        case "already-known":
          switch (updatedProspect.prospect.type) {
            case "treated":
              this.totals.treated--;
              break;
            case "en-attente":
              this.totals.enAttente--;
              break;
          }

          updatedProspect.prospect.type = newStatus;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "en-attente":
          this.prospects.enAttente = this.prospects.enAttente.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.enAttente--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "perdu":
          this.prospects.perdu = this.prospects.perdu.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.perdu--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "treated":
          this.prospects.treated = this.prospects.treated.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.treated--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "converti":
          this.prospects.converti = this.prospects.converti.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.converti--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
      }
    },

    archiveProspectModalShow(prospect) {
      this.archiveProspectModal.display = true;
      this.archiveProspectModal.prospect = prospect;
    },

    async archiveProspect() {
      await this.$api.put("/clients", {
        email: this.archiveProspectModal.prospect.email,
        archived: true,
      });

      this.archiveProspectModal.prospect.archived = true;
      this.archiveProspectModal.display = false;
      this.countProspects = true;
    },

    applyFilters(form) {
      this.searchForm = {
        ...this.searchForm,
        ...form,
      };

      this.countProspects = true;

      this.changePage(1);
    },

    changePage(page) {
      this.$router.push({
        name: "prospects",
        query: {
          page,
          count: this.countProspects,
          tab: this.activeTab,
          ...this.searchForm,
          begin: this.searchForm.begin
            ? moment(this.searchForm.begin).format("YYYY-MM-DD")
            : undefined,
          end: this.searchForm.end
            ? moment(this.searchForm.end).format("YYYY-MM-DD")
            : undefined,
          concepts: this.searchForm.concepts.map((c) => c.name),
          commercials: this.searchForm.commercials.map((c) => c.value),
          prospectStatus: this.prospectStatus,
          totals: Object.entries(this.totals),
          getTotal: this.getTotal,
        },
      });
    },

    sortChange({ order, prop }) {
      this.searchForm.sortBy = prop;
      this.searchForm.sortOrder = order;
      this.countProspects = true;

      this.changePage(1);
    },

    getTabHeaderText(prop) {
      switch (prop) {
        case "prospect.type":
          return "Statut";
        case "firstName":
          return "Prénom";
        case "lastName":
          return "Nom";
        case "email":
          return "Email";
        case "phone":
          return "Téléphone";
        case "zipcode":
          return "CP";
        case "city":
          return "Ville";
        case "createdAt":
          return "Créé le";
        case "prospect.concept":
          return "Concept";
        default:
          return null;
      }
    },

    checkCurrentSorting() {
      const currentSortingHeader = this.getTabHeaderText(
        this.searchForm.sortBy
      );
      const allSortingTabs = document.querySelectorAll(".tab-pane.active th");
      const currentSortingTab = Array.from(allSortingTabs).find(
        (sortingTab) => {
          return sortingTab.textContent === currentSortingHeader;
        }
      );

      if (document.querySelector(".tab-pane.active th.descending")) {
        const displayedSortingTab = document.querySelector(
          ".tab-pane.active th.descending"
        );

        displayedSortingTab?.classList.remove("descending");
      } else if (document.querySelector(".tab-pane.active th.ascending")) {
        const displayedSortingTab = document.querySelector(
          ".tab-pane.active th.ascending"
        );
        displayedSortingTab?.classList.remove("ascending");
      }

      if (this.searchForm.sortOrder) {
        currentSortingTab?.classList.add(this.searchForm.sortOrder);
      }
    },

    closeUpdateStatusModal(updatedProspect) {
      this.modalUpdateProspectType.show = false;

      if (typeof updatedProspect !== "object") {
        this.modalUpdateProspectType.prospectInfos = {};
        this.modalUpdateProspectType.type = "";

        return;
      }

      const existingProspect = this.prospects[this.prospectStatus].find(
        (prospect) => prospect.id === updatedProspect.id
      );

      Object.assign(existingProspect, updatedProspect);

      this.filterProspectsAfterChangingStatus(
        updatedProspect,
        updatedProspect.prospect.type
      );
    },
  },
};
</script>

<style lang="scss">
.modal-dialog {
  margin-top: 5rem !important;
}
</style>
