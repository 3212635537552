<template>
  <div>
    <el-table :data="payments" style="width: 100%">
      <el-table-column width="25">
        <template slot-scope="scope">
          <div>
            <i v-if="scope.row.price < 0" class="text-success fa fa-repeat" />
            <i
              v-else-if="scope.row.captured"
              class="text-success fa fa-check-circle"
            />
            <i
              v-else-if="scope.row.failed"
              class="text-danger fa fa-exclamation-circle"
            />
            <i v-else class="text-warning fa fa-circle" />
          </div>
        </template>
      </el-table-column>
      <el-table-column width="50" v-if="concepts">
        <template slot-scope="scope">
          <div v-html="conceptLogo(scope.row.concept, concepts)"></div>
        </template>
      </el-table-column>
      <el-table-column width="150" label="Info" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.price < 0">
            <span class="text-success">
              Remboursé le {{ formatDate(scope.row.capturedAt) }}
            </span>
            <span
              v-if="
                scope.row.refundOrder &&
                scope.row.refundOrder.invoice &&
                scope.row.refundOrder.invoice.serial
              "
              v-tooltip="scope.row.refundOrder.invoice.serial"
              class="fa fa-info-circle"
            ></span>
          </span>
          <span v-else-if="scope.row.captured" class="text-success"
            >Payé le {{ formatDate(scope.row.capturedAt) }}</span
          >
          <span v-else class="small">En attente</span>
          <br />
          <strong class="small strong" v-if="scope.row.nbSessions"
            >{{ scope.row.nbSessions }} séance{{
              scope.row.nbSessions > 1 ? "s" : ""
            }}
          </strong>
          <br />
          <a
            class="link"
            @click="
              downloadSecuredFile(
                `uploads/invoice/${scope.row.invoice.pdf}.pdf`
              )
            "
            v-if="scope.row.invoice && scope.row.invoice.pdf"
            v-on:click.stop
          >
            {{ scope.row.invoice.serial }}
          </a>
        </template>
      </el-table-column>
      <el-table-column width="150" label="Date de créa." sortable>
        <template slot-scope="scope">
          <button
            v-if="hasPermission($store.state.user, 'PAYMENTS_WRITE')"
            class="btn btn-sm btn-link"
            @click="showCreatedAt(scope.row)"
          >
            {{ formatDate(scope.row.createdAt) }}
          </button>
          <span v-else>{{ formatDate(scope.row.createdAt) }}</span>
        </template>
      </el-table-column>
      <el-table-column width="140" label="Prélèvement" sortable>
        <template slot-scope="scope">
          <div v-if="scope.row.stripe && !scope.row.captured">
            <label
              class="switch switch-sm switch-text switch-info"
              style="margin-top: 3px"
              v-if="hasPermission($store.state.user, 'PAYMENTS_WRITE')"
            >
              <input
                type="checkbox"
                class="switch-input"
                :disabled="disableAutoCapture(scope.row)"
                v-model="scope.row.autoCapture"
                @change="(event) => autoCapture(scope.row, event)"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span v-else>{{ scope.row.autoCapture ? "Oui" : "Non" }}</span>
            <button
              v-if="
                scope.row.autoCapture &&
                hasPermission($store.state.user, 'PAYMENTS_WRITE')
              "
              class="btn btn-sm btn-link"
              @click="showAutoCaptureAt(scope.row)"
            >
              <i class="fa fa-clock-o" />
              {{ formatDate(scope.row.autoCaptureAt) }}
            </button>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="70" label="Alerte">
        <template slot-scope="scope">
          <label
            class="switch switch-sm switch-text switch-info"
            style="margin-top: 3px"
            v-if="
              !scope.row.captured &&
              hasPermission($store.state.user, 'PAYMENTS_WRITE')
            "
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="scope.row.alert"
              @change="(event) => deadlineAlert(scope.row, event)"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
          <span v-else>{{ scope.row.alert ? "Oui" : "Non" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="170"
        label="Date d'échéance"
        sortable
        prop="deadline"
      >
        <template slot-scope="scope">
          <button
            v-if="
              !scope.row.captured &&
              scope.row.deadline &&
              hasPermission($store.state.user, 'PAYMENTS_WRITE')
            "
            class="btn btn-sm btn-link"
            @click="showDeadline(scope.row)"
          >
            <i class="fa fa-exclamation-triangle" />
            {{ formatDate(scope.row.deadline) }}
          </button>
          <span v-else>{{ formatDate(scope.row.deadline) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        width="150"
        label="Montant"
        sortable
        prop="paiement.price"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.captured">
            <a
              v-if="scope.row.price < 0"
              @click="goToOrder(scope.row.refundOrderId)"
              class="text-danger"
            >
              {{ formatPrice(scope.row.price) }}
            </a>
            <span v-else> {{ formatPrice(scope.row.price) }}</span>
          </span>
          <button
            v-else-if="hasPermission($store.state.user, 'PAYMENTS_WRITE')"
            class="btn btn-sm btn-link"
            @click="showPrice(scope.row)"
          >
            {{ formatPrice(scope.row.price) }}
          </button>
          <span v-else>{{ formatPrice(scope.row.price) }}</span>
          <div v-if="scope.row.refunded">
            <span class="small text-danger"> Remboursé </span>
          </div>
          <div
            v-else-if="
              scope.row.urssafPaymentIntent?.statut ===
              'En refus de prélèvement'
            "
          >
            <span class="small text-danger">
              Remboursé - {{ formatPrice(scope.row.price / 2) }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        label="Client"
        sortable
        prop="paiement.refClient.fullName"
        v-if="showClient"
      >
        <template slot-scope="scope">
          <router-link
            target="_blank"
            v-if="scope.row.refClient"
            :to="'/clients/' + scope.row.refClient.id"
          >
            {{ scope.row.refClient.fullName }} <i class="icon-user"></i>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        label="Pack"
        sortable
        prop="paiement.order.session"
        v-if="showOrder"
      >
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.order"
            target="_blank"
            :to="'/orders/' + scope.row.order.id"
          >
            {{ formatPack(scope.row.order.session) }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="200" label="Référence" v-if="showOrder">
        <template slot-scope="scope">
          <span v-if="scope.row.order && scope.row.order.invoice">
            {{ scope.row.order.invoice.filename }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="250" label="Description">
        <template slot-scope="scope">
          <!-- Stripe -->
          <div v-if="scope.row.stripe">
            <!-- Stripe Payed -->
            <template v-if="scope.row.stripe.balance_transaction">
              <i class="fa fa-credit-card" /> Stripe<span class="hidden-print">
                - {{ scope.row.stripe.balance_transaction }}</span
              >
            </template>

            <!-- Stripe not payed -->
            <template v-else>
              <router-link :to="'/order/' + scope.row.id">
                <i class="fa fa-credit-card" /> Stripe
              </router-link>
              <span
                class="text-success cursor-pointer"
                @click="checkPayment(scope.row)"
              >
                <i class="fa fa-refresh" />
                <span v-if="checkLoading"> Chargement...</span>
              </span>
            </template>

            <!-- Stripe payed link -->
            <a
              class="stripe-search"
              v-if="scope.row.stripe.balance_transaction"
              :href="searchOnStripe(scope.row.stripe.balance_transaction)"
              target="_blank"
            >
              <i class="fa fa-search"></i>
            </a>
          </div>

          <!-- Avance immediate -->
          <template v-if="scope.row.isAI">
            <p>
              {{ scope.row.type }}
              <span v-if="scope.row.urssafPaymentIntent">
                <br />
                Statut :
                {{ scope.row.urssafPaymentIntent.statut || "Envoyé" }}
                <template v-if="scope.row.urssafPaymentIntent.dateVirement">
                  <br />
                  Date de virement :
                  {{ formatDate(scope.row.urssafPaymentIntent.dateVirement) }}
                </template>
              </span>
            </p>

            <template v-if="client">
              <button
                v-if="!scope.row.urssafPaymentIntent"
                class="btn btn-sm btn-info"
                @click="sendPaymentIntent(scope.row)"
              >
                Envoyer la demande
              </button>
              <button
                v-else
                class="btn btn-sm btn-info"
                @click="checkPaymentIntent(scope.row)"
              >
                Consulter le status
              </button>
            </template>
          </template>

          <!-- Generic payments (chèques, CESU etc.) -->
          <template v-if="isGenericPayment(scope.row)">
            {{ scope.row.type }}
            <button
              class="btn btn-sm btn-link"
              @click="showType(scope.row)"
              v-if="
                isAdmin($store.state.user) &&
                scope.row.type !== 'Refus de prélèvement URSSAF'
              "
            >
              <i class="fa fa-edit" />
            </button>
          </template>

          <!-- Not stripe & not payed -->
          <div v-if="isGenericPayment(scope.row) && !scope.row.captured">
            <!-- Create sessions -->
            <button
              v-if="
                !scope.row.pending &&
                hasPermission($store.state.user, 'PAYMENTS_WRITE')
              "
              class="btn btn-sm btn-outline-warning"
              @click="allow(scope.row)"
            >
              <i class="fa fa-user-plus"></i> Créer les séances
            </button>

            <!-- Set payed -->
            <button
              v-if="
                !scope.row.captured &&
                hasPermission($store.state.user, 'PAYMENTS_WRITE')
              "
              class="btn btn-sm btn-success"
              @click="capture(scope.row)"
            >
              <i class="fa fa-check-circle-o"></i> Payé
            </button>

            <!-- Stripe typeform (legacy) -->
            <a
              class="stripe-search"
              v-if="scope.row.typeform && scope.row.typeform.responseId"
              :href="searchOnStripe(scope.row.typeform.responseId)"
              target="_blank"
            >
              <i class="fa fa-search"></i>
            </a>
          </div>

          <!-- Refund no stripe -->
          <div
            v-else-if="
              isGenericPayment(scope.row) &&
              !scope.row.refunded &&
              scope.row.price > 0 &&
              scope.row.type !== 'Refus de prélèvement URSSAF'
            "
          >
            <button
              class="btn btn-sm btn-info"
              @click="showRefund(scope.row)"
              v-if="hasPermission($store.state.user, 'REFUNDS_WRITE')"
            >
              <i class="icon-reload"></i> Rembourser
            </button>
            <a
              class="stripe-search"
              v-if="scope.row.typeform && scope.row.typeform.responseId"
              :href="searchOnStripe(scope.row.typeform.responseId)"
              target="_blank"
            >
              <i class="fa fa-search"></i>
            </a>
          </div>

          <!-- Stripe refund -->
          <div v-else-if="scope.row.stripe && scope.row.captured">
            <button
              class="btn btn-sm btn-info"
              @click="showRefund(scope.row)"
              v-if="hasPermission($store.state.user, 'REFUNDS_WRITE')"
            >
              <i class="icon-reload"></i> Rembourser
            </button>
            <a
              class="stripe-search"
              v-if="scope.row.typeform && scope.row.typeform.responseId"
              :href="searchOnStripe(scope.row.typeform.responseId)"
              target="_blank"
            >
              <i class="fa fa-search"></i>
            </a>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="210" label="Payeur">
        <template slot-scope="scope">
          <div v-if="!scope.row.stripe" class="d-flex align-items-center">
            <div class="mr-1">
              <button
                class="btn btn-sm btn-info"
                @click="showPayer(scope.row)"
                v-if="hasPermission($store.state.user, 'PAYMENTS_WRITE')"
              >
                <i class="fa fa-pencil-square-o"></i>
              </button>
            </div>
            <div>
              <p class="m-0" v-if="scope.row.payer">
                {{ scope.row.payer }}
              </p>
              <p class="m-0 text-muted" v-if="scope.row.depositDate">
                déposé le {{ formatDate(scope.row.depositDate) }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="200" label="Commerciaux" v-if="showOrder">
        <template slot-scope="scope">
          <template v-for="(rel, index) of scope.row.order?.commercials">
            <template>
              <CommercialRel :key="`com-${index}`" :relation="rel" />
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="200" label="Notes">
        <template slot-scope="scope">
          <input
            type="text"
            v-model="scope.row.notes"
            @change="savePaymentNotes(scope.row)"
            v-if="hasPermission($store.state.user, 'PAYMENTS_WRITE')"
            v-tooltip="{ content: scope.row.notes, placement: 'top' }"
          />
          <span
            v-else
            v-tooltip="{ content: scope.row.notes, placement: 'top' }"
            >{{ scope.row.notes }}</span
          >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <span
            class="text-warning"
            v-if="scope.row.failed && scope.row.paymentFailedAt"
          >
            Relance email auto le {{ formatDate(scope.row.paymentFailedAt) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Actions">
        <template slot-scope="scope">
          <button
            v-if="!scope.row.captured && !scope.row.isAI"
            class="btn btn-sm btn-link"
            @click="sendMail(scope.row.id)"
          >
            <span class="fa fa-at"></span>
          </button>
          <button
            v-if="
              (!scope.row.captured || !scope.row.stripe) &&
              hasPermission($store.state.user, 'PAYMENTS_WRITE')
            "
            class="btn btn-sm btn-danger"
            @click="remove(scope.row)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
    <modal
      title="Modifier la date de création"
      v-model="editCreatedAt"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <datepicker
          v-model="editPayment.createdAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </template>
    </modal>

    <modal
      title="Modifier le prélèvement automatique"
      v-model="editAutoCaptureAt"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <datepicker
          v-model="editPayment.autoCaptureAt"
          :disabled="onlyFuture"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </template>
    </modal>

    <modal
      title="Modifier la date d'échéance de paiement"
      v-model="editDeadline"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <datepicker
          v-model="editPayment.deadline"
          :disabled="onlyFuture"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />

        <button
          class="btn btn-outline-danger mt-1"
          @click="removeDeadline(editPayment)"
        >
          Supprimer la date d'échéance
        </button>

        <textarea
          v-if="editPayment.alert"
          class="form-control mt-1"
          rows="4"
          v-model="editPayment.alertMessage"
          placeholder="Saisir une note qui sera transmise avec l'alerte"
        />
      </template>
    </modal>

    <modal
      title="Modifier le montant"
      v-model="editPrice"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <input v-model="formPrice" type="text" class="form-control" />
      </template>
    </modal>

    <modal
      title="Valider le paiement"
      v-model="editCaptured"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePaymentCapture()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <label>Date du paiement </label>
        <datepicker
          v-if="editPayment.captured && !editPayment.stripe"
          v-model="editPayment.capturedAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </template>
    </modal>

    <modal
      title="Modifier le paiement"
      v-model="editType"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <input
          v-model="editPayment.type"
          type="text"
          class="form-control mb-1"
        />

        <strong>Changer la date d'encaissement :</strong>
        <datepicker
          v-if="editPayment.captured && !editPayment.stripe"
          v-model="editPayment.capturedAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </template>
    </modal>

    <modal
      title="Modifier la date de création"
      v-model="editCreatedAt"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="savePayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <datepicker
          v-model="editPayment.createdAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </template>
    </modal>

    <modal
      title="Rembourser un paiement"
      v-model="editRefund"
      cancelText="Fermer"
      okText="Rembourser"
      @ok="refundPayment()"
      effect="fade/zoom"
    >
      <template v-if="editPayment">
        <div class="form-group">
          <label>Description</label>
          <div class="input-group">
            <input v-model="form.refundType" type="text" class="form-control" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Montant</label>
            <div class="input-group">
              <input
                v-model="form.refundPrice"
                type="text"
                class="form-control"
                @input="(e) => refundPriceInput(e.target.value)"
              />
              <span class="input-group-addon"><i class="fa fa-euro"></i></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Prospect / Client perdu</label>
            <div class="input-group">
              <label class="switch switch-sm switch-text switch-info mb-0">
                <input
                  type="checkbox"
                  class="switch-input"
                  v-model="form.refundLostStatus"
                  @click="handleStatus"
                />
                <span class="switch-label" data-on="Oui" data-off="Non"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="form.refundLostStatus === true" class="row">
          <div class="form-group col-sm-6">
            <label>Cause de la perte</label>
            <div class="input-group">
              <select class="form-control" v-model="form.refundLostReasons">
                <option
                  v-for="(item, index) of lostReasons"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal
      v-if="payerModal.payment"
      title="Éditer un payeur"
      v-model="payerModal.show"
      cancelText="Fermer"
      okText="Éditer"
      @ok="editPayer()"
      effect="fade/zoom"
    >
      <div class="row">
        <div class="form-group col-sm-12">
          <label for="payer">Payeur</label><br />
          <input
            name="payer"
            type="text"
            class="form-control"
            v-model="payerModal.payment.payer"
          />
        </div>
        <div class="form-group col-sm-12">
          <div class="d-flex align-items-center">
            <label for="payer">Date de dépôts</label>
            <button
              class="btn btn-warning ml-1"
              @click="payerModal.payment.depositDate = null"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <datepicker
            class="mt-1"
            name="depositDate"
            v-model="payerModal.payment.depositDate"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
      </div>
    </modal>

    <modal
      v-if="editStripeRefund"
      title="Rembourser le paiement Stripe"
      v-model="editStripeRefund"
      cancelText="Fermer"
      okText="Rembourser"
      @ok="refundStripePayment()"
      effect="fade/zoom"
    >
      <template v-if="editStripeRefund">
        <div class="alert alert-warning small">
          Les remboursements apparaissent sur le relevé bancaire du client sous
          5 à 10 jours ouvrables. Stripe ne restitue pas les frais correspondant
          au paiement initial, mais ne facture pas de frais supplémentaires pour
          le remboursement. En savoir plus.
        </div>
        <div class="form-group">
          <label>Montant</label>
          <div class="input-group">
            <input
              v-model="formStripe.amount"
              type="text"
              class="form-control"
              @input="(e) => refundStripeAmountInput(e.target.value)"
            />
            <span class="input-group-addon"><i class="fa fa-euro"></i></span>
          </div>
        </div>
        <div class="form-group">
          <label>Motif</label>
          <div class="input-group">
            <select class="form-control" v-model="formStripe.reason">
              <option
                v-for="(item, index) of reasons"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import DownloadFile from "../mixin/DownloadFile";

import CommercialRel from "../components/CommercialRel";

export default {
  components: {
    Modal,
    Datepicker,
    CommercialRel,
  },

  props: {
    client: {
      type: Object,
      default: () => {},
    },
    paymentsData: {
      type: Array,
      default: () => [],
    },
    showOrder: {
      type: Boolean,
      default: false,
    },
    showClient: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [DownloadFile],

  data() {
    return {
      formPrice: null,
      editCreatedAt: false,
      editAutoCaptureAt: false,
      editDeadline: false,
      editPrice: false,
      editType: false,
      editRefund: false,
      editCaptured: false,
      editPayment: null,
      editStripeRefund: false,
      editStripePayment: null,
      onlyFuture: {
        to: moment().subtract(1, "days").toDate(),
      },
      form: {
        refundType: null,
        refundPrice: null,
        refundLostStatus: false,
        refundLostReasons: null,
      },
      formStripe: {
        amount: null,
        reason: null,
      },
      reasons: [
        {
          label: "Doublon",
          value: "duplicate",
        },
        {
          label: "Frauduleux",
          value: "fraudulent",
        },
        {
          label: "Demandé par le client",
          value: "requested_by_customer",
        },
      ],
      checkLoading: false,
      paymentToCapture: null,
      payerModal: {
        show: false,
        payment: null,
      },
      payments: [],
    };
  },

  created() {
    this.sortPayments();
  },

  computed: {
    ...mapState({
      concepts: (state) => state.concepts,
    }),
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),
  },

  methods: {
    handleStatus() {
      this.refundLostStatus = !this.refundLostStatus;
    },

    autoCapture(payment, event) {
      this.$nextTick(() => {
        this.$set(payment, "autoCapture", event.target.checked);

        if (payment.autoCapture && !payment.autoCaptureAt) {
          this.$set(
            payment,
            "autoCaptureAt",
            moment().add(1, "month").format()
          );
        }

        this.editPayment = payment;
        this.savePayment();
      });
    },

    deadlineAlert(payment, event) {
      this.$nextTick(() => {
        this.$set(payment, "alert", event.target.checked);

        if (payment.alert && !payment.deadline) {
          this.$set(payment, "deadline", moment().add(15, "days").format());
        }

        this.editPayment = payment;
        this.savePayment();
      });
    },

    showCreatedAt(payment) {
      this.editPayment = payment;
      this.editCreatedAt = true;
    },

    showAutoCaptureAt(payment) {
      this.editPayment = payment;
      this.editAutoCaptureAt = true;
    },

    showDeadline(payment) {
      this.editPayment = payment;
      this.editDeadline = true;
    },

    showPrice(payment) {
      this.editPayment = payment;
      this.formPrice = parseFloat(
        +(Math.round(+(payment.price / 100 + "e" + 2)) + "e" + -2)
      ).toFixed(2);
      this.editPrice = true;
    },

    showType(payment) {
      this.editPayment = payment;
      this.editType = true;
    },

    showRefund(payment) {
      if (payment.stripe) {
        this.formStripe.amount = parseFloat(
          +(Math.round(+(payment.price / 100 + "e" + 2)) + "e" + -2)
        ).toFixed(2);
        this.editStripeRefund = true;
        this.editStripePayment = payment;
      } else {
        this.form.refundPrice = parseFloat(
          +(Math.round(+(payment.price / 100 + "e" + 2)) + "e" + -2)
        ).toFixed(2);
        this.editPayment = payment;
        this.editRefund = true;
      }
    },

    showPayer(payment) {
      this.payerModal.show = true;
      this.payerModal.payment = payment;
    },

    async editPayer() {
      if (!this.payerModal.payment) {
        return;
      }

      await this.$api.put(`/payments/${this.payerModal.payment.id}`, {
        payer: this.payerModal.payment.payer,
        depositDate: this.payerModal.payment.depositDate,
      });

      this.payerModal.show = false;
    },

    removeDeadline(payment) {
      payment.alert = false;
      payment.deadline = undefined;
      payment.removeDeadline = true;

      this.editPayment = payment;
      this.savePayment();
    },

    savePayment() {
      this.editDeadline = false;
      this.editAutoCaptureAt = false;
      this.editPrice = false;
      this.editType = false;
      this.editCreatedAt = false;

      this.$api
        .put("/payments/" + this.editPayment.id, {
          alert: this.editPayment.alert,
          alertMessage: this.editPayment.alertMessage,
          deadline: this.editPayment.deadline,
          autoCapture: this.editPayment.autoCapture,
          autoCaptureAt: this.editPayment.autoCaptureAt,
          createdAt: this.editPayment.createdAt,
          capturedAt: this.editPayment.capturedAt,
          price: this.editPayment.price,
          type: this.editPayment.type,
          removeDeadline: this.editPayment.removeDeadline,
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.editPayment.removeDeadline = false;
        });
    },

    async savePaymentCapture() {
      (this.editCaptured = false),
        await this.$api.put("/payments/" + this.editPayment.id, {
          capturedAt: this.editPayment.capturedAt,
          captured: true,
        });

      this.editPayment = null;

      this.$emit("captured", true);
    },

    async savePaymentNotes(payment) {
      await this.$api.put(`/payments/notes/${payment.id}`, {
        notes: payment.notes,
      });
    },

    async refundPayment() {
      try {
        await this.$confirm({
          message: "Attention, cette action est irréversible !",

          onConfirm: async () => {
            await this.$api.post("/payments/refund", {
              refundId: this.editPayment.id,
              refundPrice: this.form.refundPrice,
              refundType: this.form.refundType,
              refundLostStatus: this.form.refundLostStatus,
              refundLostReasons: this.form.refundLostReasons,
            });

            window.location.reload(true);
          },
        });
      } catch (e) {
        console.warn(e);
      }
    },

    refundStripePayment() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.post("/payments/stripe-refund", {
            paymentId: this.editStripePayment.id,
            amount: parseInt(this.formStripe.amount * 100),
            reason: this.formStripe.reason,
          });

          window.location.reload(true);
        },
      });
    },

    sendMail(paymentId) {
      this.$confirm({
        message:
          "Êtes-vous sûr de vouloir envoyer un mail au client concernant son paiement ?",
        onConfirm: async () => {
          try {
            await this.$api.post("/payments/mail-info", { id: paymentId });

            this.$successToast("Email envoyé");
          } catch (e) {
            console.log(e);
          }
        },
      });
    },

    remove(payment) {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/payments/${payment.id}`);

            this.$emit(
              "update:paymentsData",
              this.payments.filter((p) => p.id !== payment.id)
            );
          } catch (e) {
            console.log(e);
          }
        },
      });
    },

    allow(payment) {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          try {
            await this.$api.put(`/payments/${payment.id}`, { pending: true });

            payment.pending = true;
            this.$emit("pending", true);
          } catch (e) {
            console.log(e);
          }
        },
      });
    },

    capture(payment) {
      this.editPayment = { ...payment, captured: true };
      this.editCaptured = true;
    },

    disableAutoCapture(payment) {
      if (this.client) {
        return !this.client.stripe;
      } else if (payment.refClient) {
        return !payment.refClient.stripe;
      }
      return false;
    },

    searchOnStripe(query) {
      return `https://dashboard.stripe.com/search?query=${query}`;
    },

    checkPayment(payment) {
      this.checkLoading = true;

      this.$api
        .post("/payments/fix-stripe-payment", {
          orderId: payment.orderId,
          paymentId: payment.id,
          paymentPrice: payment.price,
        })
        .then(() => {
          this.checkLoading = false;
          payment.captured = true;
          this.$emit("captured", true);
        })
        .catch(() => {
          this.checkLoading = false;
          this.$errorToast("Ce paiement n'a pas été trouvé coté Stripe");
        });
    },

    goToOrder(orderId) {
      if (orderId) {
        const route = this.$router.resolve({
          name: "order-details",
          params: {
            id: orderId,
          },
        });

        window.open(route.href, "_blank");
      }
    },

    refundPriceInput(value) {
      this.form.refundPrice = this.formatFormPrice(value);
    },

    refundStripeAmountInput(value) {
      this.formStripe.amount = this.formatFormPrice(value);
    },

    isGenericPayment(payment) {
      return !payment.type !== "stripe" && !payment.stripe && !payment.isAI;
    },

    async sendPaymentIntent(payment) {
      this.$confirm({
        message:
          "Attention, voulez-vous vraiment envoyer la demande de paiement à l'urssaf ?",
        onConfirm: async () => {
          await this.$api.post("/urssaf/send-payment-intent", {
            clientId: this.client?.email,
            paymentId: payment.id,
          });

          window.location.reload();
        },
      });
    },

    async checkPaymentIntent(payment) {
      await this.$api.post("/urssaf/check-payment-intent", {
        paymentId: payment.id,
      });

      window.location.reload();
    },

    sortPayments() {
      const payments = this.paymentsData.filter(
        (payment) => !payment.paymentId
      );
      const refunds = this.paymentsData.filter((payment) => payment.paymentId);

      for (const refund of refunds) {
        const indexWhereToAddRefund = payments.findIndex(
          (payment) => payment.id === refund.paymentId
        );

        payments.splice(indexWhereToAddRefund + 1, 0, refund);
      }

      this.payments = payments;
    },
  },

  watch: {
    formPrice(value) {
      if (value) {
        this.editPayment.price = parseFloat(this.formatFormPrice(value)) * 100;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-search {
  padding-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.el-table {
  overflow: auto;
}
.el-table__body-wrapper,
.el-table__header-wrapper,
.el-table__footer-wrapper {
  overflow: visible;
}
.el-table::after {
  position: relative !important;
}
</style>
