<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <div>
        <i class="icon-list" style="margin-right: 0.5rem"></i> {{ title }}
        <span v-if="total">({{ total }})</span>
      </div>

      <button
        v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')"
        type="button"
        class="btn btn-sm btn-primary"
        @click="userEventModal = true"
      >
        <i class="fa fa-plus"></i>&nbsp; Ajouter
      </button>
    </div>

    <div class="card-block">
      <EventsTable
        :data="data"
        :target="target"
        @refresh="refreshData"
        @sort="sortChange"
        @added="addContent"
        @updated="updatedContent"
        @deleted="deletedContent"
      />

      <Paginate
        v-if="count"
        v-model="currentPage"
        ref="paginate"
        :page-count="count"
        :click-handler="paginationHandler"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
        class="mt-1"
      ></Paginate>
    </div>

    <ModalEvent
      :show="userEventModal"
      :target="target"
      @close="closeUserEventModal"
      @added="addContent"
    />
  </div>
</template>

<script>
import ModalEvent from "./ModalEvent";
import EventsTable from "./EventsTable";
import Paginate from "vuejs-paginate";

export default {
  components: {
    ModalEvent,
    EventsTable,
    Paginate,
  },

  props: {
    target: {
      type: String,
      required: true,
      validator: (value) => ["COACH", "CLIENT"].includes(value),
    },

    data: {
      type: Array,
      default: () => [],
    },

    total: {
      type: Number,
      default: 0,
    },

    page: {
      type: Number,
      default: 1,
    },

    rows: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      currentPage: 1,
      userEventModal: false,
    };
  },

  computed: {
    count() {
      return Math.ceil(this.total / this.rows);
    },

    title() {
      switch (this.target) {
        case "COACH":
          return "Notifications application coach";
        case "CLIENT":
          return "Notifications espace client";
        default:
          return "Notifications";
      }
    },
  },

  watch: {
    page() {
      if (this.page - 1 !== this.$refs.paginate.selected) {
        this.currentPage = this.page;
        this.$refs.paginate.selected = this.page - 1;
      }
    },
  },

  mounted() {
    this.currentPage = this.page;
  },

  methods: {
    paginationHandler(page) {
      this.$emit("changePage", page);
    },

    closeUserEventModal() {
      this.userEventModal = false;
    },

    addContent() {
      this.$emit("added");
    },

    updatedContent(contentId) {
      this.$emit("updated", contentId);
    },

    deletedContent(contentId) {
      this.$emit("deleted", contentId);
    },

    sortChange(sortBy) {
      this.$emit("sorted", sortBy);
    },

    refreshData() {
      this.$emit("refresh");
    },
  },
};
</script>
