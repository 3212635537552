<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <StatsMarginRateFilters @apply="applyFilters" />

    <div class="card">
      <div class="card-header">
        <i class="fa fa-bar-chart-o"></i> Taux de marge
      </div>

      <div class="card-block">
        <div v-if="!stats" class="text-center text-muted">
          Sélectionnez des filtres, puis cliquez sur "Visualiser"
        </div>
        <div v-else class="row">
          <div class="col-sm-4 mb-1">
            <div class="stat-card bg-success">
              <div>Taux de marge</div>
              <div class="font-weight-bold">{{ stats.marginRate }} %</div>
            </div>
          </div>
          <div class="col-sm-4 mb-1">
            <div class="stat-card bg-info">
              <div>Prix moyen / séance</div>
              <div class="font-weight-bold">
                {{ stats.averageSessionPrice }} €
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-1">
            <div class="stat-card bg-info">
              <div>Taux horaire coach moyen</div>
              <div class="font-weight-bold">
                {{ stats.averageCoachCredit }} €
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-1">
            <div class="stat-card bg-info">
              <div>Nombre de séances</div>
              <div class="font-weight-bold">{{ stats.totalSessions }}</div>
            </div>
          </div>
          <div class="col-sm-4 mb-1">
            <div class="stat-card bg-info">
              <div>Prix total (séances)</div>
              <div class="font-weight-bold">{{ stats.totalPrice }} €</div>
            </div>
          </div>
          <div class="col-sm-4 mb-1">
            <div class="stat-card bg-info">
              <div>Coût total (coachs)</div>
              <div class="font-weight-bold">{{ stats.totalCredit }} €</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import StatsMarginRateFilters from "../components/StatsMarginRateFilters";

export default {
  components: {
    Loader,
    StatsMarginRateFilters,
  },

  data() {
    return {
      loading: false,
      stats: null,
      filters: {
        begin: null,
        end: null,
        type: null,
        concepts: [],
        merchants: [],
        commercials: [],
      },
    };
  },

  methods: {
    async loadStats() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/stats/margin-rate", {
          params: this.filters,
        });

        this.stats = data;
      } catch (e) {
        this.$errorToast("Impossible de charger cette statistique");
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      this.loadStats();
    },
  },
};
</script>

<style lang="css" scoped>
.stat-card {
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
