export default {
  authToken: null,
  user: null,
  merchant: null,
  merchants: [],
  invoiceSeries: [],
  invoiceSerie: null,
  departments: [
    { code: "all", name: "Tous" },
    { code: "01", name: "Ain" },
    { code: "02", name: "Aisne" },
    { code: "03", name: "Allier" },
    { code: "04", name: "Alpes-de-Haute-Provence" },
    { code: "05", name: "Hautes-Alpes" },
    { code: "06", name: "Alpes-Maritimes" },
    { code: "07", name: "Ardèche" },
    { code: "08", name: "Ardennes" },
    { code: "09", name: "Ariège" },
    { code: "10", name: "Aube" },
    { code: "11", name: "Aude" },
    { code: "12", name: "Aveyron" },
    { code: "13", name: "Bouches-du-Rhône" },
    { code: "14", name: "Calvados" },
    { code: "15", name: "Cantal" },
    { code: "16", name: "Charente" },
    { code: "17", name: "Charente-Maritime" },
    { code: "18", name: "Cher" },
    { code: "19", name: "Corrèze" },
    { code: "2A", name: "Corse-du-Sud" },
    { code: "2B", name: "Haute-Corse" },
    { code: "21", name: "Côte-d'Or" },
    { code: "22", name: "Côtes-d'Armor" },
    { code: "23", name: "Creuse" },
    { code: "24", name: "Dordogne" },
    { code: "25", name: "Doubs" },
    { code: "26", name: "Drôme" },
    { code: "27", name: "Eure" },
    { code: "28", name: "Eure-et-Loir" },
    { code: "29", name: "Finistère" },
    { code: "30", name: "Gard" },
    { code: "31", name: "Haute-Garonne" },
    { code: "32", name: "Gers" },
    { code: "33", name: "Gironde" },
    { code: "34", name: "Hérault" },
    { code: "35", name: "Ille-et-Vilaine" },
    { code: "36", name: "Indre" },
    { code: "37", name: "Indre-et-Loire" },
    { code: "38", name: "Isère" },
    { code: "39", name: "Jura" },
    { code: "40", name: "Landes" },
    { code: "41", name: "Loir-et-Cher" },
    { code: "42", name: "Loire" },
    { code: "43", name: "Haute-Loire" },
    { code: "44", name: "Loire-Atlantique" },
    { code: "45", name: "Loiret" },
    { code: "46", name: "Lot" },
    { code: "47", name: "Lot-et-Garonne" },
    { code: "48", name: "Lozère" },
    { code: "49", name: "Maine-et-Loire" },
    { code: "50", name: "Manche" },
    { code: "51", name: "Marne" },
    { code: "52", name: "Haute-Marne" },
    { code: "53", name: "Mayenne" },
    { code: "54", name: "Meurthe-et-Moselle" },
    { code: "55", name: "Meuse" },
    { code: "56", name: "Morbihan" },
    { code: "57", name: "Moselle" },
    { code: "58", name: "Nièvre" },
    { code: "59", name: "Nord" },
    { code: "60", name: "Oise" },
    { code: "61", name: "Orne" },
    { code: "62", name: "Pas-de-Calais" },
    { code: "63", name: "Puy-de-Dôme" },
    { code: "64", name: "Pyrénées-Atlantiques" },
    { code: "65", name: "Hautes-Pyrénées" },
    { code: "66", name: "Pyrénées-Orientales" },
    { code: "67", name: "Bas-Rhin" },
    { code: "68", name: "Haut-Rhin" },
    { code: "69", name: "Rhône" },
    { code: "70", name: "Haute-Saône" },
    { code: "71", name: "Saône-et-Loire" },
    { code: "72", name: "Sarthe" },
    { code: "73", name: "Savoie" },
    { code: "74", name: "Haute-Savoie" },
    { code: "75", name: "Paris" },
    { code: "76", name: "Seine-Maritime" },
    { code: "77", name: "Seine-et-Marne" },
    { code: "78", name: "Yvelines" },
    { code: "79", name: "Deux-Sèvres" },
    { code: "80", name: "Somme" },
    { code: "81", name: "Tarn" },
    { code: "82", name: "Tarn-et-Garonne" },
    { code: "83", name: "Var" },
    { code: "84", name: "Vaucluse" },
    { code: "85", name: "Vendée" },
    { code: "86", name: "Vienne" },
    { code: "87", name: "Haute-Vienne" },
    { code: "88", name: "Vosges" },
    { code: "89", name: "Yonne" },
    { code: "90", name: "Territoire de Belfort" },
    { code: "91", name: "Essonne" },
    { code: "92", name: "Hauts-de-Seine" },
    { code: "93", name: "Seine-Saint-Denis" },
    { code: "94", name: "Val-de-Marne" },
    { code: "95", name: "Val-d'Oise" },
    { code: "971", name: "Guadeloupe" },
    { code: "972", name: "Martinique" },
    { code: "973", name: "Guyane" },
    { code: "974", name: "La Réunion" },
    { code: "976", name: "Mayotte" },
    { code: "988", name: "Nouméa" },
  ],
  commonUtm: [
    {
      label: "Google",
      value: "google",
      color: "#db4a39",
    },
    {
      label: "Instagram",
      value: "instagram",
      color: "#8a3ab9",
    },
    {
      label: "Facebook",
      value: "facebook",
      color: "#3b5998",
    },
    {
      label: "Bing",
      value: "bing",
      color: "#284B86",
    },
    {
      label: "Mailjet",
      value: "mailjet",
      color: "#5a03d5",
    },
    {
      label: "Sms",
      value: "sms",
      color: "#28B4FF",
    },
    {
      label: "Affiche",
      value: "affiche",
      color: "#f28d39",
    },
    {
      label: "Téléphone",
      value: "tel",
      color: "#d48ddb",
    },
    {
      label: "Trustpilot",
      value: "trustpilot",
      color: "#000032",
    },
    {
      label: "Pas d'UTM",
      value: "no-utm",
      color: "#d2b933",
    },
  ],
  lostReasons: [
    {
      label: "Hors Secteur (dans un secteur pas ciblé, pas habituel)",
      value: "no-sector",
      color: "#003f5c",
      clientsOnly: false,
    },
    {
      label: "Hors Budget (pas les moyens)",
      value: "no-budget",
      color: "#2f4b7c",
      clientsOnly: false,
    },
    {
      label: "Hors disponibilités",
      value: "unavailable",
      color: "#665191",
      clientsOnly: false,
      details: [
        {
          label: "Il y a des coachs mais aucun avec ces disponibilités",
          value: "unavailable-coach",
          color: "#665191",
        },
        {
          label:
            "Le client n’a pas de disponibilité pour débuter un accompagnement",
          value: "unavailable-client",
          color: "#665191",
        },
      ],
    },
    {
      label: "Pas Joignable (jamais eu en ligne)",
      value: "unreachable",
      color: "#a05195",
      clientsOnly: false,
    },
    {
      label: "Plus Joignable",
      value: "unreachable-twice",
      color: "#a05195",
      clientsOnly: false,
      details: [
        {
          label: "Après un premier échange qui explique le fonctionnement",
          value: "unreachable-twice-after-explain",
          color: "#a05195",
        },
        {
          label: "Après un envoi de paiement pour une séance découverte",
          value: "unreachable-twice-after-payment-discovery",
          color: "#a05195",
        },
        {
          label: "Après avoir effectué la séance découverte",
          value: "unreachable-twice-after-discovery",
          color: "#a05195",
        },
        {
          label: "A la suite de la fin d’un pack (conversion ou fidélisation)",
          value: "unreachable-twice-finished-pack",
          color: "#a05195",
        },
      ],
    },
    {
      label: "Salle de sport (veut aller en salle)",
      value: "gym",
      color: "#d45087",
      clientsOnly: true,
    },
    {
      label: "Pas de coach (aucun coach sur l’attribution)",
      value: "problem-coach",
      color: "#d45087",
      clientsOnly: true,
    },
    {
      label: "Formulaire coach (c’est un coach et pas un client)",
      value: "form-coach",
      color: "#d45087",
      clientsOnly: true,
    },
    {
      label: "Objectif Atteint",
      value: "goal-reached",
      color: "#f95d6a",
      clientsOnly: true,
    },
    {
      label: "Objectif Pas Atteint",
      value: "goal-unreached",
      color: "#ff7c43",
      clientsOnly: true,
    },
    {
      label: "Problème Santé (blessure, grossesse...)",
      value: "health-issue",
      color: "#ffa600",
      clientsOnly: true,
    },
    {
      label: "Spam & hors sujet (faux formulaire)",
      value: "spam",
      color: "#FFC65C",
      clientsOnly: false,
    },
    {
      label: "Perdu coach (problème du au coach)",
      value: "lost-coach",
      color: "#FFC65C",
      clientsOnly: false,
    },
    {
      label: "Pas de coach femmes",
      value: "lost-women-coach",
      color: "#bf26d3",
      clientsOnly: false,
    },
    {
      label: "Abandon",
      value: "abandonment",
      color: "#539987",
      clientsOnly: false,
      details: [
        {
          label: "Après un premier échange qui explique le fonctionnement",
          value: "abandonment-after-explain",
          color: "#539987",
        },
        {
          label: "Après un envoi de paiement pour une séance découverte",
          value: "abandonment-after-payment-discovery",
          color: "#539987",
        },
        {
          label: "Après avoir effectué la séance découverte",
          value: "abandonment-after-discovery",
          color: "#539987",
        },
        {
          label: "A la suite de la fin d’un pack (conversion ou fidélisation)",
          value: "abandonment-finished-pack",
          color: "#539987",
        },
      ],
    },
    {
      label: "Curiosité d’information",
      value: "information-curiosity",
      color: "#bea7e5",
      clientsOnly: true,
    },
  ],
  fidelisationRanks: [
    {
      label: "Fidélisation",
      value: "fidelisation",
    },
    {
      label: "En attente",
      value: "pending",
    },
    {
      label: "Perdu",
      value: "lost",
    },
  ],
  clientStatus: [
    { label: "Actif", value: "active" },
    { label: "Inactif", value: "inactive" },
    { label: "Bloqué", value: "suspended" },
    { label: "Perdu", value: "lost" },
  ],
  prospectStatus: [
    { label: "En attente", value: "en-attente" },
    { label: "Traité (en attente de paiement)", value: "treated" },
    { label: "Converti", value: "converti" },
    { label: "Perdu", value: "perdu" },
  ],
  coachClientStatus: [
    { label: "Actif", value: "active", color: "#70ad47" },
    { label: "Inactif", value: "inactive", color: "#ec7c31" },
    { label: "Bloqué", value: "suspended", color: "#fe0100" },
    { label: "Client perdu", value: "lost", color: "#525252" },
    { label: "En attente", value: "en-attente", color: "#ec7c31" },
    {
      label: "Traité (en attente de paiement)",
      value: "treated",
      color: "#67c2ef",
    },
    { label: "Converti", value: "converti", color: "#70ad47" },
    { label: "Prospect perdu", value: "perdu", color: "#525252" },
  ],
  coachStatus: [
    {
      label: "Contacté",
      value: "contact",
      color: "#57b5eb",
    },
    {
      label: "Recrutement",
      value: "recruiting",
      color: "#702f9f",
    },
    {
      label: "Recruté",
      value: "recruited",
      color: "#F8C007",
    },
    {
      label: "Actif",
      value: "active",
      color: "#70ad47",
    },
    {
      label: "Inactif",
      value: "inactive",
      color: "#ec7c31",
    },
    {
      label: "Bloqué",
      value: "suspended",
      color: "#fe0100",
    },
    {
      label: "Import",
      value: "imported",
      color: "#3B5249",
    },
    {
      label: "Liste d'attente",
      value: "waiting-list",
      color: "#94A3B8",
    },
  ],
  coachRanges: [
    {
      label: "1 km",
      value: 1,
    },
    {
      label: "2 km",
      value: 2,
    },
    {
      label: "5 km",
      value: 5,
    },
    {
      label: "10 km",
      value: 10,
    },
    {
      label: "15 km",
      value: 15,
    },
    {
      label: "20 km",
      value: 20,
    },
    {
      label: "25 km",
      value: 25,
    },
    {
      label: "30 km",
      value: 30,
    },
    {
      label: "40 km",
      value: 40,
    },
    {
      label: "50 km",
      value: 50,
    },
    {
      label: "75 km",
      value: 75,
    },
    {
      label: "100 km",
      value: 100,
    },
  ],
  languages: [
    {
      code: "ar",
      name: "Arabic",
      nativeName: "العربية",
    },
    {
      code: "zh",
      name: "Chinese",
      nativeName: "中文 (Zhōngwén), 汉语, 漢語",
    },
    {
      code: "en",
      name: "English",
      nativeName: "English",
    },
    {
      code: "fr",
      name: "French",
      nativeName: "français, langue française",
    },
    {
      code: "de",
      name: "German",
      nativeName: "Deutsch",
    },
    {
      code: "it",
      name: "Italian",
      nativeName: "Italiano",
    },
    {
      code: "ja",
      name: "Japanese",
      nativeName: "日本語 (にほんご／にっぽんご)",
    },
    {
      code: "pt",
      name: "Portuguese",
      nativeName: "Português",
    },
    {
      code: "ru",
      name: "Russian",
      nativeName: "русский язык",
    },
    {
      code: "es",
      name: "Spanish; Castilian",
      nativeName: "español, castellano",
    },
  ],
  codesTypeVoie: [
    { code: "ACH", label: "ANCIEN CHEMIN" },
    { code: "AER", label: "AERODROME" },
    { code: "AERG", label: "AEROGARE" },
    { code: "AGL", label: "AGGLOMERATION" },
    { code: "AIRE", label: "AIRE" },
    { code: "ALL", label: "ALLEE" },
    { code: "ANGL", label: "ANGLE" },
    { code: "ARC", label: "ARCADE" },
    { code: "ART", label: "ANCIENNE ROUTE" },
    { code: "AUT", label: "AUTOROUTE" },
    { code: "AV", label: "AVENUE" },
    { code: "BASE", label: "BASE" },
    { code: "BD", label: "BOULEVARD" },
    { code: "BER", label: "BERGE" },
    { code: "BORD", label: "BORD" },
    { code: "BRE", label: "BARRIERE" },
    { code: "BRG", label: "BOURG" },
    { code: "BRTL", label: "BRETELLE" },
    { code: "BSN", label: "BASSIN" },
    { code: "CAE", label: "CARRIERA" },
    { code: "CALL", label: "CALLE CALLADA" },
    { code: "CAMI", label: "CAMIN" },
    { code: "CAMP", label: "CAMP" },
    { code: "CAN", label: "CANAL" },
    { code: "CAR", label: "CARREFOUR" },
    { code: "CARE", label: "CARRIERE" },
    { code: "CASR", label: "CASERNE" },
    { code: "CC", label: "CHEMIN COMMUNAL" },
    { code: "CD", label: "CHEMIN DEPARTEMENTAL" },
    { code: "CF", label: "CHEMIN FORESTIER" },
    { code: "CHA", label: "CHASSE" },
    { code: "CHE", label: "CHEMIN" },
    { code: "CHEM", label: "CHEMINEMENT" },
    { code: "CHL", label: "CHALET" },
    { code: "CHP", label: "CHAMP" },
    { code: "CHS", label: "CHAUSSEE" },
    { code: "CHT", label: "CHATEAU" },
    { code: "CHV", label: "CHEMIN VICINAL" },
    { code: "CITE", label: "CITE" },
    { code: "CIVE", label: "COURSIVE" },
    { code: "CLOS", label: "CLOS" },
    { code: "CLR", label: "COULOIR" },
    { code: "COIN", label: "COIN" },
    { code: "COL", label: "COL" },
    { code: "COR", label: "CORNICHE" },
    { code: "CORO", label: "CORON" },
    { code: "COTE", label: "COTE" },
    { code: "COUR", label: "COUR" },
    { code: "CPG", label: "CAMPING" },
    { code: "CR", label: "CHEMIN RURAL" },
    { code: "CRS", label: "COURS" },
    { code: "CRX", label: "CROIX" },
    { code: "CTR", label: "CONTOUR" },
    { code: "CTRE", label: "CENTRE" },
    { code: "DARS", label: "DARSE DARCE" },
    { code: "DEVI", label: "DEVIATION" },
    { code: "DIG", label: "DIGUE" },
    { code: "DOM", label: "DOMAINE" },
    { code: "DRA", label: "DRAILLE" },
    { code: "DSC", label: "DESCENTE" },
    { code: "ECA", label: "ECART" },
    { code: "ECL", label: "ECLUSE" },
    { code: "EMBR", label: "EMBRANCHEMENT" },
    { code: "EMP", label: "EMPLACEMENT" },
    { code: "ENC", label: "ENCLOS" },
    { code: "ENV", label: "ENCLAVE" },
    { code: "ESC", label: "ESCALIER" },
    { code: "ESP", label: "ESPLANADE" },
    { code: "ESPA", label: "ESPACE" },
    { code: "ETNG", label: "ETANG" },
    { code: "FD", label: "FOND" },
    { code: "FG", label: "FAUBOURG" },
    { code: "FON", label: "FONTAINE" },
    { code: "FOR", label: "FORET" },
    { code: "FORT", label: "FORT" },
    { code: "FOS", label: "FOSSE" },
    { code: "FRM", label: "FERME" },
    { code: "GAL", label: "GALERIE" },
    { code: "GARE", label: "GARE" },
    { code: "GBD", label: "GRAND BOULEVARD" },
    { code: "GPL", label: "GRANDE PLACE" },
    { code: "GR", label: "GRANDE RUE" },
    { code: "GREV", label: "GREVE" },
    { code: "HAB", label: "HABITATION" },
    { code: "HAM", label: "HAMEAU" },
    { code: "HIP", label: "HIPPODROME" },
    { code: "HLE", label: "HALLE" },
    { code: "HLG", label: "HALAGE" },
    { code: "HLM", label: "HLM" },
    { code: "HTR", label: "HAUTEUR" },
    { code: "ILE", label: "ILE" },
    { code: "ILOT", label: "ILOT" },
    { code: "IMP", label: "IMPASSE" },
    { code: "JARD", label: "JARDIN" },
    { code: "JTE", label: "JETEE" },
    { code: "LAC", label: "LAC" },
    { code: "LEVE", label: "LEVEE" },
    { code: "LICE", label: "LICES" },
    { code: "LIGN", label: "LIGNE" },
    { code: "LOT", label: "LOTISSEMENT" },
    { code: "MAIL", label: "MAIL" },
    { code: "MAIS", label: "MAISON" },
    { code: "MAR", label: "MARCHE" },
    { code: "MARE", label: "MARE" },
    { code: "MAS", label: "MAS" },
    { code: "MNE", label: "MORNE" },
    { code: "MRN", label: "MARINA" },
    { code: "MTE", label: "MONTEE" },
    { code: "NTE", label: "NOUVELLE ROUTE" },
    { code: "PAE", label: "PETITE AVENUE" },
    { code: "PARC", label: "PARC" },
    { code: "PAS", label: "PASSAGE" },
    { code: "PASS", label: "PASSE" },
    { code: "PCH", label: "PETIT CHEMIN" },
    { code: "PCHE", label: "PORCHE" },
    { code: "PHAR", label: "PHARE" },
    { code: "PIST", label: "PISTE" },
    { code: "PKG", label: "PARKING" },
    { code: "PL", label: "PLACE" },
    { code: "PLA", label: "PLACA" },
    { code: "PLAG", label: "PLAGE" },
    { code: "PLAN", label: "PLAN" },
    { code: "PLCI", label: "PLACIS" },
    { code: "PLE", label: "PASSERELLE" },
    { code: "PLN", label: "PLAINE" },
    { code: "PLT", label: "PLATEAU" },
    { code: "PNT", label: "POINTE" },
    { code: "PONT", label: "PONT" },
    { code: "PORQ", label: "PORTIQUE" },
    { code: "PORT", label: "PORT" },
    { code: "POST", label: "POSTE" },
    { code: "POT", label: "POTERNE" },
    { code: "PROM", label: "PROMENADE" },
    { code: "PRT", label: "PETITE ROUTE" },
    { code: "PRV", label: "PARVIS" },
    { code: "PTA", label: "PETITE ALLEE" },
    { code: "PTE", label: "PORTE" },
    { code: "PTR", label: "PETITE RUE" },
    { code: "PTTE", label: "PLACETTE" },
    { code: "QUA", label: "QUARTIER" },
    { code: "QUAI", label: "QUAI" },
    { code: "RAC", label: "RACCOURCI" },
    { code: "REM", label: "REMPART" },
    { code: "RES", label: "RESIDENCE" },
    { code: "RIVE", label: "RIVE" },
    { code: "RLE", label: "RUELLE" },
    { code: "ROC", label: "ROCADE" },
    { code: "RPE", label: "RAMPE" },
    { code: "RPT", label: "ROND-POINT" },
    { code: "RTD", label: "ROTONDE" },
    { code: "RTE", label: "ROUTE" },
    { code: "R", label: "RUE" },
    { code: "RUET", label: "RUETTE" },
    { code: "RUIS", label: "RUISSEAU" },
    { code: "RULT", label: "RUELLETTE" },
    { code: "RVE", label: "RAVINE" },
    { code: "SAS", label: "SAS" },
    { code: "SEN", label: "SENTIER SENTE" },
    { code: "SQ", label: "SQUARE" },
    { code: "STDE", label: "STADE" },
    { code: "TER", label: "TERRE" },
    { code: "TOUR", label: "TOUR" },
    { code: "TPL", label: "TERRE-PLEIN" },
    { code: "TRA", label: "TRAVERSE" },
    { code: "TRAB", label: "TRABOULE" },
    { code: "TRN", label: "TERRAIN" },
    { code: "TRT", label: "TERTRE" },
    { code: "TSSE", label: "TERRASSE" },
    { code: "TUN", label: "TUNNEL" },
    { code: "VAL", label: "VAL" },
    { code: "VALL", label: "VALLON VALLEE" },
    { code: "VC", label: "VOIE COMMUNALE" },
    { code: "VCHE", label: "VIEUX CHEMIN" },
    { code: "VEN", label: "VENELLE" },
    { code: "VGE", label: "VILLAGE" },
    { code: "VIA", label: "VIA" },
    { code: "VIAD", label: "VIADUC" },
    { code: "VIL", label: "VILLE" },
    { code: "VLA", label: "VILLA" },
    { code: "VOIE", label: "VOIE" },
    { code: "VOIR", label: "VOIRIE" },
    { code: "VOUT", label: "VOUTE" },
    { code: "VOY", label: "VOYEUL" },
    { code: "VTE", label: "VIEILLE ROUTE" },
    { code: "ZA", label: "ZA" },
    { code: "ZAC", label: "ZAC" },
    { code: "ZAD", label: "ZAD" },
    { code: "ZI", label: "ZI" },
    { code: "ZONE", label: "ZONE" },
    { code: "ZUP", label: "ZUP" },
  ],
  liveBan: {
    durations: [1, 24],
    reasons: [
      {
        label: "Pollue le chat",
        value: "chat-pollute",
      },
      {
        label: "Comportement inapproprié",
        value: "inappropriate-behavior",
      },
      {
        label: "Problème technique",
        value: "technical-problem",
      },
    ],
  },
  genders: [
    {
      value: "",
      label: "Aucun",
    },
    {
      value: "Homme",
      label: "Homme",
    },
    {
      value: "Femme",
      label: "Femme",
    },
  ],
  sponsorship: {
    status: [
      {
        value: "unauthorized",
        label: "Ce client ne peut pas bénéficier d'une offre de parrainage",
        canBeSponsored: false,
        textColor: "danger",
      },
      {
        value: "discovery-done",
        label: "-10% sur la prochaine commande grâce au parrainage",
        canBeSponsored: false,
        textColor: "success",
      },
      {
        value: "eligible",
        label: "Ce client peut bénéficier d'une offre de parrainage",
        canBeSponsored: true,
        textColor: "success",
      },
    ],
  },
  orderTypes: [
    { label: "Conversion", value: "conversion" },
    { label: "Acquisition", value: "acquisition" },
    { label: "Fidélisation", value: "fidelisation" },
    { label: "Découverte", value: "discovery" },
  ],
  orderStatus: [
    {
      type: "Toutes les commandes",
      status: [
        { label: "Payée", value: "payed" },
        { label: "Partiel", value: "partial" },
        { label: "En attente du paiement", value: "pending" },
        { label: "Avoir", value: "refunded" },
        { label: "Impayé (traité)", value: "unpaid" },
      ],
    },
  ],
  orderStatusFollowed: [
    {
      label: "Converti",
      value: "conversion",
    },
    {
      label: "Fidelisation",
      value: "fidelisation",
    },
    {
      label: "En attente",
      value: "pending",
    },
    {
      label: "Perdu",
      value: "lost_other",
    },
    {
      label: "Perdu coach",
      value: "lost",
    },
  ],
  orderStatusProblematic: [
    {
      label: "Paiements manquants",
      value: "missing-payments",
      isProblematic: true,
    },
    {
      label: "Paiements non planifiés",
      value: "unplanned-payments",
      isProblematic: true,
    },
  ],
  menuStatsOptions: [
    {
      label: "Découverte",
      value: "decouverte",
    },
    {
      label: "Pack Conversion",
      value: "pack-conversion",
    },
    {
      label: "Pack OneShot/Event",
      value: "one-shot",
    },
    {
      label: "Pack Fidélisation",
      value: "pack-fidelisation",
    },
    {
      label: "Pack Autres",
      value: "autres",
    },
  ],
  lettresVoie: [
    {
      label: "Bis",
      value: "B",
    },
    {
      label: "Ter",
      value: "T",
    },
    {
      label: "Quater",
      value: "Q",
    },
    {
      label: "Quinquiès",
      value: "C",
    },
  ],
  phoneCodes: [
    {
      name: "Afghanistan",
      phoneCode: "+93",
      isoCode: "AF",
      flagIcon: "fi fi-af",
      flagIconSquare: "fi fi-af fis",
    },
    {
      name: "Aland Islands",
      phoneCode: "+358",
      isoCode: "AX",
      flagIcon: "fi fi-ax",
      flagIconSquare: "fi fi-ax fis",
    },
    {
      name: "Albania",
      phoneCode: "+355",
      isoCode: "AL",
      flagIcon: "fi fi-al",
      flagIconSquare: "fi fi-al fis",
    },
    {
      name: "Algeria",
      phoneCode: "+213",
      isoCode: "DZ",
      flagIcon: "fi fi-dz",
      flagIconSquare: "fi fi-dz fis",
    },
    {
      name: "American Samoa",
      phoneCode: "+1684",
      isoCode: "AS",
      flagIcon: "fi fi-as",
      flagIconSquare: "fi fi-as fis",
    },
    {
      name: "Andorra",
      phoneCode: "+376",
      isoCode: "AD",
      flagIcon: "fi fi-ad",
      flagIconSquare: "fi fi-ad fis",
    },
    {
      name: "Angola",
      phoneCode: "+244",
      isoCode: "AO",
      flagIcon: "fi fi-ao",
      flagIconSquare: "fi fi-ao fis",
    },
    {
      name: "Anguilla",
      phoneCode: "+1264",
      isoCode: "AI",
      flagIcon: "fi fi-ai",
      flagIconSquare: "fi fi-ai fis",
    },
    {
      name: "Antarctica",
      phoneCode: "+672",
      isoCode: "AQ",
      flagIcon: "fi fi-aq",
      flagIconSquare: "fi fi-aq fis",
    },
    {
      name: "Antigua and Barbuda",
      phoneCode: "+1268",
      isoCode: "AG",
      flagIcon: "fi fi-ag",
      flagIconSquare: "fi fi-ag fis",
    },
    {
      name: "Argentina",
      phoneCode: "+54",
      isoCode: "AR",
      flagIcon: "fi fi-ar",
      flagIconSquare: "fi fi-ar fis",
    },
    {
      name: "Armenia",
      phoneCode: "+374",
      isoCode: "AM",
      flagIcon: "fi fi-am",
      flagIconSquare: "fi fi-am fis",
    },
    {
      name: "Aruba",
      phoneCode: "+297",
      isoCode: "AW",
      flagIcon: "fi fi-aw",
      flagIconSquare: "fi fi-aw fis",
    },
    {
      name: "Australia",
      phoneCode: "+61",
      isoCode: "AU",
      flagIcon: "fi fi-au",
      flagIconSquare: "fi fi-au fis",
    },
    {
      name: "Austria",
      phoneCode: "+43",
      isoCode: "AT",
      flagIcon: "fi fi-at",
      flagIconSquare: "fi fi-at fis",
    },
    {
      name: "Azerbaijan",
      phoneCode: "+994",
      isoCode: "AZ",
      flagIcon: "fi fi-az",
      flagIconSquare: "fi fi-az fis",
    },
    {
      name: "Bahamas",
      phoneCode: "+1242",
      isoCode: "BS",
      flagIcon: "fi fi-bs",
      flagIconSquare: "fi fi-bs fis",
    },
    {
      name: "Bahrain",
      phoneCode: "+973",
      isoCode: "BH",
      flagIcon: "fi fi-bh",
      flagIconSquare: "fi fi-bh fis",
    },
    {
      name: "Bangladesh",
      phoneCode: "+880",
      isoCode: "BD",
      flagIcon: "fi fi-bd",
      flagIconSquare: "fi fi-bd fis",
    },
    {
      name: "Barbados",
      phoneCode: "+1246",
      isoCode: "BB",
      flagIcon: "fi fi-bb",
      flagIconSquare: "fi fi-bb fis",
    },
    {
      name: "Belarus",
      phoneCode: "+375",
      isoCode: "BY",
      flagIcon: "fi fi-by",
      flagIconSquare: "fi fi-by fis",
    },
    {
      name: "Belgium",
      phoneCode: "+32",
      isoCode: "BE",
      flagIcon: "fi fi-be",
      flagIconSquare: "fi fi-be fis",
    },
    {
      name: "Belize",
      phoneCode: "+501",
      isoCode: "BZ",
      flagIcon: "fi fi-bz",
      flagIconSquare: "fi fi-bz fis",
    },
    {
      name: "Benin",
      phoneCode: "+229",
      isoCode: "BJ",
      flagIcon: "fi fi-bj",
      flagIconSquare: "fi fi-bj fis",
    },
    {
      name: "Bermuda",
      phoneCode: "+1441",
      isoCode: "BM",
      flagIcon: "fi fi-bm",
      flagIconSquare: "fi fi-bm fis",
    },
    {
      name: "Bhutan",
      phoneCode: "+975",
      isoCode: "BT",
      flagIcon: "fi fi-bt",
      flagIconSquare: "fi fi-bt fis",
    },
    {
      name: "Bolivia, Plurinational State of",
      phoneCode: "+591",
      isoCode: "BO",
      flagIcon: "fi fi-bo",
      flagIconSquare: "fi fi-bo fis",
    },
    {
      name: "Bosnia and Herzegovina",
      phoneCode: "+387",
      isoCode: "BA",
      flagIcon: "fi fi-ba",
      flagIconSquare: "fi fi-ba fis",
    },
    {
      name: "Botswana",
      phoneCode: "+267",
      isoCode: "BW",
      flagIcon: "fi fi-bw",
      flagIconSquare: "fi fi-bw fis",
    },
    {
      name: "Brazil",
      phoneCode: "+55",
      isoCode: "BR",
      flagIcon: "fi fi-br",
      flagIconSquare: "fi fi-br fis",
    },
    {
      name: "British Indian Ocean Territory",
      phoneCode: "+246",
      isoCode: "IO",
      flagIcon: "fi fi-io",
      flagIconSquare: "fi fi-io fis",
    },
    {
      name: "Brunei Darussalam",
      phoneCode: "+673",
      isoCode: "BN",
      flagIcon: "fi fi-bn",
      flagIconSquare: "fi fi-bn fis",
    },
    {
      name: "Bulgaria",
      phoneCode: "+359",
      isoCode: "BG",
      flagIcon: "fi fi-bg",
      flagIconSquare: "fi fi-bg fis",
    },
    {
      name: "Burkina Faso",
      phoneCode: "+226",
      isoCode: "BF",
      flagIcon: "fi fi-bf",
      flagIconSquare: "fi fi-bf fis",
    },
    {
      name: "Burundi",
      phoneCode: "+257",
      isoCode: "BI",
      flagIcon: "fi fi-bi",
      flagIconSquare: "fi fi-bi fis",
    },
    {
      name: "Cambodia",
      phoneCode: "+855",
      isoCode: "KH",
      flagIcon: "fi fi-kh",
      flagIconSquare: "fi fi-kh fis",
    },
    {
      name: "Cameroon",
      phoneCode: "+237",
      isoCode: "CM",
      flagIcon: "fi fi-cm",
      flagIconSquare: "fi fi-cm fis",
    },
    {
      name: "Canada",
      phoneCode: "+1",
      isoCode: "CA",
      flagIcon: "fi fi-ca",
      flagIconSquare: "fi fi-ca fis",
    },
    {
      name: "Cape Verde",
      phoneCode: "+238",
      isoCode: "CV",
      flagIcon: "fi fi-cv",
      flagIconSquare: "fi fi-cv fis",
    },
    {
      name: "Cayman Islands",
      phoneCode: "+ 345",
      isoCode: "KY",
      flagIcon: "fi fi-ky",
      flagIconSquare: "fi fi-ky fis",
    },
    {
      name: "Central African Republic",
      phoneCode: "+236",
      isoCode: "CF",
      flagIcon: "fi fi-cf",
      flagIconSquare: "fi fi-cf fis",
    },
    {
      name: "Chad",
      phoneCode: "+235",
      isoCode: "TD",
      flagIcon: "fi fi-td",
      flagIconSquare: "fi fi-td fis",
    },
    {
      name: "Chile",
      phoneCode: "+56",
      isoCode: "CL",
      flagIcon: "fi fi-cl",
      flagIconSquare: "fi fi-cl fis",
    },
    {
      name: "China",
      phoneCode: "+86",
      isoCode: "CN",
      flagIcon: "fi fi-cn",
      flagIconSquare: "fi fi-cn fis",
    },
    {
      name: "Christmas Island",
      phoneCode: "+61",
      isoCode: "CX",
      flagIcon: "fi fi-cx",
      flagIconSquare: "fi fi-cx fis",
    },
    {
      name: "Cocos (Keeling) Islands",
      phoneCode: "+61",
      isoCode: "CC",
      flagIcon: "fi fi-cc",
      flagIconSquare: "fi fi-cc fis",
    },
    {
      name: "Colombia",
      phoneCode: "+57",
      isoCode: "CO",
      flagIcon: "fi fi-co",
      flagIconSquare: "fi fi-co fis",
    },
    {
      name: "Comoros",
      phoneCode: "+269",
      isoCode: "KM",
      flagIcon: "fi fi-km",
      flagIconSquare: "fi fi-km fis",
    },
    {
      name: "Congo",
      phoneCode: "+242",
      isoCode: "CG",
      flagIcon: "fi fi-cg",
      flagIconSquare: "fi fi-cg fis",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      phoneCode: "+243",
      isoCode: "CD",
      flagIcon: "fi fi-cd",
      flagIconSquare: "fi fi-cd fis",
    },
    {
      name: "Cook Islands",
      phoneCode: "+682",
      isoCode: "CK",
      flagIcon: "fi fi-ck",
      flagIconSquare: "fi fi-ck fis",
    },
    {
      name: "Costa Rica",
      phoneCode: "+506",
      isoCode: "CR",
      flagIcon: "fi fi-cr",
      flagIconSquare: "fi fi-cr fis",
    },
    {
      name: "Cote d'Ivoire",
      phoneCode: "+225",
      isoCode: "CI",
      flagIcon: "fi fi-ci",
      flagIconSquare: "fi fi-ci fis",
    },
    {
      name: "Croatia",
      phoneCode: "+385",
      isoCode: "HR",
      flagIcon: "fi fi-hr",
      flagIconSquare: "fi fi-hr fis",
    },
    {
      name: "Cuba",
      phoneCode: "+53",
      isoCode: "CU",
      flagIcon: "fi fi-cu",
      flagIconSquare: "fi fi-cu fis",
    },
    {
      name: "Cyprus",
      phoneCode: "+357",
      isoCode: "CY",
      flagIcon: "fi fi-cy",
      flagIconSquare: "fi fi-cy fis",
    },
    {
      name: "Czech Republic",
      phoneCode: "+420",
      isoCode: "CZ",
      flagIcon: "fi fi-cz",
      flagIconSquare: "fi fi-cz fis",
    },
    {
      name: "Denmark",
      phoneCode: "+45",
      isoCode: "DK",
      flagIcon: "fi fi-dk",
      flagIconSquare: "fi fi-dk fis",
    },
    {
      name: "Djibouti",
      phoneCode: "+253",
      isoCode: "DJ",
      flagIcon: "fi fi-dj",
      flagIconSquare: "fi fi-dj fis",
    },
    {
      name: "Dominica",
      phoneCode: "+1767",
      isoCode: "DM",
      flagIcon: "fi fi-dm",
      flagIconSquare: "fi fi-dm fis",
    },
    {
      name: "Dominican Republic",
      phoneCode: "+1849",
      isoCode: "DO",
      flagIcon: "fi fi-do",
      flagIconSquare: "fi fi-do fis",
    },
    {
      name: "Ecuador",
      phoneCode: "+593",
      isoCode: "EC",
      flagIcon: "fi fi-ec",
      flagIconSquare: "fi fi-ec fis",
    },
    {
      name: "Egypt",
      phoneCode: "+20",
      isoCode: "EG",
      flagIcon: "fi fi-eg",
      flagIconSquare: "fi fi-eg fis",
    },
    {
      name: "El Salvador",
      phoneCode: "+503",
      isoCode: "SV",
      flagIcon: "fi fi-sv",
      flagIconSquare: "fi fi-sv fis",
    },
    {
      name: "Equatorial Guinea",
      phoneCode: "+240",
      isoCode: "GQ",
      flagIcon: "fi fi-gq",
      flagIconSquare: "fi fi-gq fis",
    },
    {
      name: "Eritrea",
      phoneCode: "+291",
      isoCode: "ER",
      flagIcon: "fi fi-er",
      flagIconSquare: "fi fi-er fis",
    },
    {
      name: "Estonia",
      phoneCode: "+372",
      isoCode: "EE",
      flagIcon: "fi fi-ee",
      flagIconSquare: "fi fi-ee fis",
    },
    {
      name: "Ethiopia",
      phoneCode: "+251",
      isoCode: "ET",
      flagIcon: "fi fi-et",
      flagIconSquare: "fi fi-et fis",
    },
    {
      name: "Falkland Islands (Malvinas)",
      phoneCode: "+500",
      isoCode: "FK",
      flagIcon: "fi fi-fk",
      flagIconSquare: "fi fi-fk fis",
    },
    {
      name: "Faroe Islands",
      phoneCode: "+298",
      isoCode: "FO",
      flagIcon: "fi fi-fo",
      flagIconSquare: "fi fi-fo fis",
    },
    {
      name: "Fiji",
      phoneCode: "+679",
      isoCode: "FJ",
      flagIcon: "fi fi-fj",
      flagIconSquare: "fi fi-fj fis",
    },
    {
      name: "Finland",
      phoneCode: "+358",
      isoCode: "FI",
      flagIcon: "fi fi-fi",
      flagIconSquare: "fi fi-fi fis",
    },
    {
      name: "France",
      phoneCode: "+33",
      isoCode: "FR",
      flagIcon: "fi fi-fr",
      flagIconSquare: "fi fi-fr fis",
    },
    {
      name: "French Guiana",
      phoneCode: "+594",
      isoCode: "GF",
      flagIcon: "fi fi-gf",
      flagIconSquare: "fi fi-gf fis",
    },
    {
      name: "French Polynesia",
      phoneCode: "+689",
      isoCode: "PF",
      flagIcon: "fi fi-pf",
      flagIconSquare: "fi fi-pf fis",
    },
    {
      name: "Gabon",
      phoneCode: "+241",
      isoCode: "GA",
      flagIcon: "fi fi-ga",
      flagIconSquare: "fi fi-ga fis",
    },
    {
      name: "Gambia",
      phoneCode: "+220",
      isoCode: "GM",
      flagIcon: "fi fi-gm",
      flagIconSquare: "fi fi-gm fis",
    },
    {
      name: "Georgia",
      phoneCode: "+995",
      isoCode: "GE",
      flagIcon: "fi fi-ge",
      flagIconSquare: "fi fi-ge fis",
    },
    {
      name: "Germany",
      phoneCode: "+49",
      isoCode: "DE",
      flagIcon: "fi fi-de",
      flagIconSquare: "fi fi-de fis",
    },
    {
      name: "Ghana",
      phoneCode: "+233",
      isoCode: "GH",
      flagIcon: "fi fi-gh",
      flagIconSquare: "fi fi-gh fis",
    },
    {
      name: "Gibraltar",
      phoneCode: "+350",
      isoCode: "GI",
      flagIcon: "fi fi-gi",
      flagIconSquare: "fi fi-gi fis",
    },
    {
      name: "Greece",
      phoneCode: "+30",
      isoCode: "GR",
      flagIcon: "fi fi-gr",
      flagIconSquare: "fi fi-gr fis",
    },
    {
      name: "Greenland",
      phoneCode: "+299",
      isoCode: "GL",
      flagIcon: "fi fi-gl",
      flagIconSquare: "fi fi-gl fis",
    },
    {
      name: "Grenada",
      phoneCode: "+1473",
      isoCode: "GD",
      flagIcon: "fi fi-gd",
      flagIconSquare: "fi fi-gd fis",
    },
    {
      name: "Guadeloupe",
      phoneCode: "+590",
      isoCode: "GP",
      flagIcon: "fi fi-gp",
      flagIconSquare: "fi fi-gp fis",
    },
    {
      name: "Guam",
      phoneCode: "+1671",
      isoCode: "GU",
      flagIcon: "fi fi-gu",
      flagIconSquare: "fi fi-gu fis",
    },
    {
      name: "Guatemala",
      phoneCode: "+502",
      isoCode: "GT",
      flagIcon: "fi fi-gt",
      flagIconSquare: "fi fi-gt fis",
    },
    {
      name: "Guernsey",
      phoneCode: "+44",
      isoCode: "GG",
      flagIcon: "fi fi-gg",
      flagIconSquare: "fi fi-gg fis",
    },
    {
      name: "Guinea",
      phoneCode: "+224",
      isoCode: "GN",
      flagIcon: "fi fi-gn",
      flagIconSquare: "fi fi-gn fis",
    },
    {
      name: "Guinea-Bissau",
      phoneCode: "+245",
      isoCode: "GW",
      flagIcon: "fi fi-gw",
      flagIconSquare: "fi fi-gw fis",
    },
    {
      name: "Guyana",
      phoneCode: "+595",
      isoCode: "GY",
      flagIcon: "fi fi-gy",
      flagIconSquare: "fi fi-gy fis",
    },
    {
      name: "Haiti",
      phoneCode: "+509",
      isoCode: "HT",
      flagIcon: "fi fi-ht",
      flagIconSquare: "fi fi-ht fis",
    },
    {
      name: "Holy See (Vatican City State)",
      phoneCode: "+379",
      isoCode: "VA",
      flagIcon: "fi fi-va",
      flagIconSquare: "fi fi-va fis",
    },
    {
      name: "Honduras",
      phoneCode: "+504",
      isoCode: "HN",
      flagIcon: "fi fi-hn",
      flagIconSquare: "fi fi-hn fis",
    },
    {
      name: "Hong Kong",
      phoneCode: "+852",
      isoCode: "HK",
      flagIcon: "fi fi-hk",
      flagIconSquare: "fi fi-hk fis",
    },
    {
      name: "Hungary",
      phoneCode: "+36",
      isoCode: "HU",
      flagIcon: "fi fi-hu",
      flagIconSquare: "fi fi-hu fis",
    },
    {
      name: "Iceland",
      phoneCode: "+354",
      isoCode: "IS",
      flagIcon: "fi fi-is",
      flagIconSquare: "fi fi-is fis",
    },
    {
      name: "India",
      phoneCode: "+91",
      isoCode: "IN",
      flagIcon: "fi fi-in",
      flagIconSquare: "fi fi-in fis",
    },
    {
      name: "Indonesia",
      phoneCode: "+62",
      isoCode: "ID",
      flagIcon: "fi fi-id",
      flagIconSquare: "fi fi-id fis",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      phoneCode: "+98",
      isoCode: "IR",
      flagIcon: "fi fi-ir",
      flagIconSquare: "fi fi-ir fis",
    },
    {
      name: "Iraq",
      phoneCode: "+964",
      isoCode: "IQ",
      flagIcon: "fi fi-iq",
      flagIconSquare: "fi fi-iq fis",
    },
    {
      name: "Ireland",
      phoneCode: "+353",
      isoCode: "IE",
      flagIcon: "fi fi-ie",
      flagIconSquare: "fi fi-ie fis",
    },
    {
      name: "Isle of Man",
      phoneCode: "+44",
      isoCode: "IM",
      flagIcon: "fi fi-im",
      flagIconSquare: "fi fi-im fis",
    },
    {
      name: "Israel",
      phoneCode: "+972",
      isoCode: "IL",
      flagIcon: "fi fi-il",
      flagIconSquare: "fi fi-il fis",
    },
    {
      name: "Italy",
      phoneCode: "+39",
      isoCode: "IT",
      flagIcon: "fi fi-it",
      flagIconSquare: "fi fi-it fis",
    },
    {
      name: "Jamaica",
      phoneCode: "+1876",
      isoCode: "JM",
      flagIcon: "fi fi-jm",
      flagIconSquare: "fi fi-jm fis",
    },
    {
      name: "Japan",
      phoneCode: "+81",
      isoCode: "JP",
      flagIcon: "fi fi-jp",
      flagIconSquare: "fi fi-jp fis",
    },
    {
      name: "Jersey",
      phoneCode: "+44",
      isoCode: "JE",
      flagIcon: "fi fi-je",
      flagIconSquare: "fi fi-je fis",
    },
    {
      name: "Jordan",
      phoneCode: "+962",
      isoCode: "JO",
      flagIcon: "fi fi-jo",
      flagIconSquare: "fi fi-jo fis",
    },
    {
      name: "Kazakhstan",
      phoneCode: "+77",
      isoCode: "KZ",
      flagIcon: "fi fi-kz",
      flagIconSquare: "fi fi-kz fis",
    },
    {
      name: "Kenya",
      phoneCode: "+254",
      isoCode: "KE",
      flagIcon: "fi fi-ke",
      flagIconSquare: "fi fi-ke fis",
    },
    {
      name: "Kiribati",
      phoneCode: "+686",
      isoCode: "KI",
      flagIcon: "fi fi-ki",
      flagIconSquare: "fi fi-ki fis",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      phoneCode: "+850",
      isoCode: "KP",
      flagIcon: "fi fi-kp",
      flagIconSquare: "fi fi-kp fis",
    },
    {
      name: "Korea, Republic of South Korea",
      phoneCode: "+82",
      isoCode: "KR",
      flagIcon: "fi fi-kr",
      flagIconSquare: "fi fi-kr fis",
    },
    {
      name: "Kuwait",
      phoneCode: "+965",
      isoCode: "KW",
      flagIcon: "fi fi-kw",
      flagIconSquare: "fi fi-kw fis",
    },
    {
      name: "Kyrgyzstan",
      phoneCode: "+996",
      isoCode: "KG",
      flagIcon: "fi fi-kg",
      flagIconSquare: "fi fi-kg fis",
    },
    {
      name: "Laos",
      phoneCode: "+856",
      isoCode: "LA",
      flagIcon: "fi fi-la",
      flagIconSquare: "fi fi-la fis",
    },
    {
      name: "Latvia",
      phoneCode: "+371",
      isoCode: "LV",
      flagIcon: "fi fi-lv",
      flagIconSquare: "fi fi-lv fis",
    },
    {
      name: "Lebanon",
      phoneCode: "+961",
      isoCode: "LB",
      flagIcon: "fi fi-lb",
      flagIconSquare: "fi fi-lb fis",
    },
    {
      name: "Lesotho",
      phoneCode: "+266",
      isoCode: "LS",
      flagIcon: "fi fi-ls",
      flagIconSquare: "fi fi-ls fis",
    },
    {
      name: "Liberia",
      phoneCode: "+231",
      isoCode: "LR",
      flagIcon: "fi fi-lr",
      flagIconSquare: "fi fi-lr fis",
    },
    {
      name: "Libyan Arab Jamahiriya",
      phoneCode: "+218",
      isoCode: "LY",
      flagIcon: "fi fi-ly",
      flagIconSquare: "fi fi-ly fis",
    },
    {
      name: "Liechtenstein",
      phoneCode: "+423",
      isoCode: "LI",
      flagIcon: "fi fi-li",
      flagIconSquare: "fi fi-li fis",
    },
    {
      name: "Lithuania",
      phoneCode: "+370",
      isoCode: "LT",
      flagIcon: "fi fi-lt",
      flagIconSquare: "fi fi-lt fis",
    },
    {
      name: "Luxembourg",
      phoneCode: "+352",
      isoCode: "LU",
      flagIcon: "fi fi-lu",
      flagIconSquare: "fi fi-lu fis",
    },
    {
      name: "Macao",
      phoneCode: "+853",
      isoCode: "MO",
      flagIcon: "fi fi-mo",
      flagIconSquare: "fi fi-mo fis",
    },
    {
      name: "Macedonia",
      phoneCode: "+389",
      isoCode: "MK",
      flagIcon: "fi fi-mk",
      flagIconSquare: "fi fi-mk fis",
    },
    {
      name: "Madagascar",
      phoneCode: "+261",
      isoCode: "MG",
      flagIcon: "fi fi-mg",
      flagIconSquare: "fi fi-mg fis",
    },
    {
      name: "Malawi",
      phoneCode: "+265",
      isoCode: "MW",
      flagIcon: "fi fi-mw",
      flagIconSquare: "fi fi-mw fis",
    },
    {
      name: "Malaysia",
      phoneCode: "+60",
      isoCode: "MY",
      flagIcon: "fi fi-my",
      flagIconSquare: "fi fi-my fis",
    },
    {
      name: "Maldives",
      phoneCode: "+960",
      isoCode: "MV",
      flagIcon: "fi fi-mv",
      flagIconSquare: "fi fi-mv fis",
    },
    {
      name: "Mali",
      phoneCode: "+223",
      isoCode: "ML",
      flagIcon: "fi fi-ml",
      flagIconSquare: "fi fi-ml fis",
    },
    {
      name: "Malta",
      phoneCode: "+356",
      isoCode: "MT",
      flagIcon: "fi fi-mt",
      flagIconSquare: "fi fi-mt fis",
    },
    {
      name: "Marshall Islands",
      phoneCode: "+692",
      isoCode: "MH",
      flagIcon: "fi fi-mh",
      flagIconSquare: "fi fi-mh fis",
    },
    {
      name: "Martinique",
      phoneCode: "+596",
      isoCode: "MQ",
      flagIcon: "fi fi-mq",
      flagIconSquare: "fi fi-mq fis",
    },
    {
      name: "Mauritania",
      phoneCode: "+222",
      isoCode: "MR",
      flagIcon: "fi fi-mr",
      flagIconSquare: "fi fi-mr fis",
    },
    {
      name: "Mauritius",
      phoneCode: "+230",
      isoCode: "MU",
      flagIcon: "fi fi-mu",
      flagIconSquare: "fi fi-mu fis",
    },
    {
      name: "Mayotte",
      phoneCode: "+262",
      isoCode: "YT",
      flagIcon: "fi fi-yt",
      flagIconSquare: "fi fi-yt fis",
    },
    {
      name: "Mexico",
      phoneCode: "+52",
      isoCode: "MX",
      flagIcon: "fi fi-mx",
      flagIconSquare: "fi fi-mx fis",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      phoneCode: "+691",
      isoCode: "FM",
      flagIcon: "fi fi-fm",
      flagIconSquare: "fi fi-fm fis",
    },
    {
      name: "Moldova",
      phoneCode: "+373",
      isoCode: "MD",
      flagIcon: "fi fi-md",
      flagIconSquare: "fi fi-md fis",
    },
    {
      name: "Monaco",
      phoneCode: "+377",
      isoCode: "MC",
      flagIcon: "fi fi-mc",
      flagIconSquare: "fi fi-mc fis",
    },
    {
      name: "Mongolia",
      phoneCode: "+976",
      isoCode: "MN",
      flagIcon: "fi fi-mn",
      flagIconSquare: "fi fi-mn fis",
    },
    {
      name: "Montenegro",
      phoneCode: "+382",
      isoCode: "ME",
      flagIcon: "fi fi-me",
      flagIconSquare: "fi fi-me fis",
    },
    {
      name: "Montserrat",
      phoneCode: "+1664",
      isoCode: "MS",
      flagIcon: "fi fi-ms",
      flagIconSquare: "fi fi-ms fis",
    },
    {
      name: "Morocco",
      phoneCode: "+212",
      isoCode: "MA",
      flagIcon: "fi fi-ma",
      flagIconSquare: "fi fi-ma fis",
    },
    {
      name: "Mozambique",
      phoneCode: "+258",
      isoCode: "MZ",
      flagIcon: "fi fi-mz",
      flagIconSquare: "fi fi-mz fis",
    },
    {
      name: "Myanmar",
      phoneCode: "+95",
      isoCode: "MM",
      flagIcon: "fi fi-mm",
      flagIconSquare: "fi fi-mm fis",
    },
    {
      name: "Namibia",
      phoneCode: "+264",
      isoCode: "NA",
      flagIcon: "fi fi-na",
      flagIconSquare: "fi fi-na fis",
    },
    {
      name: "Nauru",
      phoneCode: "+674",
      isoCode: "NR",
      flagIcon: "fi fi-nr",
      flagIconSquare: "fi fi-nr fis",
    },
    {
      name: "Nepal",
      phoneCode: "+977",
      isoCode: "NP",
      flagIcon: "fi fi-np",
      flagIconSquare: "fi fi-np fis",
    },
    {
      name: "Netherlands",
      phoneCode: "+31",
      isoCode: "NL",
      flagIcon: "fi fi-nl",
      flagIconSquare: "fi fi-nl fis",
    },
    {
      name: "Netherlands Antilles",
      phoneCode: "+599",
      isoCode: "AN",
      flagIcon: "fi fi-an",
      flagIconSquare: "fi fi-an fis",
    },
    {
      name: "New Caledonia",
      phoneCode: "+687",
      isoCode: "NC",
      flagIcon: "fi fi-nc",
      flagIconSquare: "fi fi-nc fis",
    },
    {
      name: "New Zealand",
      phoneCode: "+64",
      isoCode: "NZ",
      flagIcon: "fi fi-nz",
      flagIconSquare: "fi fi-nz fis",
    },
    {
      name: "Nicaragua",
      phoneCode: "+505",
      isoCode: "NI",
      flagIcon: "fi fi-ni",
      flagIconSquare: "fi fi-ni fis",
    },
    {
      name: "Niger",
      phoneCode: "+227",
      isoCode: "NE",
      flagIcon: "fi fi-ne",
      flagIconSquare: "fi fi-ne fis",
    },
    {
      name: "Nigeria",
      phoneCode: "+234",
      isoCode: "NG",
      flagIcon: "fi fi-ng",
      flagIconSquare: "fi fi-ng fis",
    },
    {
      name: "Niue",
      phoneCode: "+683",
      isoCode: "NU",
      flagIcon: "fi fi-nu",
      flagIconSquare: "fi fi-nu fis",
    },
    {
      name: "Norfolk Island",
      phoneCode: "+672",
      isoCode: "NF",
      flagIcon: "fi fi-nf",
      flagIconSquare: "fi fi-nf fis",
    },
    {
      name: "Northern Mariana Islands",
      phoneCode: "+1670",
      isoCode: "MP",
      flagIcon: "fi fi-mp",
      flagIconSquare: "fi fi-mp fis",
    },
    {
      name: "Norway",
      phoneCode: "+47",
      isoCode: "NO",
      flagIcon: "fi fi-no",
      flagIconSquare: "fi fi-no fis",
    },
    {
      name: "Oman",
      phoneCode: "+968",
      isoCode: "OM",
      flagIcon: "fi fi-om",
      flagIconSquare: "fi fi-om fis",
    },
    {
      name: "Pakistan",
      phoneCode: "+92",
      isoCode: "PK",
      flagIcon: "fi fi-pk",
      flagIconSquare: "fi fi-pk fis",
    },
    {
      name: "Palau",
      phoneCode: "+680",
      isoCode: "PW",
      flagIcon: "fi fi-pw",
      flagIconSquare: "fi fi-pw fis",
    },
    {
      name: "Palestinian Territory, Occupied",
      phoneCode: "+970",
      isoCode: "PS",
      flagIcon: "fi fi-ps",
      flagIconSquare: "fi fi-ps fis",
    },
    {
      name: "Panama",
      phoneCode: "+507",
      isoCode: "PA",
      flagIcon: "fi fi-pa",
      flagIconSquare: "fi fi-pa fis",
    },
    {
      name: "Papua New Guinea",
      phoneCode: "+675",
      isoCode: "PG",
      flagIcon: "fi fi-pg",
      flagIconSquare: "fi fi-pg fis",
    },
    {
      name: "Paraguay",
      phoneCode: "+595",
      isoCode: "PY",
      flagIcon: "fi fi-py",
      flagIconSquare: "fi fi-py fis",
    },
    {
      name: "Peru",
      phoneCode: "+51",
      isoCode: "PE",
      flagIcon: "fi fi-pe",
      flagIconSquare: "fi fi-pe fis",
    },
    {
      name: "Philippines",
      phoneCode: "+63",
      isoCode: "PH",
      flagIcon: "fi fi-ph",
      flagIconSquare: "fi fi-ph fis",
    },
    {
      name: "Pitcairn",
      phoneCode: "+872",
      isoCode: "PN",
      flagIcon: "fi fi-pn",
      flagIconSquare: "fi fi-pn fis",
    },
    {
      name: "Poland",
      phoneCode: "+48",
      isoCode: "PL",
      flagIcon: "fi fi-pl",
      flagIconSquare: "fi fi-pl fis",
    },
    {
      name: "Portugal",
      phoneCode: "+351",
      isoCode: "PT",
      flagIcon: "fi fi-pt",
      flagIconSquare: "fi fi-pt fis",
    },
    {
      name: "Puerto Rico",
      phoneCode: "+1939",
      isoCode: "PR",
      flagIcon: "fi fi-pr",
      flagIconSquare: "fi fi-pr fis",
    },
    {
      name: "Qatar",
      phoneCode: "+974",
      isoCode: "QA",
      flagIcon: "fi fi-qa",
      flagIconSquare: "fi fi-qa fis",
    },
    {
      name: "Romania",
      phoneCode: "+40",
      isoCode: "RO",
      flagIcon: "fi fi-ro",
      flagIconSquare: "fi fi-ro fis",
    },
    {
      name: "Russia",
      phoneCode: "+7",
      isoCode: "RU",
      flagIcon: "fi fi-ru",
      flagIconSquare: "fi fi-ru fis",
    },
    {
      name: "Rwanda",
      phoneCode: "+250",
      isoCode: "RW",
      flagIcon: "fi fi-rw",
      flagIconSquare: "fi fi-rw fis",
    },
    {
      name: "Reunion",
      phoneCode: "+262",
      isoCode: "RE",
      flagIcon: "fi fi-re",
      flagIconSquare: "fi fi-re fis",
    },
    {
      name: "Saint Barthelemy",
      phoneCode: "+590",
      isoCode: "BL",
      flagIcon: "fi fi-bl",
      flagIconSquare: "fi fi-bl fis",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      phoneCode: "+290",
      isoCode: "SH",
      flagIcon: "fi fi-sh",
      flagIconSquare: "fi fi-sh fis",
    },
    {
      name: "Saint Kitts and Nevis",
      phoneCode: "+1869",
      isoCode: "KN",
      flagIcon: "fi fi-kn",
      flagIconSquare: "fi fi-kn fis",
    },
    {
      name: "Saint Lucia",
      phoneCode: "+1758",
      isoCode: "LC",
      flagIcon: "fi fi-lc",
      flagIconSquare: "fi fi-lc fis",
    },
    {
      name: "Saint Martin",
      phoneCode: "+590",
      isoCode: "MF",
      flagIcon: "fi fi-mf",
      flagIconSquare: "fi fi-mf fis",
    },
    {
      name: "Saint Pierre and Miquelon",
      phoneCode: "+508",
      isoCode: "PM",
      flagIcon: "fi fi-pm",
      flagIconSquare: "fi fi-pm fis",
    },
    {
      name: "Saint Vincent and the Grenadines",
      phoneCode: "+1784",
      isoCode: "VC",
      flagIcon: "fi fi-vc",
      flagIconSquare: "fi fi-vc fis",
    },
    {
      name: "Samoa",
      phoneCode: "+685",
      isoCode: "WS",
      flagIcon: "fi fi-ws",
      flagIconSquare: "fi fi-ws fis",
    },
    {
      name: "San Marino",
      phoneCode: "+378",
      isoCode: "SM",
      flagIcon: "fi fi-sm",
      flagIconSquare: "fi fi-sm fis",
    },
    {
      name: "Sao Tome and Principe",
      phoneCode: "+239",
      isoCode: "ST",
      flagIcon: "fi fi-st",
      flagIconSquare: "fi fi-st fis",
    },
    {
      name: "Saudi Arabia",
      phoneCode: "+966",
      isoCode: "SA",
      flagIcon: "fi fi-sa",
      flagIconSquare: "fi fi-sa fis",
    },
    {
      name: "Senegal",
      phoneCode: "+221",
      isoCode: "SN",
      flagIcon: "fi fi-sn",
      flagIconSquare: "fi fi-sn fis",
    },
    {
      name: "Serbia",
      phoneCode: "+381",
      isoCode: "RS",
      flagIcon: "fi fi-rs",
      flagIconSquare: "fi fi-rs fis",
    },
    {
      name: "Seychelles",
      phoneCode: "+248",
      isoCode: "SC",
      flagIcon: "fi fi-sc",
      flagIconSquare: "fi fi-sc fis",
    },
    {
      name: "Sierra Leone",
      phoneCode: "+232",
      isoCode: "SL",
      flagIcon: "fi fi-sl",
      flagIconSquare: "fi fi-sl fis",
    },
    {
      name: "Singapore",
      phoneCode: "+65",
      isoCode: "SG",
      flagIcon: "fi fi-sg",
      flagIconSquare: "fi fi-sg fis",
    },
    {
      name: "Slovakia",
      phoneCode: "+421",
      isoCode: "SK",
      flagIcon: "fi fi-sk",
      flagIconSquare: "fi fi-sk fis",
    },
    {
      name: "Slovenia",
      phoneCode: "+386",
      isoCode: "SI",
      flagIcon: "fi fi-si",
      flagIconSquare: "fi fi-si fis",
    },
    {
      name: "Solomon Islands",
      phoneCode: "+677",
      isoCode: "SB",
      flagIcon: "fi fi-sb",
      flagIconSquare: "fi fi-sb fis",
    },
    {
      name: "Somalia",
      phoneCode: "+252",
      isoCode: "SO",
      flagIcon: "fi fi-so",
      flagIconSquare: "fi fi-so fis",
    },
    {
      name: "South Africa",
      phoneCode: "+27",
      isoCode: "ZA",
      flagIcon: "fi fi-za",
      flagIconSquare: "fi fi-za fis",
    },
    {
      name: "South Sudan",
      phoneCode: "+211",
      isoCode: "SS",
      flagIcon: "fi fi-ss",
      flagIconSquare: "fi fi-ss fis",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      phoneCode: "+500",
      isoCode: "GS",
      flagIcon: "fi fi-gs",
      flagIconSquare: "fi fi-gs fis",
    },
    {
      name: "Spain",
      phoneCode: "+34",
      isoCode: "ES",
      flagIcon: "fi fi-es",
      flagIconSquare: "fi fi-es fis",
    },
    {
      name: "Sri Lanka",
      phoneCode: "+94",
      isoCode: "LK",
      flagIcon: "fi fi-lk",
      flagIconSquare: "fi fi-lk fis",
    },
    {
      name: "Sudan",
      phoneCode: "+249",
      isoCode: "SD",
      flagIcon: "fi fi-sd",
      flagIconSquare: "fi fi-sd fis",
    },
    {
      name: "Suriname",
      phoneCode: "+597",
      isoCode: "SR",
      flagIcon: "fi fi-sr",
      flagIconSquare: "fi fi-sr fis",
    },
    {
      name: "Svalbard and Jan Mayen",
      phoneCode: "+47",
      isoCode: "SJ",
      flagIcon: "fi fi-sj",
      flagIconSquare: "fi fi-sj fis",
    },
    {
      name: "Swaziland",
      phoneCode: "+268",
      isoCode: "SZ",
      flagIcon: "fi fi-sz",
      flagIconSquare: "fi fi-sz fis",
    },
    {
      name: "Sweden",
      phoneCode: "+46",
      isoCode: "SE",
      flagIcon: "fi fi-se",
      flagIconSquare: "fi fi-se fis",
    },
    {
      name: "Switzerland",
      phoneCode: "+41",
      isoCode: "CH",
      flagIcon: "fi fi-ch",
      flagIconSquare: "fi fi-ch fis",
    },
    {
      name: "Syrian Arab Republic",
      phoneCode: "+963",
      isoCode: "SY",
      flagIcon: "fi fi-sy",
      flagIconSquare: "fi fi-sy fis",
    },
    {
      name: "Taiwan",
      phoneCode: "+886",
      isoCode: "TW",
      flagIcon: "fi fi-tw",
      flagIconSquare: "fi fi-tw fis",
    },
    {
      name: "Tajikistan",
      phoneCode: "+992",
      isoCode: "TJ",
      flagIcon: "fi fi-tj",
      flagIconSquare: "fi fi-tj fis",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      phoneCode: "+255",
      isoCode: "TZ",
      flagIcon: "fi fi-tz",
      flagIconSquare: "fi fi-tz fis",
    },
    {
      name: "Thailand",
      phoneCode: "+66",
      isoCode: "TH",
      flagIcon: "fi fi-th",
      flagIconSquare: "fi fi-th fis",
    },
    {
      name: "Timor-Leste",
      phoneCode: "+670",
      isoCode: "TL",
      flagIcon: "fi fi-tl",
      flagIconSquare: "fi fi-tl fis",
    },
    {
      name: "Togo",
      phoneCode: "+228",
      isoCode: "TG",
      flagIcon: "fi fi-tg",
      flagIconSquare: "fi fi-tg fis",
    },
    {
      name: "Tokelau",
      phoneCode: "+690",
      isoCode: "TK",
      flagIcon: "fi fi-tk",
      flagIconSquare: "fi fi-tk fis",
    },
    {
      name: "Tonga",
      phoneCode: "+676",
      isoCode: "TO",
      flagIcon: "fi fi-to",
      flagIconSquare: "fi fi-to fis",
    },
    {
      name: "Trinidad and Tobago",
      phoneCode: "+1868",
      isoCode: "TT",
      flagIcon: "fi fi-tt",
      flagIconSquare: "fi fi-tt fis",
    },
    {
      name: "Tunisia",
      phoneCode: "+216",
      isoCode: "TN",
      flagIcon: "fi fi-tn",
      flagIconSquare: "fi fi-tn fis",
    },
    {
      name: "Turkey",
      phoneCode: "+90",
      isoCode: "TR",
      flagIcon: "fi fi-tr",
      flagIconSquare: "fi fi-tr fis",
    },
    {
      name: "Turkmenistan",
      phoneCode: "+993",
      isoCode: "TM",
      flagIcon: "fi fi-tm",
      flagIconSquare: "fi fi-tm fis",
    },
    {
      name: "Turks and Caicos Islands",
      phoneCode: "+1649",
      isoCode: "TC",
      flagIcon: "fi fi-tc",
      flagIconSquare: "fi fi-tc fis",
    },
    {
      name: "Tuvalu",
      phoneCode: "+688",
      isoCode: "TV",
      flagIcon: "fi fi-tv",
      flagIconSquare: "fi fi-tv fis",
    },
    {
      name: "Uganda",
      phoneCode: "+256",
      isoCode: "UG",
      flagIcon: "fi fi-ug",
      flagIconSquare: "fi fi-ug fis",
    },
    {
      name: "Ukraine",
      phoneCode: "+380",
      isoCode: "UA",
      flagIcon: "fi fi-ua",
      flagIconSquare: "fi fi-ua fis",
    },
    {
      name: "United Arab Emirates",
      phoneCode: "+971",
      isoCode: "AE",
      flagIcon: "fi fi-ae",
      flagIconSquare: "fi fi-ae fis",
    },
    {
      name: "United Kingdom",
      phoneCode: "+44",
      isoCode: "GB",
      flagIcon: "fi fi-gb",
      flagIconSquare: "fi fi-gb fis",
    },
    {
      name: "United States",
      phoneCode: "+1",
      isoCode: "US",
      flagIcon: "fi fi-us",
      flagIconSquare: "fi fi-us fis",
    },
    {
      name: "Uruguay",
      phoneCode: "+598",
      isoCode: "UY",
      flagIcon: "fi fi-uy",
      flagIconSquare: "fi fi-uy fis",
    },
    {
      name: "Uzbekistan",
      phoneCode: "+998",
      isoCode: "UZ",
      flagIcon: "fi fi-uz",
      flagIconSquare: "fi fi-uz fis",
    },
    {
      name: "Vanuatu",
      phoneCode: "+678",
      isoCode: "VU",
      flagIcon: "fi fi-vu",
      flagIconSquare: "fi fi-vu fis",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      phoneCode: "+58",
      isoCode: "VE",
      flagIcon: "fi fi-ve",
      flagIconSquare: "fi fi-ve fis",
    },
    {
      name: "Vietnam",
      phoneCode: "+84",
      isoCode: "VN",
      flagIcon: "fi fi-vn",
      flagIconSquare: "fi fi-vn fis",
    },
    {
      name: "Virgin Islands, British",
      phoneCode: "+1284",
      isoCode: "VG",
      flagIcon: "fi fi-vg",
      flagIconSquare: "fi fi-vg fis",
    },
    {
      name: "Virgin Islands, U.S.",
      phoneCode: "+1340",
      isoCode: "VI",
      flagIcon: "fi fi-vi",
      flagIconSquare: "fi fi-vi fis",
    },
    {
      name: "Wallis and Futuna",
      phoneCode: "+681",
      isoCode: "WF",
      flagIcon: "fi fi-wf",
      flagIconSquare: "fi fi-wf fis",
    },
    {
      name: "Yemen",
      phoneCode: "+967",
      isoCode: "YE",
      flagIcon: "fi fi-ye",
      flagIconSquare: "fi fi-ye fis",
    },
    {
      name: "Zambia",
      phoneCode: "+260",
      isoCode: "ZM",
      flagIcon: "fi fi-zm",
      flagIconSquare: "fi fi-zm fis",
    },
    {
      name: "Zimbabwe",
      phoneCode: "+263",
      isoCode: "ZW",
      flagIcon: "fi fi-zw",
      flagIconSquare: "fi fi-zw fis",
    },
  ],
  codesInseePays: [
    { label: "Albanie", value: "99125" },
    { label: "Allemagne", value: "99109" },
    { label: "Andorre", value: "99130" },
    { label: "Aruba", value: "99135" },
    { label: "Autriche", value: "99110" },
    { label: "Belgique", value: "99131" },
    { label: "Bielorussie", value: "99148" },
    { label: "Bosnie-Herzegovine", value: "99118" },
    { label: "Bouvet (Île)", value: "99103" },
    { label: "Bulgarie", value: "99111" },
    { label: "Croatie", value: "99119" },
    { label: "Danemark", value: "99101" },
    { label: "Espagne", value: "99134" },
    { label: "Estonie", value: "99106" },
    { label: "France", value: "99100" },
    { label: "Macédoine du nord", value: "99156" },
    { label: "Féroé (Îles)", value: "99101" },
    { label: "Finlande", value: "99105" },
    { label: "Gibraltar", value: "99133" },
    { label: "Grèce", value: "99126" },
    { label: "Guernesey", value: "99132" },
    { label: "Hongrie", value: "99112" },
    { label: "Irlande ou Eire", value: "99136" },
    { label: "Islande", value: "99102" },
    { label: "Italie", value: "99127" },
    { label: "Jersey", value: "99132" },
    { label: "Kosovo", value: "99157" },
    { label: "Lettonie", value: "99107" },
    { label: "Liechtenstein", value: "99113" },
    { label: "Lituanie", value: "99108" },
    { label: "Luxembourg", value: "99137" },
    { label: "Malte", value: "99144" },
    { label: "Man (Île)", value: "99132" },
    { label: "Moldavie", value: "99151" },
    { label: "Monaco", value: "99128" },
    { label: "Montenegro", value: "99120" },
    { label: "Norvège", value: "99103" },
    { label: "Pays-Bas", value: "99135" },
    { label: "Pologne", value: "99122" },
    { label: "Portugal", value: "99139" },
    { label: "République Démocratique Allemande", value: "99141" },
    { label: "République Fédérale d'Allemagne", value: "99142" },
    { label: "Roumanie", value: "99114" },
    { label: "Royaume-uni", value: "99132" },
    { label: "Russie", value: "99123" },
    { label: "Saint-Marin", value: "99128" },
    { label: "Serbie", value: "99121" },
    { label: "Slovaquie", value: "99117" },
    { label: "Slovenie", value: "99145" },
    { label: "Suède", value: "99104" },
    { label: "Suisse", value: "99140" },
    { label: "Svalbard et Île Jan Mayen", value: "99103" },
    { label: "Techecoslovaquie", value: "99115" },
    { label: "Tchéquie", value: "99116" },
    { label: "Turquie d'Europe", value: "99124" },
    { label: "Ukraine", value: "99155" },
    { label: "Vatican, ou Saint-Siege", value: "99129" },
    { label: "Afghanistan", value: "99212" },
    { label: "Arabie Saoudite", value: "99201" },
    { label: "Armenie", value: "99252" },
    { label: "Azerbaidjan", value: "99253" },
    { label: "Bahrein", value: "99249" },
    { label: "Bangladesh", value: "99246" },
    { label: "Bhoutan", value: "99214" },
    { label: "Birmanie", value: "99222" },
    { label: "Brunei", value: "99225" },
    { label: "Cambodge", value: "99324" },
    { label: "Chine", value: "99216" },
    { label: "Chypre", value: "99254" },
    { label: "Corée", value: "99237" },
    { label: "Corée (République DE)", value: "99239" },
    { label: "Corée (République Populaire Démocratique DE)", value: "99238" },
    { label: "Émirats Arabes Unis", value: "99247" },
    { label: "États Malais Non Fédéré", value: "99228" },
    { label: "Georgie", value: "99255" },
    { label: "Goa", value: "99223" },
    { label: "Hong-kong", value: "99230" },
    { label: "Inde", value: "99223" },
    { label: "Indonésie", value: "99231" },
    { label: "Iran", value: "99204" },
    { label: "Iraq", value: "99203" },
    { label: "Israel", value: "99207" },
    { label: "Japon", value: "99217" },
    { label: "Jordanie", value: "99222" },
    { label: "Kamtchatka", value: "99211" },
    { label: "Kazakhstan", value: "99256" },
    { label: "Kirghizistan", value: "99257" },
    { label: "Koweit", value: "99240" },
    { label: "Laos", value: "99241" },
    { label: "Liban", value: "99205" },
    { label: "Macao", value: "99232" },
    { label: "Malaisie", value: "99227" },
    { label: "Maldives", value: "99229" },
    { label: "Mandchourie", value: "99218" },
    { label: "Mongolie", value: "99242" },
    { label: "Népal", value: "99215" },
    { label: "Oman", value: "99250" },
    { label: "Ouzbekistan", value: "99258" },
    { label: "Pakistan", value: "99213" },
    { label: "Palestine (État de)", value: "99261" },
    { label: "Philippines", value: "99220" },
    { label: "Possessions Britanniques au Proche-Orient", value: "99221" },
    { label: "Qatar", value: "99248" },
    { label: "Siberie", value: "99209" },
    { label: "Singapour", value: "99226" },
    { label: "Sri Lanka", value: "99235" },
    { label: "Syrie", value: "99206" },
    { label: "Tadjikisitan", value: "99259" },
    { label: "Taiwan", value: "99236" },
    { label: "Thailande", value: "99219" },
    { label: "Timor Oriental", value: "99262" },
    { label: "Turkestan Russe", value: "99210" },
    { label: "Turkmenistan", value: "99260" },
    { label: "Turquie", value: "99208" },
    { label: "Viet Nam", value: "99243" },
    { label: "Viet Nam du Nord", value: "99244" },
    { label: "Viet Nam du Sud", value: "99245" },
    { label: "Yémen", value: "99251" },
    { label: "Yémen Démocratique", value: "99233" },
    { label: "Yémen (République Arabe du)", value: "99202" },
    { label: "Acores, Madere", value: "99319" },
    { label: "Afrique du Sud", value: "99303" },
    { label: "Algérie", value: "99352" },
    { label: "Angola", value: "99395" },
    { label: "Bénin", value: "99327" },
    { label: "Botswana", value: "99347" },
    { label: "Burkina", value: "99331" },
    { label: "Burundi", value: "99321" },
    { label: "Cameroun", value: "99322" },
    { label: "Cameroun et Togo", value: "99305" },
    { label: "Canaries (Îles)", value: "99313" },
    { label: "Cap-vert", value: "99396" },
    { label: "Centrafricaine (République)", value: "99323" },
    { label: "Comores", value: "99397" },
    { label: "Congo", value: "99324" },
    { label: "Congo (République Démocratique)", value: "99312" },
    { label: "Côte d'ivoire", value: "99326" },
    { label: "Djibouti", value: "99399" },
    { label: "Égypte", value: "99301" },
    { label: "Érythrée", value: "99317" },
    { label: "Eswatini", value: "99391" },
    { label: "Éthiopie", value: "99315" },
    { label: "Gabon", value: "99328" },
    { label: "Gambie", value: "99304" },
    { label: "Ghana", value: "99329" },
    { label: "Ghuinée", value: "99330" },
    { label: "Ghuinée Equatorial", value: "99314" },
    { label: "Ghuinée-Bissau", value: "99392" },
    { label: "Îles Portugaise de l'océan Indien", value: "99320" },
    { label: "Kenya", value: "99332" },
    { label: "Lesotho", value: "99348" },
    { label: "Liberia", value: "99302" },
    { label: "Libye", value: "99316" },
    { label: "Madagascar", value: "99333" },
    { label: "Malawi", value: "99334" },
    { label: "Mali", value: "99335" },
    { label: "Maroc", value: "99350" },
    { label: "Maurice", value: "99390" },
    { label: "Mauritanie", value: "99336" },
    { label: "Mozambique", value: "99393" },
    { label: "Namibie", value: "99311" },
    { label: "Niger", value: "99337" },
    { label: "Nigeria", value: "99338" },
    { label: "Océan Indien (Territoire Britannique de l')", value: "99308" },
    { label: "Ouganda", value: "99339" },
    { label: "Presides", value: "99313" },
    { label: "Provinces Espagnoles d'Afrique", value: "99313" },
    { label: "Rwanda", value: "99340" },
    { label: "Sahara Occidental", value: "99389" },
    { label: "Sainte Helene, Ascention et Tristan Da Cunha", value: "99306" },
    { label: "Sénégal", value: "99394" },
    { label: "Seychelles", value: "99398" },
    { label: "Sierra Léone", value: "99342" },
    { label: "Somalie", value: "99318" },
    { label: "Soudan", value: "99343" },
    { label: "Soudan Anglo-Egyptien, Kenya, Ouganda", value: "99307" },
    { label: "Soudan du Sud", value: "99349" },
    { label: "Tanger", value: "99325" },
    { label: "Tanzanie", value: "99309" },
    { label: "Tchad", value: "99344" },
    { label: "Togo", value: "99345" },
    { label: "Tunisie", value: "99351" },
    { label: "Zambie", value: "99346" },
    { label: "Zanzibar", value: "99308" },
    { label: "Zimbabwe", value: "99310" },
    { label: "Alaska", value: "99404" },
    { label: "Anguilla", value: "99425" },
    { label: "Antigua-Et-Barbuda", value: "99441" },
    { label: "Antilles Néerlandaises", value: "99431" },
    { label: "Argentine", value: "99415" },
    { label: "Bahamas", value: "99436" },
    { label: "Barbade", value: "99434" },
    { label: "Belize", value: "99429" },
    { label: "Bermudes", value: "99425" },
    { label: "Bolivie", value: "99418" },
    { label: "Bonaire, Saint Eustache Et Saba", value: "99443" },
    { label: "Bresil", value: "99416" },
    { label: "Caimanes (Îles)", value: "99425" },
    { label: "Canada", value: "99401" },
    { label: "Chili", value: "99417" },
    { label: "Colombie", value: "99419" },
    { label: "Costa Rica", value: "99406" },
    { label: "Cuba", value: "99407" },
    { label: "Curaçao", value: "99444" },
    { label: "Dominicaine (République)", value: "99408" },
    { label: "Dominique", value: "99438" },
    { label: "El Salvador", value: "99414" },
    { label: "Equateur", value: "99420" },
    { label: "États-Unis", value: "99404" },
    { label: "Georgie Du Sud Et Les Iles Sandwich Du Sud", value: "99427" },
    { label: "Grenade", value: "99435" },
    { label: "Groenland", value: "99430" },
    { label: "Guatemala", value: "99409" },
    { label: "Guyana", value: "99428" },
    { label: "Haiti", value: "99410" },
    { label: "Honduras", value: "99411" },
    { label: "Jamaique", value: "99426" },
    { label: "Labrador", value: "99403" },
    { label: "Malouines, ou Falkland (Îles)", value: "99427" },
    { label: "Mexique", value: "99405" },
    { label: "Montserrat", value: "99425" },
    { label: "Nicaragua", value: "99412" },
    { label: "Panama", value: "99413" },
    { label: "Paraguay", value: "99421" },
    { label: "Perou", value: "99422" },
    { label: "Porto Rico", value: "99432" },
    { label: "Saint-Christophe-Et-Nieves", value: "99442" },
    { label: "Sainte-Lucie", value: "99439" },
    { label: "Saint-Martin (Partie Néerlandaise)", value: "99445" },
    { label: "Saint-Vincent-Et-Les-Grenadines", value: "99440" },
    { label: "Suriname", value: "99437" },
    { label: "Terr. Des États-Unis d'Amérique en Amérique", value: "99432" },
    { label: "Terr. Du Royaume-Uni dans L'Atlantique Sud", value: "99427" },
    { label: "Terre-Neuve", value: "99402" },
    { label: "Territoires du Royaume-Uni aux Antilles", value: "99425" },
    { label: "Trinite-Et-Tobago", value: "99433" },
    { label: "Turks Et Caiques (Iles)", value: "99425" },
    { label: "Uruguay", value: "99423" },
    { label: "Venezuela", value: "99424" },
    { label: "Vierges Britanniques (Îles)", value: "99425" },
    { label: "Vierge Des États-Unis (Îles)", value: "99432" },
    { label: "Australie", value: "99501" },
    { label: "Christmas (Île)", value: "99501" },
    { label: "Cocos ou Keeling (Îles)", value: "99501" },
    { label: "Cook (Îles)", value: "99502" },
    { label: "Fidji", value: "99508" },
    { label: "Guam", value: "99505" },
    { label: "Hawaii (Îles)", value: "99504" },
    { label: "Heard et Macdonald (Îles)", value: "99501" },
    { label: "Kiribati", value: "99513" },
    { label: "Mariannes du Nord (Îles)", value: "99505" },
    { label: "Marshall (Îles)", value: "99515" },
    { label: "Micronésie (États Fédéré De", value: "99516" },
    { label: "Nauru", value: "99507" },
    { label: "Niue", value: "99502" },
    { label: "Norfolk (Île)", value: "99501" },
    { label: "Nouvelle-Zelande", value: "99502" },
    { label: "Palaos (Îles)", value: "99517" },
    { label: "Papouasie-Nouvelle-Guinée", value: "99510" },
    { label: "Pitcairn (Île)", value: "99503" },
    { label: "Salomon (Îles)", value: "99512" },
    { label: "Samoa Americaines", value: "99505" },
    { label: "Samoa Occidentales", value: "99506" },
    { label: "Terr. Des États-Unie D'Amérique en Océanie", value: "99505" },
    { label: "Tokelau", value: "99502" },
    { label: "Tonga", value: "99509" },
    { label: "Tuvalu", value: "99511" },
    { label: "Vanuatu", value: "99514" },
  ],
  companyStatuts: [
    { label: "Formulaires", value: "forms" },
    { label: "Mail + MV 1", value: "collect-coords" },
    { label: "Mail + MV 2 + SMS", value: "first-contact" },
    { label: "Mail + MV 3", value: "first-meeting" },
    { label: "Mail + MV 4 + SMS", value: "mv4" },
    { label: "Devis + Presta envoyé", value: "estimate-sended" },
    { label: "relance 1", value: "reminder-1" },
    { label: "relance 2", value: "reminder-2" },
    { label: "relance 3", value: "reminder-3" },
    { label: "Séance découverte", value: "discovery-session" },
    { label: "One shot", value: "one-shot" },
    { label: "Client", value: "client" },
    { label: "Perdu", value: "lost" },
  ],
  companyLost: [
    { label: "Tarifs", value: "Prices" },
    { label: "Choisi concurrent", value: "lost-concurrent" },
    { label: "Pas validé par direction", value: "not-validated-management" },
    { label: "Pas validé par le CE", value: "not-validated-ce" },
    { label: "Seulement un évènement", value: "one-event" },
    { label: "Coach pas satisfaisant", value: "not-satisfying-coach" },
    { label: "Manque de fréquentation", value: "attendance-lack" },
    { label: "Pas de retour", value: "no-return" },
  ],
  preferredContactMethodOptions: ["Email", "Téléphone"],
  signatureSheetStatus: [
    { label: "En attente", value: "pending", color: "#5bc0de" },
    { label: "Soumise", value: "submitted", color: "#f0ad4e" },
    { label: "Envoyée", value: "sent", color: "#5cb85c" },
  ],
};
