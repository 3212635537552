<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <div class="card">
      <div class="card-header">
        <i class="icon-refresh"></i> Filtrer "KPI essentiels"
      </div>
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Période entre le</label>
            <datepicker
              v-model="form.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>et le</label>
            <datepicker
              v-model="form.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Commercial</label>
            <multiselect
              v-model="form.commercials"
              :options="commercials"
              :multiple="true"
              label="label"
              track-by="label"
              class="custom-multiselect"
            >
            </multiselect>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Filtrer par concept</label>
            <multiselect
              v-model="form.concepts"
              :options="concepts"
              :multiple="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Choisir"
              class="custom-multiselect"
            >
            </multiselect>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Client B2B</label>
            <el-switch
              v-model="form.isB2B"
              active-text="Oui"
              inactive-text="Non"
              size="mini"
            />
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Établissement marché</label>
            <el-switch
              v-model="form.marketEstablishment"
              active-text="Oui"
              inactive-text="Non"
              size="mini"
              @change="forceB2BForm1"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button
              :disabled="loading"
              type="button"
              class="btn btn-sm btn-secondary"
              @click="loadData"
            >
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <i class="icon-refresh"></i> Filtrer (taux retention & cycle de vie)
      </div>
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Période 1 entre le</label>
            <datepicker
              v-model="form2.p1begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>et le</label>
            <datepicker
              v-model="form2.p1end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Période 2 entre le</label>
            <datepicker
              v-model="form2.p2begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>et le</label>
            <datepicker
              v-model="form2.p2end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Commercial</label>
            <multiselect
              v-model="form2.commercials"
              :options="commercials"
              :multiple="true"
              label="label"
              track-by="label"
              class="custom-multiselect"
            >
            </multiselect>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Filtrer par concept</label>
            <multiselect
              v-model="form2.concepts"
              :options="concepts"
              :multiple="true"
              :clear-on-select="false"
              :preserve-search="true"
              label="name"
              track-by="name"
              placeholder="Choisir"
              class="custom-multiselect"
            >
            </multiselect>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Client B2B</label>
            <el-switch
              v-model="form2.isB2B"
              active-text="Oui"
              inactive-text="Non"
              size="mini"
            />
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label>Établissement marché</label>
            <el-switch
              v-model="form2.marketEstablishment"
              active-text="Oui"
              inactive-text="Non"
              size="mini"
              @change="forceB2BForm2"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button
              :disabled="loading"
              type="button"
              class="btn btn-sm btn-secondary"
              @click="loadData2"
            >
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Datepicker from "vuejs-datepicker";
import DownloadFile from "../mixin/DownloadFile";
import Multiselect from "vue-multiselect";
import Loader from "../components/Loader";

export default {
  components: {
    Datepicker,
    Multiselect,
    Loader,
  },

  mixins: [DownloadFile],

  data() {
    return {
      loading: false,
      stats: {},
      form: {
        begin: moment().subtract("1", "year").startOf("y").format(),
        end: moment().subtract("1", "year").endOf("y").format(),
        concepts: [],
        commercials: [],
        isB2B: false,
        marketEstablishment: false,
      },
      form2: {
        p1begin: moment().subtract("2", "year").startOf("y").format(),
        p1end: moment().subtract("2", "year").endOf("y").format(),
        p2begin: moment().subtract("1", "year").startOf("y").format(),
        p2end: moment().subtract("1", "year").endOf("y").format(),
        concepts: [],
        commercials: [],
        isB2B: false,
        marketEstablishment: false,
      },
      concepts: [],
      commercials: [],
    };
  },

  async created() {
    await this.fetchCommercials();
    await this.fetchConcepts();
  },

  methods: {
    async loadData() {
      this.loading = true;

      try {
        const {
          begin,
          end,
          commercials,
          concepts,
          isB2B,
          marketEstablishment,
        } = this.form;

        const { data } = await this.$api.get("/marketings/kpi-export", {
          params: {
            begin,
            end,
            concepts,
            commercials: commercials.map((c) => c.value),
            isB2B,
            marketEstablishment,
          },
        });

        this.downloadXLSX(data, "kpi-export");
      } catch (error) {
        this.$notify.error(
          "Une erreur est survenue lors de la génération du fichier"
        );
      }

      this.loading = false;
    },

    async loadData2() {
      this.loading = true;

      try {
        const {
          p1begin,
          p1end,
          p2begin,
          p2end,
          commercials,
          concepts,
          isB2B,
          marketEstablishment,
        } = this.form2;

        const { data } = await this.$api.get("/marketings/kpi-export-2", {
          params: {
            p1begin,
            p1end,
            p2begin,
            p2end,
            concepts,
            commercials: commercials.map((c) => c.value),
            isB2B,
            marketEstablishment,
          },
        });

        this.downloadXLSX(data, "kpi-export");
      } catch (error) {
        this.$notify.error(
          "Une erreur est survenue lors de la génération du fichier"
        );
      }

      this.loading = false;
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async fetchConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data.map((c) => c.name);
    },

    forceB2BForm1() {
      if (this.form.marketEstablishment) {
        this.form.isB2B = true;
      }
    },

    forceB2BForm2() {
      if (this.form2.marketEstablishment) {
        this.form2.isB2B = true;
      }
    },
  },
};
</script>
