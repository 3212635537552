<template>
  <modal
    :title="titleModal"
    v-model="isVisible"
    :backdrop="false"
    cancelText="Annuler"
    okText="Enregistrer"
    @ok="updateStatus()"
    @cancel="close"
    effect="fade/zoom"
  >
    <Loader :is-visible="loading" />

    <div v-if="isProspect">
      Êtes vous sûr de vouloir modifier le statut en
      <span v-if="status === 'en-attente'" class="font-weight-bold"
        >'En attente'</span
      >
      <span v-if="status === 'treated'" class="font-weight-bold"
        >'Traité (en attente de paiement)'</span
      >
      <span v-else-if="status === 'converti'" class="font-weight-bold"
        >'Converti'</span
      >
      <span v-else-if="status === 'perdu'" class="font-weight-bold"
        >'Perdu'</span
      >
    </div>

    <div v-else>
      Êtes vous sûr de vouloir modifier le statut en
      <span v-if="status === 'active'" class="font-weight-bold">'Actif'</span>
      <span v-if="status === 'inactive'" class="font-weight-bold"
        >'Inactif'</span
      >
      <span v-else-if="status === 'suspended'" class="font-weight-bold"
        >'Bloqué'</span
      >
      <span v-else-if="status === 'lost'" class="font-weight-bold"
        >'Perdu'</span
      >
    </div>

    <div class="row" v-if="status === 'lost' || status === 'perdu'">
      <div class="col-12 mt-1">
        Raison :
        <v-select
          :class="{ 'has-error': !form.lostReason }"
          :options="globalLostReasons"
          v-model="form.lostReason"
        >
        </v-select>

        <div v-if="form.lostReason?.details" class="mt-1">
          Détails :
          <v-select
            :class="{
              'has-error': !form.detailLostReason,
            }"
            :options="form.lostReason?.details"
            v-model="form.detailLostReason"
          >
          </v-select>
        </div>
      </div>

      <div class="col-12 mt-1">
        Perdu.e le :
        <datepicker
          v-model="form.lostAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
          :disabled="disabledDates"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";
import Modal from "vue-strap/src/Modal";

import Loader from "@/components/Loader";

export default {
  components: {
    Modal,
    Datepicker,
    Loader,
  },

  props: {
    isProspect: {
      type: Boolean,
      required: true,
    },

    client: {
      type: Object,
      required: true,
    },

    show: {
      type: Boolean,
      required: true,
    },

    globalLostReasons: {
      type: Array,
      required: true,
    },

    disabledDates: {
      type: Object,
      required: true,
    },

    status: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isVisible: false,
      loading: false,

      form: {
        type: null,
        lostReason: null,
        detailLostReason: null,
        lostAt: moment().toDate(),
      },
    };
  },

  watch: {
    show(value) {
      this.isVisible = value;
    },
  },

  computed: {
    titleModal() {
      return `Modifier le statut du ${this.isProspect ? "prospect" : "client"}`;
    },
  },

  methods: {
    close(client) {
      this.form.lostReason = null;
      this.form.detailLostReason = null;
      this.isVisible = false;

      this.$emit("close", client);
    },

    formError() {
      if (this.form.lostReason?.details) {
        return !!this.form.detailLostReason;
      }

      return !!this.form.lostReason;
    },

    async updateStatus() {
      const currentStatus = this.isProspect
        ? this.client.prospect.type
        : this.client.status;

      const lostReason = this.form.lostReason
        ? this.form.lostReason?.value
        : null;

      const detailLostReason = this.form.detailLostReason
        ? this.form.detailLostReason?.value
        : null;

      const lostAt = lostReason && this.form.lostAt ? this.form.lostAt : null;

      if (!this.formError() && this.status === "perdu") {
        this.$errorToast("Veuillez selectionner une raison de perte");

        return;
      }

      const data = {
        lostReason,
        detailLostReason,
        lostAt,
      };

      try {
        this.loading = true;

        const updatedClient = this.isProspect
          ? await this.updateProspect(data)
          : await this.updateClient(data);

        this.form = {
          type: null,
          lostReason: null,
          detailLostReason: null,
          lostAt: moment().toDate(),
        };

        this.close(updatedClient);
        this.loading = false;
        this.$successToast("Statut modifié avec succès");
      } catch (e) {
        this.$errorToast("Une erreur s'est produite");
        this.form.prospect.type = currentStatus;
      }
    },

    async updateProspect({ lostReason, detailLostReason, lostAt }) {
      const { data } = await this.$api.patch(`/clients/status`, {
        email: this.client.email,
        prospect: {
          type: this.status,
          lostReason,
          detailLostReason,
          lostAt,
        },
      });

      return data;
    },

    async updateClient({ lostReason, detailLostReason, lostAt }) {
      const { data } = await this.$api.patch(`/clients/status`, {
        email: this.client.email,
        status: this.status,
        lostReason,
        detailLostReason,
        lostAt,
      });

      return data;
    },
  },
};
</script>
