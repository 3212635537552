<template>
  <modal
    title="Exporter l'historique"
    :value="show"
    cancelText="Annuler"
    okText="Exporter"
    effect="fade/zoom"
    @ok="exportHistory"
    @cancel="close"
  >
    <Loader :is-visible="loading" />

    <div class="row">
      <div class="form-group col-sm-6">
        <label>Date de début</label>
        <datepicker
          v-model="begin"
          format="MM.yyyy"
          language="fr"
          input-class="form-control"
          minimum-view="month"
        ></datepicker>
      </div>
      <div class="form-group col-sm-6">
        <label>Date de fin</label>
        <datepicker
          v-model="end"
          format="MM.yyyy"
          language="fr"
          input-class="form-control"
          minimum-view="month"
        ></datepicker>
      </div>
    </div>

    <div class="row">
      <div class="form-group col d-flex flex-column">
        <label class="mb-1">Inclure les archives</label>
        <el-switch
          v-model="includeArchives"
          active-text="Oui"
          inactive-text="Non"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";
import Loader from "./Loader";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Modal,
    Loader,
    Datepicker,
  },

  mixins: [DownloadFile],

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "payed",
    },
  },

  data() {
    return {
      loading: false,
      begin: moment().startOf("year").toDate(),
      end: moment().endOf("year").toDate(),
      includeArchives: true,
    };
  },

  methods: {
    close() {
      this.$emit("close");
      this.reset();
    },

    reset() {
      this.begin = moment().startOf("year").toDate();
      this.end = moment().endOf("year").toDate();
      this.includeArchives = true;
    },

    async exportHistory() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/disbursements/history", {
          params: {
            type: this.type,
            begin: this.begin,
            end: this.end,
            includeArchives: this.includeArchives,
          },
        });

        this.downloadXLSX(
          data,
          `paiements_coachs_${moment(this.begin).format("MM-YYYY")}_${moment(
            this.end
          ).format("MM-YYYY")}.xlsx`
        );

        this.close();
      } catch (e) {
        this.$errorToast(
          "Une erreur est survenue lors de l'export de l'historique"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
