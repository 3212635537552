<template>
  <div class="animated fadeIn">
    <MarketingFilters
      :default="filters"
      :defaultDates="dates"
      :values="values"
      :countClient="clients.length"
      @formFilters="handleFormFilters"
      @updateFilter="handleFilters"
    />

    <MarketingStats v-if="cols && !loading" :cols="cols" />
    <loader :isVisible="loading" />
  </div>
</template>

<script>
import moment from "moment-timezone";

import MarketingFilters from "../components/MarketingFilters";
import MarketingStats from "../components/MarketingStats";
import Loader from "../components/Loader";

export default {
  components: {
    MarketingFilters,
    MarketingStats,
    Loader,
  },

  data() {
    return {
      cols: undefined,

      clients: [],
      expenses: [],

      dates: {
        begin: moment().startOf("M").format(),
        end: moment().endOf("M").format(),
      },

      filters: {},
      filtersCommercials: { commercials: [] },

      values: {
        utm_sources: [],
        utm_mediums: [],
        utm_terms: [],
        utm_contents: [],
        utm_campaigns: [],
        sourceUrls: [],
        referers: [],
        expenses: [],
        status: [
          {
            label: "Découverte",
            value: "decouverte",
          },
          {
            label: "Conversion",
            value: "pack-conversion",
          },
          {
            label: "OneShot/Event",
            value: "one-shot",
          },
          {
            label: "Fidélisation",
            value: "pack-fidelisation",
          },
          {
            label: "Pack Autres",
            value: "autres",
          },
        ],
      },
      loading: false,
    };
  },

  async created() {
    await this.loadData();
  },

  methods: {
    fetchMarketingFilters() {
      const sources = this.expenses
        .filter((e) => e.source)
        .map((e) => e.source);

      sources.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.utm_source)
          .map((e) => e.marketing.utm_source)
      );

      const sourcesValues = sources
        .filter((item, index) => {
          return sources.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.utm_sources = [{ type: "Tous", values: sourcesValues }];

      const campaigns = this.expenses
        .filter((e) => e.campaign)
        .map((e) => e.campaign);

      campaigns.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.utm_campaign)
          .map((e) => e.marketing.utm_campaign)
      );

      const campaignsValues = campaigns
        .filter((item, index) => {
          return campaigns.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.utm_campaigns = [{ type: "Tous", values: campaignsValues }];

      const mediums = this.expenses
        .filter((e) => e.medium)
        .map((e) => e.medium);

      mediums.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.utm_medium)
          .map((e) => e.marketing.utm_medium)
      );

      const mediumsValues = mediums
        .filter((item, index) => {
          return mediums.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.utm_mediums = [{ type: "Tous", values: mediumsValues }];

      const terms = this.expenses.filter((e) => e.term).map((e) => e.term);

      terms.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.utm_term)
          .map((e) => e.marketing.utm_term)
      );

      const termsValues = terms
        .filter((item, index) => {
          return terms.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.utm_terms = [{ type: "Tous", values: termsValues }];

      const contents = this.expenses
        .filter((e) => e.content)
        .map((e) => e.content);

      contents.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.utm_content)
          .map((e) => e.marketing.utm_content)
      );

      const contentsValue = contents
        .filter((item, index) => {
          return contents.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.utm_contents = [{ type: "Tous", values: contentsValue }];

      const sourceUrls = this.expenses
        .filter((e) => e.sourceUrl)
        .map((e) => e.sourceUrl);

      sourceUrls.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.sourceUrl)
          .map((e) => e.marketing.sourceUrl)
      );

      const sourcesUrlsValues = sourceUrls
        .filter((item, index) => {
          return sourceUrls.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.sourceUrls = [{ type: "Tous", values: sourcesUrlsValues }];

      const referers = this.expenses
        .filter((e) => e.referer)
        .map((e) => e.referer);

      referers.push(
        ...this.clients
          .filter((e) => e.marketing && e.marketing.referer)
          .map((e) => e.marketing.referer)
      );

      const referersValues = referers
        .filter((item, index) => {
          return referers.indexOf(item) == index;
        })
        .map((s) => ({ value: s, label: s }));

      this.values.referers = [{ type: "Tous", values: referersValues }];
    },

    async fetchExpenses() {
      try {
        const { begin, end } = this.dates;

        const { data } = await this.$api.get("/expenses", {
          params: { begin, end },
        });

        this.expenses = data.data;

        this.values.expenses = this.expenses.map((v) => ({
          label: `${v.type} : ${v.name}`,
          value: v,
        }));
      } catch (e) {
        console.log(e);
      }
    },

    async fetchClients() {
      try {
        const { begin, end } = this.dates;
        const commercialEmail = this.filtersCommercials.commercials.value;

        const { data } = await this.$api.get("/marketings/clients", {
          params: { begin, end, commercialEmail },
        });

        this.clients = data;
      } catch (e) {
        console.log(e);
      }
    },

    async handleFormFilters(dates, form) {
      this.dates = dates;
      this.cols = undefined;

      this.filtersCommercials = {
        ...this.filtersCommercials,
        ...form,
      };

      await this.loadData();
    },

    handleFilters(filters) {
      this.filters = filters;

      this.cols = {
        utm_sources: this.getStatsSource(),
        utm_campaigns: this.getStatsCampaign(),
        utm_mediums: this.getStatsMedium(),
        utm_terms: this.getStatsTerm(),
        utm_contents: this.getStatsContent(),
        referers: this.getStatsReferer(),
        sourceUrls: this.getStatsSourceUrl(),
      };
    },

    async loadData() {
      this.loading = true;
      await this.fetchExpenses();
      await this.fetchClients();

      this.fetchMarketingFilters();
      this.loading = false;
    },

    getCaForClient(clients, isAcquisition = false) {
      let result = 0;

      for (const client of clients) {
        const orderWithInvoice = client.orders.filter((o) => {
          if (!isAcquisition) {
            return o.invoice?.serial;
          } else {
            return o.isAcquisition && o.invoice?.serial;
          }
        });

        if (orderWithInvoice.length > 0) {
          result += orderWithInvoice.reduce((p, n) => ({
            price:
              n.status !== "refunded" ? p.price + n.price : p.price - n.price,
          })).price;
        }
      }

      return result / 100;
    },

    createStat(clients, expenses, marketingValue) {
      const ca = this.getCaForClient(clients);
      const caAcquisition = this.getCaForClient(clients, true);
      const amount = expenses.reduce((p, n) => p + n.amount, 0);

      const nbClientsProspects = clients.length;

      const nbProspectsConvert = clients.filter(
        (c) => c.prospect && c.prospect.type === "converti"
      ).length;

      const nbClients = clients.filter(
        (c) => !c.prospect || (c.prospect && !c.prospect.isProspect)
      ).length;

      const prospectsLosts = clients.filter(
        (c) =>
          c.prospect && c.prospect.isProspect && c.prospect.type === "perdu"
      );

      const nbProspectsLosts = prospectsLosts.length;

      let lostReasons = "";

      const prospectLostReasons = [
        {
          label: "Hors Secteur (dans un secteur pas ciblé, pas habituel)",
          value: "no-sector",
          count: 0,
        },
        { label: "Hors Budget (pas les moyens)", value: "no-budget", count: 0 },
        {
          label: "Hors Disponibilité",
          value: "unavailable",
          count: 0,
          details: [
            {
              label: "Il y a des coachs mais aucun avec ces disponibilités",
              value: "unavailable-coach",
              count: 0,
            },
            {
              label:
                "Le client n’a pas de disponibilité pour débuter un accompagnement",
              value: "unavailable-client",
              count: 0,
            },
          ],
        },
        {
          label: "Pas Joignable (jamais eu en ligne)",
          value: "unreachable",
          count: 0,
        },
        {
          label: "Plus Joignable",
          value: "unreachable-twice",
          count: 0,
          details: [
            {
              label: "Après un premier échange qui explique le fonctionnement",
              value: "unreachable-twice-after-explain",
              count: 0,
            },
            {
              label: "Après un envoi de paiement pour une séance découverte",
              value: "unreachable-twice-after-payment-discovery",
              count: 0,
            },
            {
              label: "Après avoir effectué la séance découverte",
              value: "unreachable-twice-after-discovery",
              count: 0,
            },
            {
              label:
                "A la suite de la fin d’un pack (conversion ou fidélisation)",
              value: "unreachable-twice-finished-pack",
              count: 0,
            },
          ],
        },
        {
          label: "Salle de sport (veut aller en salle)",
          value: "gym",
          count: 0,
        },
        {
          label: "Pas de coach (aucun coach sur l’attribution)",
          value: "problem-coach",
          count: 0,
        },
        {
          label: "Formulaire coach (c’est un coach et pas un client)",
          value: "form-coach",
          count: 0,
        },
        { label: "Objectif Atteint", value: "goal-unreached", count: 0 },
        {
          label: "Problème Santé (blessure, grossesse...)",
          value: "health-issue",
          count: 0,
        },
        { label: "Spam (faux formulaire)", value: "spam", count: 0 },
        {
          label: "Perdu coach (problème du au coach)",
          value: "lost-coach",
          count: 0,
        },
        {
          label: "Abandon",
          value: "abandonment",
          count: 0,
          details: [
            {
              label: "Après un premier échange qui explique le fonctionnement",
              value: "abandonment-after-explain",
              count: 0,
            },
            {
              label: "Après un envoi de paiement pour une séance découverte",
              value: "abandonment-after-payment-discovery",
              count: 0,
            },
            {
              label: "Après avoir effectué la séance découverte",
              value: "abandonment-after-discovery",
              count: 0,
            },
            {
              label:
                "A la suite de la fin d’un pack (conversion ou fidélisation)",
              value: "abandonment-finished-pack",
              count: 0,
            },
          ],
        },
      ];

      for (const prospect of prospectsLosts) {
        const reason = prospectLostReasons.find(
          (reason) => reason.value === prospect.prospect?.lostReason
        );

        if (reason?.details) {
          const detail = reason.details.find(
            (detail) => detail.value === prospect.prospect?.detailLostReason
          );

          if (detail) detail.count++;
        }

        if (reason) reason.count++;
      }

      for (const reasonObj of prospectLostReasons) {
        if (reasonObj.count > 0) {
          let detailsLostReasons = "";

          if (reasonObj.details) {
            for (const detail of reasonObj.details) {
              if (detail.count > 0) {
                detailsLostReasons += `${
                  detailsLostReasons.length > 0 ? ", " : ""
                }${detail.count} ${detail.label}`;
              }
            }
          }

          lostReasons += `${reasonObj.count} ${reasonObj.label} ${
            detailsLostReasons.length > 0 ? `: (${detailsLostReasons})` : ""
          }\n`;
        }
      }

      const conversionRate =
        nbClients > 0 && nbClientsProspects > 0
          ? (nbClients / nbClientsProspects) * 100
          : 0;

      return {
        marketingValue,
        caAcquisition,
        ca,
        amount,
        nbClientsProspects,
        nbProspectsConvert,
        nbClients,
        nbProspectsLosts,
        lostReasons,
        conversionRate,
      };
    },

    getStatsSource() {
      let stats = [];

      if (!this.filters.sources) {
        return [];
      }

      for (const source of this.filters.sources) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.utm_source &&
            c.marketing.utm_source === source.value
        );

        const expenses = this.expenses.filter(
          (e) => e.source && e.source === source.value
        );

        stats.push(this.createStat(clients, expenses, source.value));
      }

      return stats;
    },

    getStatsCampaign() {
      let stats = [];

      if (!this.filters.campaigns) {
        return [];
      }

      for (const campaign of this.filters.campaigns) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.utm_campaign &&
            c.marketing.utm_campaign === campaign.value
        );
        const expenses = this.expenses.filter(
          (e) => e.campaign && e.campaign === campaign.value
        );

        stats.push(this.createStat(clients, expenses, campaign.value));
      }

      return stats;
    },

    getStatsMedium() {
      let stats = [];

      if (!this.filters.mediums) {
        return [];
      }

      for (const medium of this.filters.mediums) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.utm_medium &&
            c.marketing.utm_medium === medium.value
        );
        const expenses = this.expenses.filter(
          (e) => e.medium && e.medium === medium.value
        );

        stats.push(this.createStat(clients, expenses, medium.value));
      }

      return stats;
    },

    getStatsTerm() {
      let stats = [];

      if (!this.filters.terms) {
        return [];
      }

      for (const term of this.filters.terms) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.utm_term &&
            c.marketing.utm_term === term.value
        );

        const expenses = this.expenses.filter(
          (e) => e.term && e.term === term.value
        );

        stats.push(this.createStat(clients, expenses, term.value));
      }

      return stats;
    },

    getStatsContent() {
      let stats = [];

      if (!this.filters.contents) {
        return [];
      }

      for (const content of this.filters.contents) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.utm_content &&
            c.marketing.utm_content === content.value
        );

        const expenses = this.expenses.filter(
          (e) => e.content && e.content === content.value
        );

        stats.push(this.createStat(clients, expenses, content.value));
      }

      return stats;
    },

    getStatsSourceUrl() {
      let stats = [];

      if (!this.filters.sourceUrls) {
        return [];
      }

      for (const sourceUrl of this.filters.sourceUrls) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.sourceUrl &&
            c.marketing.sourceUrl === sourceUrl.value
        );

        const expenses = this.expenses.filter(
          (e) => e.sourceUrl && e.sourceUrl === sourceUrl.value
        );

        stats.push(this.createStat(clients, expenses, sourceUrl.value));
      }

      return stats;
    },

    getStatsReferer() {
      let stats = [];

      if (!this.filters.referers) {
        return [];
      }

      for (const referer of this.filters.referers) {
        const clients = this.clients.filter(
          (c) =>
            c.marketing &&
            c.marketing.referer &&
            c.marketing.referer === referer.value
        );

        const expenses = this.expenses.filter(
          (e) => e.referer && e.referer === referer.value
        );

        stats.push(this.createStat(clients, expenses, referer.value));
      }

      return stats;
    },
  },
};
</script>
