<template>
  <div>
    <Loader :is-visible="loading" />

    <el-table
      :data="data"
      :default-sort="{ prop: 'createdAt', order: 'descending' }"
      @sort-change="sortChange"
    >
      <el-table-column
        prop="title"
        label="Nom"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="Type"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span class="badge badge-info">{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="Créé le"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="lastSentAt"
        label="Dernier envoi le"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.lastSentAt) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')"
        align="right"
      >
        <template slot-scope="scope">
          <button
            class="btn btn-primary btn-sm mr-05"
            @click.stop="confirmSendEvent(scope.row)"
          >
            Envoyer
          </button>
          <button
            class="btn btn-primary btn-sm mr-05"
            @click.stop="editEvent(scope.row)"
          >
            <i class="fa fa-edit"></i>
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click.stop="handleDelete(scope.row)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <ModalEvent
      :show="editModal"
      :edited-event="eventToEdit"
      :target="target"
      @close="closeEditEvent"
      @updated="eventUpdated"
    />

    <ModalConfirmSendEvent
      :visible="confirmSendEventModal"
      :selected-event="eventToSend"
      @close="closeConfirmSendEvent"
      @added="eventAdded"
    />
  </div>
</template>

<script>
import Loader from "./Loader";
import ModalEvent from "./ModalEvent";
import ModalConfirmSendEvent from "../components/ModalConfirmSendEvent";

export default {
  components: {
    Loader,
    ModalEvent,
    ModalConfirmSendEvent,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    target: {
      type: String,
      required: true,
      validator: (value) => ["COACH", "CLIENT"].includes(value),
    },
  },

  data() {
    return {
      loading: false,

      editModal: false,
      eventToEdit: null,

      confirmSendEventModal: false,
      eventToSend: null,

      sort: {
        prop: "lastSentAt",
        order: "descending",
      },
    };
  },

  methods: {
    editEvent(eventObj) {
      if (this.hasPermission(this.$store.state.user, "DYNAMIC_CONTENT_WRITE")) {
        this.eventToEdit = { ...eventObj };
        this.editModal = true;
      }
    },

    closeEditEvent() {
      this.editModal = false;
      this.eventToEdit = null;
    },

    confirmSendEvent(eventObj) {
      this.eventToSend = { ...eventObj };
      this.confirmSendEventModal = true;
    },

    closeConfirmSendEvent() {
      this.eventToSend = null;
      this.confirmSendEventModal = false;
    },

    eventAdded() {
      this.$emit("added");
    },

    eventUpdated(eventObj) {
      this.$emit("updated", eventObj);
    },

    handleDelete(eventObj) {
      if (!eventObj?.id) {
        return;
      }

      this.$confirm({
        message: `Êtes-vous sûr.e de vouloir supprimer l'événement "${eventObj.title}" ?`,
        onConfirm: () => this.deleteEvent(eventObj.id),
      });
    },

    async deleteEvent(eventId) {
      try {
        this.loading = true;

        await this.$api.delete(`/events/${eventId}`);

        this.$emit("deleted", eventId);
        this.$successToast("Événement supprimé");
      } catch (e) {
        this.$errorToast("Impossible de supprimer cet événement");
      } finally {
        this.loading = false;
      }
    },

    sortChange({ prop, order }) {
      this.sort = {
        ...this.sort,
        prop,
        order,
      };

      this.$emit("sort", this.sort);
    },
  },
};
</script>
